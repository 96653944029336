const removeLinkFromLexicalObject = (lexicalObjectRaw: string, link: string) => {
  try {
    if (!link) return lexicalObjectRaw;

    const lexicalObject = JSON.parse(lexicalObjectRaw);
    if (lexicalObject["root"]["children"].length === 0) return lexicalObjectRaw;
    if (lexicalObject["root"]["children"][0]["children"].length === 0) return lexicalObjectRaw;

    for (const element of lexicalObject["root"]["children"]) {
      if (element["children"].length === 0) continue;
      if (
        element["children"][0]["type"] === "link" ||
        element["children"][0]["type"] === "autolink"
      ) {
        if (element["children"][0]["url"] === link) {
          element["children"].shift();
          break;
        }
      }
    }
    return JSON.stringify(lexicalObject);
  } catch {
    return lexicalObjectRaw;
  }
};

export default removeLinkFromLexicalObject;
