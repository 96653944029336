import api, { RequestWithData } from "@src/api/api";

type UsernameData = {
  username: string;
};

type UsernameUpdateRequest = RequestWithData<UsernameData>;

export default async function updateUsername(request: UsernameUpdateRequest): Promise<void> {
  await api.patch("/api/v1/users/username", request.data);
}
