import { useDispatch, useSelector } from "react-redux";

import { disableAutoScroll, enableAutoScroll } from "@src/state/modal/autoScrollSlice";
import { AppDispatch, RootState } from "@src/state/store";

const useAutoScroll = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isEnabled = useSelector((state: RootState) => state.autoScroll.isAutoScrollEnabled);

  const toggle = () => {
    if (!isEnabled) dispatch(enableAutoScroll());
    else dispatch(disableAutoScroll());
  };

  return { isEnabled, toggle };
};

export default useAutoScroll;
