import { useMutation, useQuery } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import me from "@src/api/auth/me";
import uploadAvatar from "@src/api/avatar/upload";
import uploadHeader from "@src/api/header/upload";
import update, { UpdateRequest } from "@src/api/profile/update";
import Panel from "@src/components/box/Panel";
import Alert from "@src/components/elements/Alert";
import ContactsEditor from "@src/components/elements/form/ContactsEditor";
import UserComboBox from "@src/components/elements/form/UserComboBox";
import Checkbox from "@src/components/elements/input/Checkbox";
import CustomSelect from "@src/components/elements/input/CustomSelect";
import ImageUpload from "@src/components/elements/input/ImageUpload";
import Input, { ReplacementType } from "@src/components/elements/input/Input";
import LegalInput from "@src/components/elements/input/LegalInput";
import Select from "@src/components/elements/input/Select";
import Tags from "@src/components/elements/input/Tags";
import TabFilter from "@src/components/menu/TabFilter";
import BackButton from "@src/components/nav/BackButton";
import BottomProfileEditTabs from "@src/pages/profile/edit/BottomProfileEditTabs";
import ProfileEditCVList from "@src/pages/profile/edit/ProfileEditCVList";
import ProfileEditConfig from "@src/pages/profile/edit/ProfileEditConfig";
import ProfileEditSocialsContainer from "@src/pages/profile/edit/ProfileEditSocialsContainer";
import { useAppSelector } from "@src/state/hooks";
import usePopupModal from "@src/state/modal/usePopupModal";
import { setAvatar } from "@src/state/profileImages/avatarSlice";
import { setHeaderImage } from "@src/state/profileImages/headerImageSlice";
import { UserType } from "@src/types/UserType";
import checkUsername from "@src/utils/checkUsername";
import getName from "@src/utils/getName";

const tabs = (t: TFunction) => ({
  company: [
    { key: "common", name: t("main.profileTabs.company.info") },
    { key: "description", name: t("main.profileTabs.company.details") },
    { key: "contact_person", name: t("main.profileTabs.company.contact") },
    { key: "socials", name: t("main.profileTabs.company.socialMedia") },
  ],
  person: [
    { key: "common", name: t("main.profileTabs.person.info") },
    {
      key: "more_information",
      name: t("main.profileTabs.person.details"),
    },
    { key: "cv", name: t("main.profileTabs.person.career") },
    { key: "socials", name: t("main.profileTabs.person.socialMedia") },
  ],
  club: [
    { key: "common", name: t("main.profileTabs.club.info") },
    { key: "description", name: t("main.profileTabs.club.details") },
    { key: "contact_person", name: t("main.profileTabs.club.contact") },
    { key: "socials", name: t("main.profileTabs.club.socialMedia") },
  ],
});

const ProfileEditContainer = () => {
  const { t } = useTranslation();
  const { user: seesionUser } = useAppSelector(state => state.user);
  const { avatar } = useAppSelector(state => state.avatar);
  const { headerImage } = useAppSelector(state => state.headerImage);
  const [userList, setUserList] = useState<Array<UserType>>([]);
  const [adminList, setAdminList] = useState<Array<UserType>>([]);
  const [data, setData] = useState<any>({});
  const [metaData, setMetaData] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<string>();
  const popupModal = usePopupModal();
  const dispatch = useDispatch();

  const meResult = useQuery({
    queryKey: ["me"],
    queryFn: () => me(),
    enabled: !!seesionUser,
  });

  const uploadAvatarMutation = useMutation({
    mutationKey: ["avatar", "upload"],
    mutationFn: async (file: File) => {
      const data = await uploadAvatar({ file });
      dispatch(setAvatar(file));
      return data;
    },
    onSuccess: ({ data }) => {
      setData((value: any) => {
        return { ...value, avatar: data };
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const uploadHeaderMutation = useMutation({
    mutationKey: ["header", "upload"],
    mutationFn: async (file: File) => {
      const data = await uploadHeader({ file });
      dispatch(setHeaderImage(file));
      return data;
    },
    onSuccess: ({ data }) => {
      setData((value: any) => {
        return { ...value, header: data };
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const updateMutation = useMutation({
    mutationKey: ["update-profile"],
    mutationFn: async (request: UpdateRequest) => {
      if (Object.keys(metaData).some((item: any) => item?.valid === false)) {
        throw new Error("Username already taken. Please choose another one.");
      }

      return await update(request);
    },
    onSuccess: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          variant={Alert.MessageVariants.OK}
          message={t("components.createJob.alertmsg")}
          title={t("components.createJob.alerttitle")}
        />,
      );
      if (data.type) {
        window.location.reload();
      }
    },
    onError: (error: any) => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          variant={Alert.MessageVariants.Error}
          message={error?.message}
          title={t("components.createJob.alerttitle")}
        />,
      );
    },
  });

  if (!meResult.isSuccess) return <span>Loading...</span>;

  const user = meResult.data.data;
  const type: "company" | "club" | "person" = user.type;

  if (!selectedTab) {
    setSelectedTab(tabs(t)[type][0].key);
  }

  if (!selectedTab) return <span>Loading...</span>;

  const selectForward = () => {
    for (const [i, tab] of tabs(t)[type].entries()) {
      if (i + 1 === tabs(t)[type].length) {
        return;
      }
      if (tab.key === selectedTab) {
        setSelectedTab(tabs(t)[type][i + 1].key);
      }
    }
  };

  const selectBackward = () => {
    for (const [i, tab] of tabs(t)[type].entries()) {
      if (tab.key === selectedTab) {
        if (i === 0) {
          return;
        }
        setSelectedTab(tabs(t)[type][i - 1].key);
      }
    }
  };

  const imageSrc = (storedImage: File | undefined, backendImage: string | undefined) => {
    if (storedImage) {
      return storedImage;
    } else if (backendImage !== "") {
      return backendImage;
    } else {
      return undefined;
    }
  };

  const handleNewAdmins = (newAdminList: Array<UserType>) => {
    setAdminList(newAdminList);
    const administrators = newAdminList.map(user => ({ admin_id: user.id }));
    setData((data: any) => {
      return { ...data, administrators };
    });
  };

  return (
    <>
      <div className="px-4 pt-4">
        <BackButton stepback />
        <TabFilter value={selectedTab} options={tabs(t)[type]} onChange={setSelectedTab} />
      </div>
      <Panel mobileBorder={false}>
        <div className="grid grid-cols-2 items-end gap-2">
          {selectedTab === "contact_person" && (
            <div className="col-span-2">
              <div className="my-2 font-semibold">
                {t("main.navLinks.jobsPublished.contactPerson")}
              </div>
              <ContactsEditor
                onChange={(data: Array<UserType>) => {
                  setUserList(data);
                }}
                contacts={userList}
                allowCustom
              />
              <div className="my-2 font-semibold">
                {t("main.profileViewTabs.editProfile.form.contact.admin")}
              </div>
              <ContactsEditor onChange={handleNewAdmins} contacts={adminList} />
            </div>
          )}
          {selectedTab === "socials" && (
            <ProfileEditSocialsContainer
              value={data.socials ?? user.socials}
              onChange={socials =>
                setData((data: any) => {
                  return { ...data, socials };
                })
              }
            />
          )}
          {selectedTab === "cv" && (
            <div className="col-span-2">
              <ProfileEditCVList
                name="cv"
                onChange={cv =>
                  setData((data: any) => {
                    return { ...data, cv };
                  })
                }
                values={data.cv ?? user.person?.cv}
                addText={t("main.profileViewTabs.editProfile.form.careerInfo.addEntry")}
                label={t("main.profileViewTabs.editProfile.form.careerInfo.career")}
              />
              <ProfileEditCVList
                name="education"
                onChange={education =>
                  setData((data: any) => {
                    return { ...data, education };
                  })
                }
                values={data.education ?? user.person?.education}
                addText={t("main.profileViewTabs.editProfile.form.careerInfo.addEntry")}
                label={t("main.profileViewTabs.editProfile.form.careerInfo.training")}
              />
            </div>
          )}
          {selectedTab !== "socials" &&
            selectedTab !== "contact_person" &&
            ProfileEditConfig(t)[user.type][selectedTab].map(
              (item: {
                type: string;
                name: string;
                disabled: boolean;
                label: string;
                link: string;
                cols: number;
                items: Array<any>;
                placeholder: string;
                pattern: string;
                maxLength: number;
                title: string;
                filter?: Array<string>;
                replacement?: Array<ReplacementType>;
              }) => (
                <div key={item.name} className={`${item.cols === 2 && "col-span-2"}`}>
                  {item.type === "input" && (
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      disabled={item.disabled}
                      pattern={item.pattern}
                      title={item.title}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      defaultValue={getProfileData(item.name, user)}
                      replacement={item.replacement}
                      onChange={e =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        })
                      }
                    />
                  )}
                  {item.type === "username" && (
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      disabled={item.disabled}
                      pattern={item.pattern}
                      title={item.title}
                      valid={metaData[item.name]?.valid}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      defaultValue={getProfileData(item.name, user)}
                      replacement={item.replacement}
                      onChange={async e => {
                        const isUnique = await checkUsername(e);
                        setMetaData((value: any) => {
                          return { ...value, [item.name]: { valid: isUnique } };
                        });
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        });
                      }}
                    />
                  )}
                  {item.type === "usersearch" && (
                    <UserComboBox
                      onSelect={e => {
                        setData((value: any) => {
                          return { ...value, [item.name]: getName(e) };
                        });
                      }}
                      onChange={e => {
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        });
                      }}
                      label={item.label}
                      userType={item.filter ?? []}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      placeholder={item.placeholder}
                    />
                  )}
                  {item.type === "number" && (
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      disabled={item.disabled}
                      type="number"
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      defaultValue={getProfileData(item.name, user)}
                      onChange={e => {
                        setData((value: any) => {
                          return {
                            ...value,
                            [item.name]: parseInt(e),
                          };
                        });
                      }}
                    />
                  )}
                  {item.type === "date" && (
                    <Input
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      disabled={item.disabled}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      type="date"
                      defaultValue={getProfileData(item.name, user)}
                      onChange={e =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        })
                      }
                    />
                  )}
                  {item.type === "link" && (
                    <a
                      className="text-sm hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={item.link}
                    >
                      {item.label}
                    </a>
                  )}
                  {item.type === "textarea" && (
                    <>
                      <label className="block text-sm font-medium text-gray-700">
                        {item.label}
                      </label>
                      <TextareaAutosize
                        minRows={3}
                        onChange={e =>
                          setData((value: any) => {
                            return { ...value, [item.name]: e.target.value };
                          })
                        }
                        value={data[item.name] ?? getProfileData(item.name, user)}
                        defaultValue={(user as any)[item.name]}
                        className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
                        maxLength={item.maxLength}
                      />
                    </>
                  )}
                  {item.type === "select" && item.items && (
                    <Select
                      name={item.name}
                      items={item.items}
                      disabled={item.disabled}
                      value={data[item.name]}
                      defaultValue={getProfileData(item.name, user)}
                      label={item.label}
                      onChange={e =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e.target.value };
                        })
                      }
                    />
                  )}
                  {item.type === "customselect" && item.items && (
                    <CustomSelect
                      options={item.items}
                      disabled={item.disabled}
                      defaultValue={getProfileData(item.name, user)}
                      label={item.label}
                      onChange={e =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        })
                      }
                    />
                  )}
                  {item.type === "tags" && (
                    <Tags label={item.label} name={item.name} placeholder={item.placeholder} />
                  )}
                  {item.type === "legal" && (
                    <LegalInput
                      name={item.name}
                      label={item.label}
                      maxLength={item.maxLength}
                      value={data[item.name] ?? getProfileData(item.name, user)}
                      onChange={value =>
                        setData((oldData: any) => {
                          return { ...oldData, [item.name]: value };
                        })
                      }
                    />
                  )}
                  {item.type === "checkbox" && (
                    <Checkbox
                      name={item.name}
                      checked={data[item.name] ?? getProfileData(item.name, user)}
                      onChange={e =>
                        setData((value: any) => {
                          return { ...value, [item.name]: e };
                        })
                      }
                    >
                      {item.label}
                    </Checkbox>
                  )}
                </div>
              ),
            )}
        </div>
        {selectedTab === "common" && (
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
            <ImageUpload
              name="avatar"
              onChange={(newImage: File) => {
                uploadAvatarMutation.mutate(newImage);
              }}
              label={t("main.profileViewTabs.editProfile.form.uploadProfilePicture")}
              borderRadius={100}
              value={imageSrc(avatar ?? undefined, user.avatar_url)}
            />
            <ImageUpload
              name="header"
              onChange={(newImage: File) => {
                uploadHeaderMutation.mutate(newImage);
              }}
              label={t("main.profileViewTabs.editProfile.form.uploadHeaderImage")}
              value={imageSrc(headerImage ?? undefined, user.header_url)}
            />
          </div>
        )}
        <BottomProfileEditTabs
          showForward={selectedTab !== "socials"}
          showBackward={selectedTab !== "common"}
          backward={selectBackward}
          forward={selectForward}
          submit={() => updateMutation.mutate({ data })}
        />
      </Panel>
    </>
  );
};

// TODO: refactor to avoid @ts-ignore
const getProfileData = (key: string, data: UserType) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data.type === "person" && data?.person[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.person[key];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data.type === "company" && data?.company[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.company[key];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data.type === "club" && data?.club[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.club[key];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (data && data[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data[key];
  }
  return "";
};

export default ProfileEditContainer;
