import React, { ReactElement, useEffect, useState } from "react";

import TabBodies, { TabBodiesProps } from "@src/components/elements/shared/tab/TabBodies";
import TabHeaders, { TabHeadersProps } from "@src/components/elements/shared/tab/TabHeaders";

type TabControllerProps = {
  children: ReactElement[] | ReactElement;
  defaultTab?: string;
  isSubTab?: boolean;
  isPersistent?: boolean;
};

/**
 * component to control tabs
 *
 * @param defaultTab Selected tab by default
 * @param isSubTab Tab menu is using sub tab styles
 * @param isPersistent Keeps tab content rendered when hidden
 */
const TabController: React.FC<TabControllerProps> = ({
  children,
  defaultTab,
  isSubTab = false,
  isPersistent = false,
}: TabControllerProps) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return (
    <>
      {React.Children.map(children, child => {
        if ((child as ReactElement<ReactElement[] | ReactElement>).type === TabHeaders) {
          return React.cloneElement(child as ReactElement<TabHeadersProps>, {
            activeTab,
            setActiveTab,
            isSubTab,
          });
        } else if ((child as ReactElement<ReactElement[] | ReactElement>).type === TabBodies) {
          return React.cloneElement(child as ReactElement<TabBodiesProps>, {
            activeTab,
            isPersistent,
          });
        } else return child;
      })}
    </>
  );
};

export default TabController;
