import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import updateEmail from "@src/api/account/emailChange";
import me from "@src/api/auth/me";
import ChangeAlert from "@src/components/elements/ChangeAlert";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import useChangeMutation from "@src/hooks/useChangeMutation";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useAppSelector } from "@src/state/hooks";

const ChangeEmailContainer = () => {
  const [_currentEmail, setCurrentEmail] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const [buttonsIsDisabled, setButtonsIsDisabled] = useState(true);

  const meResult = useQuery({
    queryKey: ["me"],
    queryFn: () => me(),
    enabled: !!user,
  });

  const {
    mutation: changeEmailMutation,
    showAlert,
    setShowAlert,
    isSuccess,
  } = useChangeMutation(updateEmail, "update-email", {
    onSettled: () => {
      setCurrentEmail("");
      setNewEmail("");
    },
  });

  useEffect(() => {
    setButtonsIsDisabled(
      !(_currentEmail && newEmail && isValidEmail(_currentEmail) && isValidEmail(newEmail)),
    );
  }, [_currentEmail, newEmail]);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9.-]+\.[a-z]{2,}$/gi;
    return emailRegex.test(email);
  };

  if (changeEmailMutation.isPending) {
    return <div>{t("main.loading")}</div>;
  }

  if (!meResult.isSuccess) return <></>;

  let maskedEmail = meResult.data.data.email ?? "";

  if (maskedEmail.length > 5) {
    maskedEmail = maskedEmail.slice(0, 5) + "X".repeat(maskedEmail.length - 5);
  }

  return (
    <AuthWrapper>
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.panel.profileWidgets.changeEmail")}{" "}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="current email"
          label={t("main.panel.profileWidgets.currentEmail")}
          value=""
          placeholder={maskedEmail}
          type="text"
          onChange={e => setCurrentEmail(e)}
        />
        <Input
          name="new email"
          label={t("main.panel.profileWidgets.newEmail")}
          value={newEmail}
          placeholder=""
          type="text"
          onChange={e => setNewEmail(e)}
        />
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button
            disabled={buttonsIsDisabled}
            onClick={() =>
              !changeEmailMutation.isPending &&
              changeEmailMutation.mutate({
                data: {
                  old_email: _currentEmail,
                  new_email: newEmail,
                },
              })
            }
          >
            {t("main.panel.profileWidgets.changeEmail")}
          </Button>
        </div>
      </form>

      {showAlert && (
        <ChangeAlert
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          isSuccess={isSuccess}
          type={"email"}
        />
      )}
    </AuthWrapper>
  );
};

export default ChangeEmailContainer;
