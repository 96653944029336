import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

import Panel from "@src/components/box/Panel";
import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import CheckboxGroup from "@src/components/elements/input/CheckboxGroup";
import Input from "@src/components/elements/input/Input";
import LegalInput from "@src/components/elements/input/LegalInput";
import Select from "@src/components/elements/input/Select";
import Tags from "@src/components/elements/input/Tags";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import TabFilter from "@src/components/menu/TabFilter";
import AssignmentCreateConfig, {
  FormElement,
} from "@src/pages/assignments/create/AssignmentCreateConfig";
import { useAppSelector } from "@src/state/hooks";

type dataType = {
  [category: string]: string | number | boolean;
};

function AssignmentsCreateContainer() {
  const { user } = useAppSelector(state => state.user);
  const [data, setData] = useState<dataType>({});
  const [category, setCategory] = useState("standard");
  const { t } = useTranslation();

  const tabs = [
    { name: t("main.navLinks.createAd.tabs.standard"), key: "standard" },
    { name: t("main.navLinks.createAd.tabs.service"), key: "ads" },
    { name: t("main.navLinks.createAd.tabs.vehicles"), key: "vehicle" },
    {
      name: t("main.navLinks.createAd.tabs.property"),
      key: "property",
    },
  ];

  if (!user) return <span>Loading...</span>;

  const renderType = (item: FormElement): ReactNode => {
    switch (item.type) {
      case "empty":
        return <div></div>;

      case "input":
        return (
          <Input
            name={item.name ?? ""}
            placeholder={item.placeholder}
            label={item.label}
            onChange={e =>
              setData((value: dataType) => {
                return { ...value, [item.name ?? ""]: e };
              })
            }
          />
        );

      case "date":
        return (
          <Input
            name={item.name ?? ""}
            type="date"
            placeholder={item.label}
            label={item.label}
            onChange={e =>
              setData((value: dataType) => {
                return { ...value, [item.name ?? ""]: e };
              })
            }
          />
        );

      case "link":
        return (
          <a
            className="text-sm hover:underline"
            rel="noopener noreferrer"
            target="_blank"
            href={item.link}
          >
            {item.label}
          </a>
        );

      case "textarea":
        return (
          <div>
            <label className="block text-sm font-medium text-gray-700">{item.label}</label>
            <TextareaAutosize
              minRows={3}
              onChange={e =>
                setData((value: dataType) => {
                  return { ...value, [item.name ?? ""]: e.target.value };
                })
              }
              value={(data[item.name ?? ""] ?? "").toString()}
              className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
            />
          </div>
        );

      case "select":
        return (
          <Select
            name={item.name ?? ""}
            items={item.items}
            label={item.label}
            onChange={e =>
              setData((value: dataType) => {
                return { ...value, [item.name ?? ""]: e.target.value };
              })
            }
          />
        );

      case "selectTab":
        return (
          <Select
            name={item.name ?? ""}
            items={item.items}
            label={item.label}
            onChange={e => {
              setData((value: dataType) => {
                return { ...value, [item.name ?? ""]: e.target.value };
              });
              setCategory(e.target.value);
            }}
          />
        );

      case "tags":
        return <Tags label={item.label} name={item.name ?? ""} placeholder={item.label} />;

      case "legal":
        return (
          <LegalInput
            name={item.name ?? ""}
            label={item.label ?? ""}
            value={data[item.name ?? ""].toString()}
            onChange={value =>
              setData((oldData: dataType) => {
                return { ...oldData, [item.name ?? ""]: value };
              })
            }
          />
        );

      case "radio":
        return (
          <div className="flex flex-col gap-1">
            <div className="text-gray-700">{item.label}</div>
            <div className="flex flex-col gap-2 lg:flex-row">
              {item.items?.map((subItem: FormElement, index: number) => (
                <div key={subItem.name ?? index} className="flex items-center gap-2">
                  <input
                    name={item.name}
                    id={item.name}
                    type="radio"
                    value={subItem.value ?? ""}
                    className="focus:ring-transparent"
                  />
                  {subItem.name}
                </div>
              ))}
            </div>
          </div>
        );

      case "label":
        return <div className="font-semibold">{item.label}</div>;

      case "checkboxgroup":
        return (
          <div className="flex flex-col gap-x-6 gap-y-2 text-black">
            <CheckboxGroup
              itemStyle={
                item.itemStyle ? item.itemStyle : "flex flex-col lg:flex-row gap-x-6 gap-y-0"
              }
              name={item.name ?? ""}
              label={item.label}
              items={item.items ?? []}
              onChange={() => {
                return null;
              }}
            />
          </div>
        );

      case "checkbox":
        return (
          <div className="flex flex-col gap-x-6 gap-y-2 text-black">
            <Checkbox
              name={item.name ?? ""}
              checked
              onChange={() => {
                return null;
              }}
            >
              {item.label}
            </Checkbox>
          </div>
        );

      case "filesUpload":
        return (
          <div className="flex flex-col gap-x-6 gap-y-2 text-black">
            <input
              type="file"
              name={item.name}
              accept={item.formats ?? "*"}
              className="mb-0.5 truncate border-b border-gray-400 px-1 py-0.5 text-left text-xs"
              multiple={item.multiple}
            />
          </div>
        );

      default:
        return <div>Unbekanntes Eingabefeld</div>;
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <TabFilter value={category} options={tabs} onChange={value => setCategory(value)} />

      <Panel>
        <div className="grid grid-flow-row gap-2 text-sm">
          <PostUserSelect />
          {AssignmentCreateConfig(t)[category]?.map((item: FormElement | FormElement[]) => {
            const itemArray = Array.isArray(item) ? item : [item];

            return (
              <div
                key={itemArray[0].name}
                className="grid grid-flow-row items-end gap-2 lg:grid-flow-col"
              >
                {itemArray.map((subItem: FormElement) => {
                  return <>{renderType(subItem)}</>;
                })}
              </div>
            );
          })}

          <div className="grid grid-flow-row gap-2 lg:grid-flow-col">
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.back")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("buttons.duplicate")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.preview")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("main.groupsInfoForm.buttons.save")}
            </Button>
            <Button
              onClick={() => {
                return;
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              {t("buttons.publish")}
            </Button>
          </div>
        </div>
      </Panel>
    </form>
  );
}

export default AssignmentsCreateContainer;
