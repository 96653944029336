import { useState } from "react";

import feed from "@src/api/feed/feed";
import PostWidget from "@src/components/elements/PostWidget";
import SuggestionsContainer from "@src/components/elements/sidebar/suggestions/SuggestionsContainer";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import nextPost from "@src/utils/nextPost";
import prevPost from "@src/utils/prevPost";

type Props = {
  searchQuery?: string;
  userId?: string;
};

const PostsContainer = ({ searchQuery, userId }: Props) => {
  const [lightboxSelection, setLightboxSelection] = useState("");
  const queryParams = {
    q: searchQuery ?? null,
    user_id: userId ?? null,
    liked_by: null,
  };

  const { result: feedResult } = useInfiniteQueryOnScroll(
    {
      queryKey: ["posts", "feed", queryParams],
      queryFn: ({ pageParam }) =>
        feed({
          params: {
            ...queryParams,
            page: pageParam,
          },
        }),
      initialPageParam: 0,
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : null;
      },
    },
    { offset: 10000 },
  );

  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      {feedResult.data.pages.map((page, pageIndex) =>
        page.data.map((post, index: number) => (
          <>
            {[6, 13, 20, 27].includes(index) && (
              <SuggestionsContainer
                id={`post-suggestions:${index}`}
                key={`post-suggestions:${index}`}
              />
            )}
            <div
              className="max-lg:odd:border-y max-lg:odd:border-y-gray-300 max-lg:odd:py-2"
              key={`post:${post.id}`}
            >
              <PostWidget
                key={`post-widget:${post.id}`}
                postId={post.id}
                post={post}
                text={post.text}
                author={post.reposted ? post.reposted.author : post.author}
                repost={post.reposted}
                created_at={post.created_at}
                onSwitch={(id: string) => {
                  setLightboxSelection(id);
                }}
                next={nextPost({ index, pageIndex, postsResult: feedResult })}
                prev={prevPost({ index, pageIndex, postsResult: feedResult })}
                lightboxSelection={lightboxSelection}
              />
            </div>
          </>
        )),
      )}
    </>
  );
};

export default PostsContainer;
