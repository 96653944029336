import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import chatsArchive from "@src/api/chats/archive/chats";
import chats from "@src/api/chats/chats";
import chatsGroups from "@src/api/chats/groups/chats";
import create from "@src/api/chats/messages/create";
import PopupWrapper from "@src/components/elements/PopupWrapper";
import UserWidget from "@src/components/elements/UserWidget";
import Button from "@src/components/elements/input/Button";
import TabFilter from "@src/components/menu/TabFilter";
import { getChatPartner } from "@src/pages/messenger/Messenger";
import { useAppSelector } from "@src/state/hooks";
import { MediaType } from "@src/types/MediaType";
import getName from "@src/utils/getName";

type Props = {
  onClose: () => void;
  message: {
    text: string;
    link?: string;
    media?: MediaType[];
  };
};

const ForwardMessagePopup = ({ onClose, message }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [chatFilter, setChatFilter] = useState("");
  const [selectedChats, setSelectedChats] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const chatsResult = useQuery({
    queryKey: ["chats", chatFilter],
    queryFn: () => {
      switch (chatFilter) {
        case "archive":
          return chatsArchive();
        case "groups":
          return chatsGroups();
        default:
          return chats();
      }
    },
    enabled: !!user,
  });

  const createMutation = useMutation({
    mutationKey: ["chats", "messages", "create"],
    mutationFn: async () => {
      for (const selectedChat of selectedChats) {
        await create({
          id: selectedChat,
          data: {
            text: message.text,
            link: message.link,
            media: message.media?.map(m => m.id),
          },
        });
        queryClient.invalidateQueries({
          queryKey: ["chats", "messages", selectedChat],
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      queryClient.invalidateQueries({
        queryKey: ["chats", "messages"],
      });
      onClose();
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (!user) return <></>;
  if (!chatsResult.isSuccess) return <></>;

  return (
    <PopupWrapper onClose={onClose}>
      <div className="no-scrollbar flex flex-col gap-2">
        <TabFilter
          options={[
            { name: "Chats", key: "" },
            { name: "Archiviert", key: "archive" },
            { name: "Gruppen", key: "groups" },
          ]}
          value={chatFilter}
          onChange={setChatFilter}
          subTab={true}
        />
        {chatsResult.data.data.map(chat => (
          <button
            key={chat.id}
            className="group relative flex flex-row gap-2 rounded-2xl bg-gray-200 p-2"
            onClick={() =>
              setSelectedChats(data => {
                if (data.includes(chat.id)) {
                  return data.filter(id => id !== chat.id);
                } else {
                  return [...data, chat.id];
                }
              })
            }
          >
            <div className="flex h-[45px] items-center justify-center">
              <input
                id="chat-checkbox"
                name="chat-checkbox"
                type="checkbox"
                checked={selectedChats.includes(chat.id)}
                className="size-4 rounded-full border-gray-300 text-blue-500 focus:ring-offset-0"
              />
            </div>
            <div className="my-auto">
              <UserWidget
                user={getChatPartner(chat.members, user)}
                follow={false}
                online={false}
                size={45}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-left font-semibold">
                {getName(getChatPartner(chat.members, user))}
              </div>
              <div className="line-clamp-1 text-left text-xs">
                {chat.last_message_text.startsWith("chat/media/")
                  ? "Media"
                  : chat.last_message_text}
              </div>
              <div className="text-left text-xs">{chat.created_at}</div>
            </div>
          </button>
        ))}
        <Button onClick={() => createMutation.mutate()}>Senden</Button>
      </div>
    </PopupWrapper>
  );
};

export default ForwardMessagePopup;
