import { useTranslation } from "react-i18next";

import Button from "@src/components/elements/input/Button";
import StarsRating from "@src/components/elements/input/StarsRating";
import ScoreBars from "@src/components/elements/shop/ScoreBars";
import WriteReviewButton from "@src/pages/jobs/details/actionbar/WriteReviewButton";

type Props = {
  rating?: number;
};

const ScoreWrapper = ({ rating = 0 }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="inline-flex gap-8 text-sm">
      <div className="w-auto">
        <div className="mb-2 text-lg font-semibold text-darkred">
          {t("main.shop.details.review")}
        </div>
        <div className="inline-flex items-center gap-2 text-base">
          <StarsRating rating={5} />
          {5} von 5
        </div>
        <div className="mb-4 text-gray-600">
          {t("main.shop.details.reviews", { count: rating })}
        </div>
        <ScoreBars />

        <div className="mt-4 flex flex-col gap-2">
          <div className="text-sm font-normal">{t("main.navLinks.jobsPublished.rateJob")}</div>

          <div className="-mt-2 ml-2 w-full md:w-[200px]">
            <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small}>
              <WriteReviewButton />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreWrapper;
