import { createSlice } from "@reduxjs/toolkit";

interface AutoScrollConfigState {
  isAutoScrollEnabled: boolean;
}

const initialState: AutoScrollConfigState = {
  isAutoScrollEnabled: false,
};

const autoScrollSlice = createSlice({
  name: "autoScrollConfig",
  initialState,
  reducers: {
    enableAutoScroll: state => {
      state.isAutoScrollEnabled = true;
    },
    disableAutoScroll: state => {
      state.isAutoScrollEnabled = false;
    },
  },
});

export const { enableAutoScroll, disableAutoScroll } = autoScrollSlice.actions;

export default autoScrollSlice.reducer;
