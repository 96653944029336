import { MinusIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from "@src/components/elements/input/Button";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import { useAppSelector } from "@src/state/hooks";
import { setImageEditor } from "@src/state/profileImages/imageEditorSlice";

type Props = {
  label?: string;
  value: File | string | undefined;
  name?: string;
  onChange?: (e: File) => void;
  formats?: string;
  multiple?: boolean;
  required?: boolean;
  borderRadius?: number;
};

const ImageUpload = ({
  label,
  onChange,
  name,
  value,
  multiple = false,
  required = false,
  formats = "image/*",
  borderRadius = 0,
}: Props) => {
  const [showImage, setShowImage] = useState(false);
  const [scale, setScale] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [edited, setEdited] = useState(false);
  const editor = useRef<AvatarEditor>(null);
  const { t } = useTranslation();
  // Workaround till s3 CORS is fixed
  const { imageEditor } = useAppSelector(state => state.imageEditor);
  const dispatch = useDispatch();
  // Workaround till here

  const changeScale = (diff: number) => {
    let newScale = scale + diff;
    if (newScale < 0.1) newScale = 0.1;
    if (newScale > 5) newScale = 5;
    setScale(newScale);
    setEdited(true);
  };

  const changeRotation = (diff: number) => {
    let newRotation = rotation + diff;
    if (Math.abs(newRotation) > 360) newRotation = Math.abs(newRotation % 360);
    setRotation(newRotation);
    setEdited(true);
  };

  const onClose = () => {
    setShowImage(false);
  };

  const reset = () => {
    setScale(1);
    setRotation(0);
    setEdited(false);
  };

  const onSave = () => {
    if (!editor.current) return;
    const canvas = editor.current.getImageScaledToCanvas();
    canvas.toBlob(
      blob => {
        if (blob) {
          const file = new File([blob], "newimage.jpg", {
            type: "image/jpg",
            lastModified: Date.now(),
          });
          if (file && onChange) onChange(file);
        }
      },
      "image/jpg",
      1,
    );
  };

  const showImageSource = (value: File | string | undefined) => {
    if (value instanceof File) {
      return URL.createObjectURL(value);
    } else if (typeof value === "string") {
      return value;
    } else {
      return "";
    }
  };

  // Workaround function till s3 CORS is fixed
  const fetchImage = async (url: string) => {
    try {
      const response = await fetch(url, { cache: "reload" });
      if (response.status === 200) {
        const myBlob = await response.blob();
        const objectURL = URL.createObjectURL(myBlob);
        dispatch(setImageEditor(objectURL));
        return objectURL;
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  return (
    <>
      <div>
        {label && <div className="text-sm text-gray-600">{label}</div>}
        <div className="grid grid-flow-row overflow-hidden border border-gray-600">
          <input
            type="file"
            name={name}
            accept={formats}
            onChange={e => {
              if (!e.target.files) return;
              if (onChange) onChange(e.target.files[0]);
            }}
            className="mb-0.5 truncate border-b border-gray-400 px-1 py-0.5 text-left text-xs"
            multiple={multiple}
            required={required}
          />
          <button
            className="flex justify-center bg-black"
            onClick={() => {
              if (!value) {
                return;
              } else {
                setShowImage(true);
                // Workaround until s3 CORS is fixed
                if (value instanceof File) {
                  dispatch(setImageEditor(value));
                } else if (typeof value === "string") {
                  fetchImage(value);
                }
                // Workaround end
              }
            }}
          >
            <img alt="" className="size-[200px] object-cover" src={showImageSource(value)} />
          </button>
        </div>
      </div>
      <ModalWrapper
        onCancel={() => {
          onClose();
        }}
        onConfirm={() => {
          onSave();
          reset();
          onClose();
        }}
        open={showImage}
      >
        <button
          onClick={() => {
            onClose();
          }}
          className="absolute right-3 top-3"
        >
          <XMarkIcon className="size-8 text-black" />
        </button>
        <div className="mb-2 font-semibold">{label}</div>
        <div
          className="mb-2 flex justify-center bg-neutral-800"
          onWheel={e => {
            changeScale(Math.sign(e.deltaY) * -0.1);
          }}
        >
          <AvatarEditor
            ref={editor}
            image={imageEditor}
            border={0}
            scale={scale}
            rotate={rotation}
            borderRadius={borderRadius}
            crossOrigin="anonymous"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 max-xl:mb-2">
          <div className="flex flex-col gap-2">
            <div className="text-left text-sm">
              {t("main.profileViewTabs.editProfile.form.scale")}
              {(scale * 100).toFixed(0)}%
            </div>
            <div className="flex flex-row gap-1">
              <button
                onClick={() => {
                  changeScale(-0.1);
                }}
              >
                <MinusIcon className="size-4" />
              </button>
              <input
                type="range"
                min="0.10"
                max="5"
                value={scale}
                step={0.1}
                onChange={e => {
                  setScale(Number(e.target.value));
                  setEdited(true);
                }}
                className="range-visible"
                onWheel={e => {
                  changeScale(Math.sign(e.deltaY) * -0.1);
                }}
              />
              <button
                onClick={() => {
                  changeScale(0.1);
                }}
              >
                <PlusIcon className="size-4" />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between gap-2">
              <div className="whitespace-nowrap text-sm">
                {t("main.profileViewTabs.editProfile.form.rotation")}
                {rotation}°
              </div>
            </div>
            <div className="flex flex-row gap-1">
              <button
                onClick={() => {
                  changeRotation(-10);
                }}
              >
                <MinusIcon className="size-4" />
              </button>
              <input
                type="range"
                min="-360"
                max="360"
                value={rotation}
                step={10}
                onChange={e => {
                  setRotation(Number(e.target.value));
                  setEdited(true);
                }}
                className="range-visible"
                onWheel={e => {
                  changeRotation(Math.sign(e.deltaY) * -10);
                }}
              />
              <button
                onClick={() => {
                  changeRotation(10);
                }}
              >
                <PlusIcon className="size-4" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          {edited && (
            <div>
              <Button
                onClick={() => {
                  reset();
                }}
              >
                {t("main.profileViewTabs.editProfile.form.restore")}
              </Button>
            </div>
          )}
        </div>
      </ModalWrapper>
    </>
  );
};

export default ImageUpload;
