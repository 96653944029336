import { useTranslation } from "react-i18next";

import Alert from "@src/components/elements/Alert";

interface ChangeAlertProps {
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess: boolean;
  type: "email" | "password" | "username";
}

const ChangeAlert = ({ showAlert = false, setShowAlert, isSuccess, type }: ChangeAlertProps) => {
  const { t } = useTranslation();

  const getTypeTranslation = (type: string) => {
    switch (type) {
      case "email":
        return t("main.profileViewTabs.editProfile.form.email");
      case "password":
        return t("main.signIn.password");
      case "username":
        return t("main.profileViewTabs.editProfile.form.username");
      default:
        return "";
    }
  };

  const translatedType = getTypeTranslation(type);

  return (
    showAlert && (
      <Alert
        onClose={() => {
          setShowAlert(false);
        }}
        buttons={Alert.ButtonVariants.None}
        variant={isSuccess ? Alert.MessageVariants.OK : Alert.MessageVariants.Error}
        timeout={5000}
        message={
          isSuccess
            ? t("main.alerts.changeSuccess", { type: translatedType })
            : t("main.alerts.changeError", { type: translatedType })
        }
        title={t("main.alerts.changeTitle", { type: translatedType })}
      />
    )
  );
};

export default ChangeAlert;
