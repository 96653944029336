import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxIcon,
  FlagIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import archive from "@src/api/chats/archive";
import remove from "@src/api/chats/remove";
import unarchive from "@src/api/chats/unarchive";
import block from "@src/api/users/block";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import { showReportModal } from "@src/state/reportModal/actions";

type Props = {
  archived?: boolean;
  isAdmin?: boolean;
  chatId: string;
  userId: string;
  exitChat: () => void;
  toggleSearch?: () => void;
  openEditModal?: () => void;
  profileLink?: () => void;
  isGroup?: boolean;
};
const ChatDropdown = ({
  archived,
  chatId,
  exitChat,
  toggleSearch,
  userId,
  openEditModal,
  isGroup,
  isAdmin,
  profileLink,
}: Props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const archiveMutation = useMutation({
    mutationKey: ["chats", "archive"],
    mutationFn: archive,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chat", chatId] });
      queryClient.invalidateQueries({ queryKey: ["chats"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unarchiveMutation = useMutation({
    mutationKey: ["chats", "unarchive"],
    mutationFn: unarchive,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chat", chatId] });
      queryClient.invalidateQueries({ queryKey: ["chats"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const removeMutation = useMutation({
    mutationKey: ["chats", "remove"],
    mutationFn: remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chat", chatId] });
      queryClient.invalidateQueries({ queryKey: ["chats"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const blockMutation = useMutation({
    mutationKey: [`block-${userId}`],
    mutationFn: block,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chat", chatId] });
      queryClient.invalidateQueries({ queryKey: ["chats"] });
    },
    onError: () => {
      alert("Failed");
    },
  });
  const { t } = useTranslation();

  return (
    <ContextMenu
      items={[
        toggleSearch && {
          label: t("main.feedTabs.placeholder"),
          onClick: () => {
            toggleSearch();
          },
          icon: MagnifyingGlassIcon,
        },
        openEditModal &&
          isGroup &&
          isAdmin && {
            label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
            onClick: () => {
              openEditModal();
            },
            icon: PencilSquareIcon,
          },
        !archived
          ? {
              label: t("pages.messenger.archive"),
              onClick: () => {
                archiveMutation.mutate({ id: chatId });
              },
              icon: ArchiveBoxArrowDownIcon,
            }
          : {
              label: t("pages.messenger.unarchive"),
              onClick: () => {
                unarchiveMutation.mutate({ id: chatId });
              },
              icon: ArchiveBoxIcon,
            },
        {
          label: t("buttons.report"),
          onClick: () => dispatch(showReportModal(chatId, "chat")),
          icon: FlagIcon,
        },
        {
          label: isGroup ? t("pages.messenger.blockGroup") : t("pages.messenger.blockUser"),
          onClick: () => {
            exitChat();
            blockMutation.mutate({ id: userId });
          },
          icon: MinusCircleIcon,
        },
        {
          label: isGroup ? t("pages.messenger.groupProfile") : t("pages.messenger.userProfile"),
          onClick: () => {
            if (profileLink) profileLink();
          },
          icon: isGroup ? UserGroupIcon : UserIcon,
        },
        {
          label: t("pages.messenger.deleteChat"),
          onClick: () => {
            exitChat();
            removeMutation.mutate({ id: chatId });
          },
          icon: TrashIcon,
        },
      ]}
    />
  );
};

export default ChatDropdown;
