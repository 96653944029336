import React, { ReactElement } from "react";

import { TabBodyProps } from "@src/components/elements/shared/tab/TabBody";

export interface TabBodiesProps {
  children: ReactElement<TabBodyProps>[] | ReactElement<TabBodyProps>;
  activeTab?: string;
  isPersistent?: boolean;
}

/**
 * Component that contains the tab content
 *
 * @param activeTab Current active tab
 * @param isPersistent Keeps tab content rendered when hidden
 */
const TabBodies: React.FC<TabBodiesProps> = ({
  children,
  activeTab,
  isPersistent,
}: TabBodiesProps) => {
  if (isPersistent)
    return (
      <>
        {React.Children.map(children, child => {
          if ((child as ReactElement<TabBodyProps>).props.id !== activeTab) {
            return (
              <div className="hidden" aria-hidden="true">
                {child}
              </div>
            );
          } else return child;
        })}
      </>
    );
  else
    return (
      <>
        {React.Children.toArray(children).filter(child => {
          return (child as ReactElement<TabBodyProps>).props.id === activeTab;
        })}
      </>
    );
};

export default TabBodies;
