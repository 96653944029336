import {
  ArrowPathRoundedSquareIcon,
  BoltIcon,
  ClockIcon,
  FaceSmileIcon,
  ForwardIcon,
  LanguageIcon,
  PencilIcon,
  PhotoIcon,
  SparklesIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "@src/App";
import SoundsContainer from "@src/pages/watch/create/SoundsContainer";
import { MediaType } from "@src/types/MediaType";
import isMobile from "@src/utils/isMobile";

type Props = {
  dark?: boolean;
  onClose?: () => void;
  onChange?: () => void;
  media?: Array<MediaType>;
};

const EditContainer = ({ ...props }: Props) => {
  const appContext = useContext(AppContext);
  const [tab, setTab] = useState("basics");
  const { t } = useTranslation();
  const sidebarButtons = [
    {
      icon: <ArrowPathRoundedSquareIcon className="size-6" />,
      label: t("main.watch.create.turnaround"),
      onClick: () => {
        return;
      },
    },
    {
      icon: <ForwardIcon className="size-6" />,
      label: t("main.watch.create.speed"),
      onClick: () => {
        return;
      },
    },
    {
      icon: <SparklesIcon className="size-6" />,
      label: t("main.watch.create.beaty"),
      onClick: () => {
        return;
      },
    },
    {
      icon: <SquaresPlusIcon className="size-6" />,
      label: t("main.watch.create.filter"),
      onClick: () => {
        return;
      },
    },
    {
      icon: <ClockIcon className="size-6" />,
      label: t("main.watch.create.timer"),
      onClick: () => {
        return;
      },
    },
    {
      icon: <BoltIcon className="size-6" />,
      label: t("main.watch.create.light"),
      onClick: () => {
        return;
      },
    },
    {
      icon: <LanguageIcon className="size-6" />,
      label: t("main.watch.create.text"),
      onClick: () => {
        return;
      },
    },
    {
      icon: <FaceSmileIcon className="size-6" />,
      label: t("main.watch.create.stickers"),
      onClick: () => {
        return;
      },
    },
  ];

  if (isMobile) {
    if (appContext?.setFullscreen) appContext.setFullscreen(true);
  }

  return (
    <>
      {tab === "basics" && (
        <div className="relative h-screen w-screen bg-black text-white">
          <button
            className="absolute left-1/2 top-6 flex -translate-x-1/2 flex-row items-center gap-1 text-base"
            onClick={() => setTab("sounds")}
          >
            {t("main.header.links.sounds")}
          </button>
          <div className="absolute right-4 top-6 flex w-10 flex-col gap-4 text-mini">
            {sidebarButtons.map(button => {
              return (
                <button
                  key={button.label}
                  className="flex flex-col items-center"
                  onClick={button.onClick}
                >
                  {button.icon}
                  <div className="w-10 truncate xl:hidden">{button.label}</div>
                </button>
              );
            })}
          </div>
          <div className="absolute bottom-4 left-0 flex w-full flex-col gap-5">
            <div className="relative mx-auto">
              <button className="absolute left-0 top-1/2 flex -translate-x-[calc(100%+40px)] -translate-y-1/2 flex-col items-center text-mini">
                <PencilIcon className="size-6" />
                {t("main.watch.create.effects")}
              </button>
              <button className="mx-auto rounded-full bg-white p-1">
                <div className="rounded-full bg-black p-0.5">
                  <div className="size-[50px] rounded-full bg-red-500" />
                </div>
              </button>
              <button className="absolute right-0 top-1/2 flex -translate-y-1/2 translate-x-[calc(100%+40px)] flex-col items-center text-mini">
                <PhotoIcon className="size-6" />
                {t("components.shared.upload")}
              </button>
            </div>
            <div className="relative mx-auto text-sm">
              <button className="absolute left-0 top-1/2 -translate-x-[calc(100%+20px)] -translate-y-1/2 text-xs text-gray-300">
                60 Sec.
              </button>
              <button className="text-white">15 Sec.</button>
              <button className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-[calc(100%+20px)] text-xs text-gray-300">
                MV
              </button>
            </div>
            <button
              className="mx-auto bg-red-500 px-6 py-1 text-sm text-white"
              onClick={() => onclose}
            >
              {t("main.groupsInfoForm.buttons.further")}
            </button>
          </div>
          <button
            onClick={() => {
              if (props.onClose) {
                props.onClose();
                if (isMobile) {
                  if (appContext?.setFullscreen) appContext.setFullscreen(true);
                }
              }
            }}
          >
            <XMarkIcon className="absolute left-4 top-6 size-6 text-white" />
          </button>
        </div>
      )}

      {tab === "sounds" && <SoundsContainer onClose={() => setTab("basics")} />}
    </>
  );
};

export default EditContainer;
