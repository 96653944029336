import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Input from "@src/components/elements/input/Input";
import SoundsThumbnailList from "@src/components/elements/posts/sounds/SoundsThumbnailList";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import BackButton from "@src/components/nav/BackButton";
import Ads from "@src/pages/discover/tabcontent/Ads";
import Assignments from "@src/pages/discover/tabcontent/Assignments";
import Hashtags from "@src/pages/discover/tabcontent/Hashtags";
import Jobs from "@src/pages/discover/tabcontent/Jobs";
import News from "@src/pages/discover/tabcontent/News";
import Profiles from "@src/pages/discover/tabcontent/Profiles";
import Watch from "@src/pages/discover/tabcontent/Watch";

type Props = {
  tab: string;
  subTab?: string;
};

const DiscoverContainer = ({ tab, subTab }: Props) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();

  return (
    <>
      <BackButton stepback />

      <TabController defaultTab={tab}>
        <TabHeaders>
          <TabHeader url="/entdecken/hashtags/feed" id="hashtags">
            {t("main.bookmarks.tabs.hashtags")}
          </TabHeader>
          <TabHeader url="/entdecken/news" id="news">
            {t("main.bookmarks.tabs.news")}
          </TabHeader>
          <TabHeader url="/entdecken/watch" id="watch">
            {t("main.bookmarks.tabs.watch")}
          </TabHeader>
          <TabHeader url="/entdecken/sounds" id="sounds">
            {t("main.bookmarks.tabs.sounds")}
          </TabHeader>
          <TabHeader url="/entdecken/profile/alle" id="profiles">
            {t("main.bookmarks.tabs.profiles")}
          </TabHeader>
          <TabHeader url="/entdecken/jobs" id="jobs">
            {t("main.bookmarks.tabs.jobs")}
          </TabHeader>
          <TabHeader url="/entdecken/inserate" id="assignments">
            {t("main.bookmarks.tabs.advertisements")}
          </TabHeader>
          <TabHeader url="/entdecken/marketplace" id="ads">
            {t("main.bookmarks.tabs.marketplace")}
          </TabHeader>
        </TabHeaders>

        <Input
          placeholder={t("main.bookmarks.searchInput", {
            tab: _.capitalize(_.last(location.pathname.split("/"))),
          })}
          name="profiles_keywords"
          onChange={e => setSearchQuery(e)}
          clearable
        />

        <TabBodies>
          <TabBody id="hashtags">
            <Hashtags tab={subTab} />
          </TabBody>
          <TabBody id="watch">
            <Watch searchQuery={searchQuery} showPlaceholder />
          </TabBody>
          <TabBody id="sounds">
            <SoundsThumbnailList search={searchQuery} small fineGrid showPlaceholder />
          </TabBody>
          <TabBody id="news">
            <News searchQuery={searchQuery} showPlaceholder />
          </TabBody>
          <TabBody id="profiles">
            <Profiles searchQuery={searchQuery} tab={subTab} />
          </TabBody>
          <TabBody id="jobs">
            <Jobs searchQuery={searchQuery} showPlaceholder />
          </TabBody>
          <TabBody id="assignments">
            <Assignments showPlaceholder />
          </TabBody>
          <TabBody id="ads">
            <Ads searchQuery={searchQuery} showPlaceholder />
          </TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default DiscoverContainer;
