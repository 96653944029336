import api, { RequestWithData } from "@src/api/api";

type RequestData = {
  link_preview_id?: string;
  messageBody: string;
  media: string[];
};

type CreateRequest = RequestWithData<RequestData>;

export default async function create(request: CreateRequest): Promise<void> {
  const { data } = await api.post("/api/v1/chats/multicast/messages", request.data);
  return data;
}
