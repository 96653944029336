import api, { RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type BirthdaysParams = {
  page?: number;
  q?: string;
};

type BirthdaysRequest = RequestWithParams<BirthdaysParams>;

type BirthdaysResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function birthdays(request: BirthdaysRequest): Promise<BirthdaysResponse> {
  const { data } = await api.get(
    `/api/v1/contacts/birthdays${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
