import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

import PopupWrapper from "@src/components/elements/PopupWrapper";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AppLink from "@src/components/elements/shared/AppLink";
import LoginExternal from "@src/components/popup/LoginExternal";
import useLoginModal from "@src/state/modal/useLoginModal";
import useLoginMutation from "@src/utils/useLoginMutation";

const LoginPopup = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const loginMutation = useLoginMutation();

  return (
    <PopupWrapper onClose={() => loginModal.close()}>
      <div className="w-full md:max-w-[310px]">
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
          id="loginForm"
          className="grid grid-flow-row justify-center gap-1 text-center text-black"
        >
          <div className="text-lg font-semibold text-darkblue lg:text-xl lg:font-medium">
            {t("main.signIn.title1")}
            <br /> {t("main.signIn.title2")}
          </div>
          <Input
            name="email"
            label={t("main.signIn.email")}
            value={email}
            placeholder={t("main.signIn.placeholderEmail")}
            type="email"
            onChange={e => setEmail(e)}
          />
          <Input
            name="password"
            label={t("main.signIn.password")}
            value={password}
            placeholder={t("main.signIn.password")}
            type="password"
            onChange={e => setPassword(e)}
          />
          <Button
            submit
            size={Button.Sizes.Large}
            onClick={() =>
              !loginMutation.isPending &&
              loginMutation.mutate({
                data: {
                  email,
                  password,
                },
              })
            }
          >
            {t("main.signIn.login")}
          </Button>
          <LoginExternal />
          <div className="text-sm text-gray-700">{t("main.signIn.description")}</div>
          <Button
            size={Button.Sizes.Large}
            onClick={() => {
              loginModal.close();
              navigate("/registrierung");
            }}
            variant={Button.Variants.Cyan}
          >
            {t("main.signIn.createAccount")}
          </Button>
          <AppLink />
          <div className="flex justify-center gap-2">
            <NavLink to="/impressum" className="text-sm">
              {t("main.signIn.imprint")}
            </NavLink>
            <span className="text-sm">|</span>
            <NavLink to="/datenschutz" className="text-sm">
              {t("main.signIn.dataProtection")}
            </NavLink>
          </div>
        </form>
      </div>
    </PopupWrapper>
  );
};

export default LoginPopup;
