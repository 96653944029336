import api, { RequestWithParams } from "@src/api/api";
import { LinkPreviewType } from "@src/types/LinkPreviewType";

type LinkPreviewParams = {
  url: string;
};

type LinkPreviewRequest = RequestWithParams<LinkPreviewParams>;

type LinkPreviewResponse = {
  data: LinkPreviewType;
};

export default async function linkPreview(
  request: LinkPreviewRequest,
): Promise<LinkPreviewResponse> {
  const { data } = await api.get(`/api/v1/link-preview?url=${request.params.url}`);
  return data;
}
