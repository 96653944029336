import api, { RequestWithParams, requestParams } from "@src/api/api";
import { ProductType } from "@src/types/ProductType";

type ProductsParams = {
  page?: number;
  q?: string;
};

type ProductsRequest = RequestWithParams<ProductsParams>;

type ProductsResponse = {
  data: ProductType[];
  current_page: number;
  total_pages: number;
};

export default async function products(request: ProductsRequest): Promise<ProductsResponse> {
  const { data } = await api.get(
    `/api/v1/marketplace/products${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
