import api from "@src/api/api";
import { CartType } from "@src/types/CartType";

type CartsResponse = {
  data: CartType[];
};

export default async function carts(): Promise<CartsResponse> {
  const { data } = await api.get("/api/v1/carts");
  return data;
}
