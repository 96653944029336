import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";

import SharePopup from "@src/components/popup/SharePopup";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePopupModal from "@src/state/modal/usePopupModal";
import { MediaType } from "@src/types/MediaType";

type ContentType = {
  title?: string;
  body?: string;
  media?: Array<MediaType>;
};

type Props = {
  label?: string;
  content?: ContentType;
};

const ShareButton = ({ label = "", content = {} }: Props) => {
  const popupModal = usePopupModal();
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <PostsActionBarItem
      onClick={() =>
        checkAuth() && popupModal.open(<SharePopup onClose={popupModal.close} content={content} />)
      }
      label={label}
    >
      <ArrowPathRoundedSquareIcon />
    </PostsActionBarItem>
  );
};

export default ShareButton;
