import { t } from "i18next";

import Review from "@src/components/elements/shared/Review";
import CustomerJobReviews from "@src/mocks/CustomerJobReviews.json";

const JobReviews = () => {
  const reviews = CustomerJobReviews.reviews;
  return (
    <div className="w-full">
      <div className="text-base font-bold">{t("main.shop.details.customerReviews")}:</div>
      <div className="flex flex-col gap-4 pl-4">
        <div className="flow-root">
          <ul className="list-none divide-y divide-gray-200 dark:divide-gray-700">
            {reviews.map(review => (
              <div key={review.review_id}>
                <Review review={review} />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default JobReviews;
