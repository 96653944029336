import React from "react";
import { SocialIcon } from "react-social-icons";

import Panel from "@src/components/box/Panel";
import { UserType } from "@src/types/UserType";

type SocialsProps = { user: UserType };

const Socials: React.FC<SocialsProps> = ({ user }) => {
  const socials = user.socials || [];

  const uniqueSocials = [...new Map(socials.map(item => [item.username, item])).values()];

  return (
    <div className="flex flex-wrap gap-2">
      {uniqueSocials.map(socialItem => {
        if (socialItem.type.toLowerCase() === "xing") {
          return (
            <Panel key={socialItem.username}>
              <a href={socialItem.username}>
                <img src="../images/icons/xing.png" alt="Xing" style={{ height: 30, width: 30 }} />
              </a>
            </Panel>
          );
        } else {
          return (
            <Panel key={socialItem.username}>
              <SocialIcon url={socialItem.username} style={{ height: 30, width: 30 }} />
            </Panel>
          );
        }
      })}
    </div>
  );
};

export default Socials;
