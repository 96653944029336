import api, { RequestWithData } from "@src/api/api";
import { JobType } from "@src/types/JobType";

type CreateData = JobType;

type CreateRequest = RequestWithData<CreateData>;

type CreateResponse = {
  data: JobType;
};

export default async function create(request: CreateRequest): Promise<CreateResponse> {
  const { data } = await api.post("/api/v1/jobs", request.data);
  return data;
}
