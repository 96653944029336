import { useTranslation } from "react-i18next";

import Button from "@src/components/elements/input/Button";

type Props = {
  showBackward: boolean;
  showForward: boolean;
  showSubmitButton: boolean;
  backward: () => void;
  forward: () => void;
  preview: () => void;
  submit: () => void;
};

const BottomJobCreateTab: React.FC<Props> = ({
  showBackward,
  showForward,
  showSubmitButton,
  backward,
  forward,
  preview,
  submit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mb-[env(safe-area-inset-bottom)] flex flex-row flex-wrap justify-end gap-2 sm:flex-nowrap">
      {showBackward && (
        <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} onClick={backward}>
          {t("main.groupsInfoForm.buttons.back")}
        </Button>
      )}
      {showSubmitButton && (
        <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} onClick={submit}>
          {t("main.groupsInfoForm.buttons.save")}
        </Button>
      )}
      {showSubmitButton && (
        <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} onClick={preview}>
          {t("main.groupsInfoForm.buttons.preview")}
        </Button>
      )}

      {showForward && (
        <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} onClick={forward}>
          {t("main.groupsInfoForm.buttons.further")}
        </Button>
      )}
    </div>
  );
};

export default BottomJobCreateTab;
