import BigTile from "@src/pages/assignments/fullpage/tiles/BigTile";
import HalfCircleTitle from "@src/pages/assignments/fullpage/tiles/HalfCircleTitle";
import ScrollListH from "@src/pages/assignments/fullpage/tiles/ScrollListH";
import SmallTile from "@src/pages/assignments/fullpage/tiles/SmallTile";
import SquareTiles from "@src/pages/assignments/fullpage/tiles/SquareTiles";
import TinyTile from "@src/pages/assignments/fullpage/tiles/TinyTile";

const SquareContent = () => {
  return (
    <SquareTiles>
      <TinyTile />
      <TinyTile />
      <TinyTile />
    </SquareTiles>
  );
};

const ScrollListContent = () => {
  return (
    <ScrollListH>
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
      <SmallTile />
    </ScrollListH>
  );
};

const SmallListContent = () => {
  return (
    <ScrollListH>
      <SmallTile />
      <SmallTile />
      <SmallTile />
    </ScrollListH>
  );
};

const FullPageContainer = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="">
        <BigTile />
      </div>

      <SmallListContent />

      <SmallListContent />

      <ScrollListH height="h-fit" showBackground={false}>
        <SquareContent />
        <SquareContent />
        <SquareContent />
        <SquareContent />
      </ScrollListH>

      <ScrollListContent />

      <ScrollListContent />

      <ScrollListH height="h-fit" showBackground={false}>
        <SquareContent />
        <SquareContent />
        <SquareContent />
        <SquareContent />
      </ScrollListH>

      <ScrollListH>
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
      </ScrollListH>
    </div>
  );
};

export default FullPageContainer;
