import api, { RequestWithParams, requestParams } from "@src/api/api";
import { ProfileViewsStatistics } from "@src/types/ProfileViewsStatisticsType";

type MyProfileViewsStatisticsParams = {
  page?: number;
  q?: string;
};

type MyProfileViewsStatisticsRequest = RequestWithParams<MyProfileViewsStatisticsParams>;

type MyProfileViewsStatisticsResponse = {
  data: ProfileViewsStatistics[];
  current_page: number;
  total_pages: number;
};

export default async function myProfileViewsStatistics(
  request: MyProfileViewsStatisticsRequest,
): Promise<MyProfileViewsStatisticsResponse> {
  const { data } = await api.get(
    `/api/v1/users/profile/visits/statistics${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
