import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import YoutubeFrame from "@src/components/elements/YoutubeFrame";
import BackButton from "@src/components/nav/BackButton";

type ArticleType = {
  title: string;
  text: ReactNode;
  youtubeLink: string;
};

const Copyright = () => {
  const { t } = useTranslation();
  const articles: Array<ArticleType> = [
    {
      title: t("pages.copyright.title1"),
      text: t("pages.copyright.text1"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/kXthSf2d5lw?controls=0",
    },
    {
      title: t("pages.copyright.title2"),
      text: t("pages.copyright.text2"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/AVjU2J0yBgE?controls=0",
    },
    {
      title: t("pages.copyright.title3"),
      text: "",
      youtubeLink: "https://www.youtube-nocookie.com/embed/Kn-alT2ifrI?controls=0",
    },
    {
      title: t("pages.copyright.title4"),
      text: "",
      youtubeLink: "https://www.youtube-nocookie.com/embed/BklLZ9J-cRo?controls=0",
    },
    {
      title: t("pages.copyright.title5"),
      text: t("pages.copyright.text5"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/Wpvo4ATs5M8?controls=0",
    },
    {
      title: t("pages.copyright.title6"),
      text: (
        <>
          {t("pages.copyright.text6.1")}
          <ul className="list-inside list-disc">
            <li>{t("pages.copyright.text6.2")}</li>
            <li>{t("pages.copyright.text6.3")}</li>
            <li>{t("pages.copyright.text6.4")}</li>
            <li>{t("pages.copyright.text6.5")}</li>
            <li>{t("pages.copyright.text6.6")}</li>
          </ul>
        </>
      ),
      youtubeLink: "https://www.youtube-nocookie.com/embed/XXXXXX?controls=0",
    },
    {
      title: t("pages.copyright.title7"),
      text: t("pages.copyright.text7"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/Tg13mwAJiK0?controls=0",
    },
    {
      title: t("pages.copyright.title8"),
      text: t("pages.copyright.text8"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/qdOtmuzvxm8?controls=0",
    },
    {
      title: t("pages.copyright.title9"),
      text: t("pages.copyright.text9"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/CtJn9g-b4_U?controls=0",
    },
    {
      title: t("pages.copyright.title10"),
      text: "",
      youtubeLink: "https://www.youtube-nocookie.com/embed/Mf82_qC6OHw?controls=0",
    },
    {
      title: t("pages.copyright.title11"),
      text: t("pages.copyright.text11"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/Udwj1MKj6sY?controls=0",
    },
    {
      title: t("pages.copyright.title12"),
      text: t("pages.copyright.text12"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/zdxSTohPG-4?controls=0",
    },
    {
      title: t("pages.copyright.title13"),
      text: t("pages.copyright.text13"),
      youtubeLink: "https://www.youtube-nocookie.com/embed/AN9ZsdkZnO0?controls=0",
    },
  ];

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/marken_patent_rechte_tradefoox_2.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit whitespace-nowrap bg-white/80 px-1 text-2xl text-darkcyan">
              {t("pages.copyright.text14")}
            </div>
            <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
              {t("pages.copyright.text16")}
            </div>
          </div>
        </section>

        <article className="text-justify text-xs max-xl:px-4">
          <div className="font-semibold">{t("pages.copyright.text17")}</div>
          <div className="grid grid-flow-row gap-1">{t("pages.copyright.text17")}</div>
        </article>
        {articles.map((article: ArticleType) => {
          return (
            <article className="flex flex-col gap-1" key={article.youtubeLink}>
              <h2 className="text-xs font-semibold max-xl:px-4">{article.title}</h2>
              <YoutubeFrame key={article.youtubeLink} src={article.youtubeLink} />
              <div className="text-justify text-xs max-xl:px-4">{article.text}</div>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default Copyright;
