import api, { RequestWithData } from "@src/api/api";

type EmailData = {
  old_email: string;
  new_email: string;
};

type EmailChangeRequest = RequestWithData<EmailData>;

export default async function updateEmail(request: EmailChangeRequest): Promise<void> {
  await api.post("api/v1/users/account/email/change_request", request.data);
}
