import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import follow from "@src/api/users/follow";
import unfollow from "@src/api/users/unfollow";
import Button from "@src/components/elements/input/Button";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";

type Props = {
  followerId: string;
  isFollowed: boolean | undefined;
  onFollowStatusChange: (followed: boolean) => void;
};

const ContactsFollowButton = ({ followerId, isFollowed, onFollowStatusChange }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  const followMutation = useMutation({
    mutationKey: [`follow-${followerId}`],
    mutationFn: follow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      onFollowStatusChange(true);
    },
    onError: () => {
      alert("Follow failed, please try again.");
    },
  });

  const unfollowMutation = useMutation({
    mutationKey: [`unfollow-${followerId}`],
    mutationFn: unfollow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      onFollowStatusChange(false);
    },
    onError: () => {
      alert("Unfollow failed, please try again.");
    },
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <>
      {isFollowed ? (
        <Button
          onClick={() => checkAuth() && unfollowMutation.mutate({ id: followerId })}
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
        >
          <img src="/follow.svg" className="mx-auto h-5 w-4" />
        </Button>
      ) : (
        <Button
          onClick={() => checkAuth() && followMutation.mutate({ id: followerId })}
          size={Button.Sizes.Small}
          variant={Button.Variants.Red}
        >
          {t("main.contacts.tabs.buttonsConsequences")}
        </Button>
      )}
    </>
  );
};

export default ContactsFollowButton;
