import api from "@src/api/api";
import { UserType } from "@src/types/UserType";

type SuggestionsResponse = {
  data: UserType[];
};

export default async function suggestions(): Promise<SuggestionsResponse> {
  const { data } = await api.get("/api/v1/users/suggestions");
  return { data };
}
