import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

import Input from "@src/components/elements/input/Input";

type dateRange = {
  start: string;
  end: string;
  description: string;
};

type Props = {
  label?: string;
  values?: Array<dateRange>;
  name: string;
  onChange: (array: Array<dateRange>) => void;
  addText?: string;
};

const ProfileEditCVList = ({
  label,
  onChange,
  values = [],
  addText = "Neuen Link hinzufügen",
}: Props) => {
  /**
   * Remove item
   * @param index
   */
  const removeItem = (index: number) => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues.splice(index, 1);
    onChange(newValues);
  };

  /**
   * Adds item
   * @param item
   */
  const addItem = (item: dateRange) => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues.push(item);
    onChange(newValues);
  };

  /**
   * Changes the start date
   * @param start
   * @param index
   */
  const changeStart = (start: string, index: number) => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues[index].start = new Date(start).toISOString();
    onChange(newValues);
  };

  /**
   * Changes the end date
   * @param end
   * @param index
   */
  const changeEnd = (end: string, index: number) => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues[index].end = new Date(end).toISOString();
    onChange(newValues);
  };

  /**
   * Changes the description
   * @param description
   * @param index
   */
  const changeDescription = (description: string, index: number) => {
    const newValues = JSON.parse(JSON.stringify(values));
    newValues[index].description = description;
    onChange(newValues);
  };

  return (
    <div className="mt-1">
      {label && <label className="mb-1 block text-sm font-medium text-gray-700">{label}</label>}
      <div className="flex flex-col rounded-2xl border border-gray-300 py-2 pl-2 pr-4 shadow-sm sm:text-sm">
        {values.map((item: dateRange, index: number) => {
          return (
            <div key={index} className="grid grid-cols-[140px,140px,auto,20px] items-center gap-2">
              <Input
                name=""
                value={new Date(item.start).toISOString().substring(0, 10)}
                onChange={e => changeStart(e, index)}
                type="date"
              />
              <Input
                name=""
                value={new Date(item.end).toISOString().substring(0, 10)}
                onChange={e => changeEnd(e, index)}
                type="date"
              />
              <Input name="" value={item.description} onChange={e => changeDescription(e, index)} />
              <button
                onClick={() => {
                  removeItem(index);
                }}
              >
                <MinusCircleIcon className="size-6 text-red-600" />
              </button>
            </div>
          );
        })}
        <div className="relative mt-3 flex flex-row items-center justify-center">
          <div className="col-span-3 text-center">{addText}</div>
          <button
            onClick={() => {
              addItem({
                start: new Date().toISOString(),
                end: new Date().toISOString(),
                description: "",
              });
            }}
          >
            <PlusCircleIcon className="absolute -right-1 top-0 size-6 text-green-600" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditCVList;
