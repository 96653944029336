import { DefinedInfiniteQueryObserverResult, InfiniteData } from "@tanstack/react-query";

import { PostsResponse } from "@src/api/hashtags/posts";

const prevPost = ({
  index,
  pageIndex,
  postsResult,
}: {
  index: number;
  pageIndex: number;
  postsResult: DefinedInfiniteQueryObserverResult<InfiniteData<PostsResponse>>;
}) => {
  if (index == 0 && pageIndex > 0)
    return postsResult.data.pages[pageIndex - 1]?.data[
      postsResult.data.pages[pageIndex - 1]?.data.length - 1
    ].id;
  if (index > 0) return postsResult.data.pages[pageIndex]?.data[index - 1]?.id;
  return "";
};

export default prevPost;
