import { t } from "i18next";

const topSellingProductsData = [
  { name: "Product A", salesVolume: 300 },
  { name: "Product B", salesVolume: 250 },
  { name: "Product C", salesVolume: 200 },
  { name: "Product D", salesVolume: 180 },
  { name: "Product E", salesVolume: 150 },
];

export const salesTrendData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      label: t("main.shop.details.salesTrend") + "(€)",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "#4dc9f6",
      borderColor: "#4dc9f6",
      pointBorderColor: "#4dc9f6",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#4dc9f6",
      pointHoverBorderColor: "#4dc9f6",
      pointHoverBorderWidth: 2,
      data: [5000, 7000, 5500, 9000, 6000, 8000, 7500],
    },
  ],
};

export const revenueGrowthData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: t("main.shop.details.revenueGrowth") + "(€)",
      data: [5000, 6000, 5500, 7000, 6500, 8000],
      backgroundColor: "#38c172",
      borderWidth: 1,
    },
  ],
};

export const inventoryLevelData = {
  labels: ["Category A", "Category B", "Category C", "Category D"],
  datasets: [
    {
      label: t("main.shop.details.inventoryLevel"),
      data: [200, 150, 300, 250],
      backgroundColor: ["#3490dc", "#38c172", "#e3342f", "#6cb2eb"],
      borderWidth: 1,
    },
  ],
};

export const categoryData = {
  labels: ["Electronics", "Clothing", "Home & Kitchen", "Books"],
  datasets: [
    {
      label: t("main.shop.details.productCategories"),
      data: [25, 30, 20, 25],
      backgroundColor: ["#3490dc", "#38c172", "#e3342f", "#6cb2eb"],
    },
  ],
};

export const inventoryLevelOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export const salesChannelOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export const revenueGrowthOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export const topSellingOptions = {
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        padding: 10,
      },
    },
  },
};

export const customerDemographicsOptions = {
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
    },
    x: {
      stacked: true,
    },
  },
};

export const revenueDistributionData = {
  labels: [
    t("main.shop.details.directSales"),
    t("main.shop.details.onlineMarketplace"),
    t("main.shop.details.holesale"),
  ],
  datasets: [
    {
      data: [30000, 45000, 20000],
      backgroundColor: ["#38c172", "#3490dc", "#e3342f"],
      hoverBackgroundColor: ["#4fd888", "#55c9ff", "#ef4444"],
    },
  ],
};

export const orderStatusData = {
  labels: [
    t("main.shop.details.pending"),
    t("main.shop.details.shipped"),
    t("main.shop.details.delivered"),
    t("main.shop.details.cancelled"),
  ],
  datasets: [
    {
      data: [5, 20, 50, 3],
      backgroundColor: ["#fcc419", "#38c172", "#3490dc", "#e3342f"],
      hoverBackgroundColor: ["#f6e05e", "#4fd888", "#55c9ff", "#ef4444"],
    },
  ],
};

export const customerDemographicsData = {
  labels: ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
  datasets: [
    {
      label: t("main.shop.details.ageGroups"),
      data: [350, 450, 300, 200, 150, 100],
      backgroundColor: "#3490dc",
      borderWidth: 1,
    },
    {
      label: t("main.shop.details.genderDistribution"),
      data: [600, 400],
      backgroundColor: "#38c172",
      borderWidth: 1,
    },
    {
      label: t("main.shop.details.geographicLocation"),
      data: [250, 150, 200, 300],
      backgroundColor: "#e3342f",
      borderWidth: 1,
    },
  ],
};

export const salesChannelData = {
  labels: ["Website Sales", "Mobile App Sales", "Marketplace Sales"],
  datasets: [
    {
      label: t("main.shop.details.salesChannels"),
      data: [40000, 30000, 25000],
      backgroundColor: ["#3490dc", "#38c172", "#e3342f"],
      borderWidth: 1,
    },
  ],
};

export const topSellingData = {
  labels: topSellingProductsData.map(product => product.name),
  datasets: [
    {
      label: t("main.shop.details.salesVolume"),
      data: topSellingProductsData.map(product => product.salesVolume),
      backgroundColor: "#3490dc",
      borderWidth: 1,
    },
  ],
};
