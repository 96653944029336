import { t } from "i18next";

import Products from "@src/mocks/AllProducts.json";
import Orders from "@src/pages/marketplace/components/Orders";
import Product from "@src/pages/marketplace/components/Product";
import MarketplaceCarousels from "@src/pages/marketplace/shop/MarketplaceCarousels";

const OrdersContainer = () => {
  return (
    <div className="mb-5">
      <Orders />
      <div className="mb-5 mt-3 xl:block">
        <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
        <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
          {Products.allProducts.map(product => (
            <div key={product.ID} className="flex justify-center">
              <Product product={product} />
            </div>
          ))}
        </div>
        <MarketplaceCarousels />
      </div>
    </div>
  );
};

export default OrdersContainer;
