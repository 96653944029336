import feed from "@src/api/feed/feed";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ProfileFeedList from "@src/pages/profile/details/ProfileFeedList";
import useDebounce from "@src/utils/useDebounce";

type Props = {
  userId: string;
  searchQuery: string;
};

const PostLikes = (props: Props) => {
  const { userId } = props;
  const debouncedSearchQuery = useDebounce(props.searchQuery, 500);
  const queryParams = {
    q: debouncedSearchQuery || null,
    user_id: null,
    liked_by: userId || null,
  };

  const { result: feedResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", "feed", queryParams],
    queryFn: ({ pageParam }) => feed({ params: { ...queryParams, page: pageParam } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-5">
      <ProfileFeedList placeholder="/images/default_header_profile.png" feedResult={feedResult} />
    </div>
  );
};

export default PostLikes;
