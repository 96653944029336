import {
  BuildingOffice2Icon,
  CreditCardIcon,
  DocumentTextIcon,
  QueueListIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";

import { UserType } from "@src/types/UserType";

type Props = {
  user: UserType;
};

const CompanyAndClubProfileContainer = ({ user }: Props) => {
  const { t } = useTranslation();
  const userType = user.type;

  const InfoField: React.FC<{
    key: string;
    icon: React.ReactNode;
    label: string;
    value?: string | number;
  }> = ({ icon, label, value, key }) => {
    if (!value) {
      return null;
    }

    return (
      <div className="grid grid-cols-[20px,1fr] items-center" key={key}>
        {typeof icon === "string" ? (
          <img src={icon} alt={`${label} icon`} className="size-[15px]" />
        ) : (
          <div className="size-[15px]">{icon}</div>
        )}
        <div className="font-semibold">{label}:</div>
        <div />
        <div>{value}</div>
      </div>
    );
  };

  const fields = [
    {
      icon: <BuildingOffice2Icon className="size-4" />,
      label:
        userType === "club"
          ? t("main.profileViewTabs.editProfile.form.clubname")
          : t("main.profileViewTabs.editProfile.form.companyName"),
      value: userType === "club" ? user.club?.name : user.company?.name,
    },
    {
      icon: "/images/icons/legal-form.svg",
      label: t("main.profileViewTabs.editProfile.form.legalForm"),
      value: userType === "club" ? user.club?.legal_type : user.company?.legal_type,
    },
    {
      icon: <CreditCardIcon className="size-4" />,
      label: t("main.profileViewTabs.editProfile.form.clubID"),
      value:
        userType === "club"
          ? user.club?.club_register_nr
          : user.company?.company_registration_number,
    },
    {
      icon: "/images/icons/vat-id.svg",
      label: t("main.profileViewTabs.editProfile.form.vatID"),
      value: userType === "club" ? user.club?.tax_nr : user.company?.tax_id,
    },
    {
      icon: "/images/icons/annual-turnover.svg",
      label: t("main.profileViewTabs.editProfile.form.annualsales"),
      value: userType === "club" ? user.club?.yearly_revenue : user.company?.yearly_revenue,
    },
    {
      icon: <UsersIcon className="size-4" />,
      label: t("components.shared.amountEmployees"),
      value: userType === "club" ? user.club?.members : user.company?.employees,
    },
    {
      icon: <QueueListIcon className="size-4" />,
      label: t("main.profileViewTabs.editProfile.form.topics"),
      value: userType === "club" ? user.club?.specialization : user.company?.industry,
    },
    {
      icon: "/images/icons/keywords.svg",
      label: t("main.groupsInfoForm.keywords"),
      value: userType === "club" ? user.club?.keywords : user.company?.keywords,
    },
    {
      icon: "/images/icons/paragraph.svg",
      label: t("main.profileViewTabs.editProfile.form.moreInformation.imprint"),
      value: userType === "club" ? user.club?.legal_notice : user.company?.imprint,
    },
    {
      icon: <DocumentTextIcon className="size-4" />,
      label: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
      value: userType === "club" ? user.club?.terms : user.company?.terms,
    },
  ];
  const clubMember = {
    icon: <UsersIcon className="size-4" />,
    label: t("components.shared.amountMembers"),
    value: user.club?.members,
  };
  if (userType === "club") {
    fields.splice(5 + 1, 0, clubMember);
  }

  return (
    <div className="mb-5 w-full lg:w-auto xl:rounded-xl xl:border xl:border-gray-300 xl:bg-gray-100 xl:p-4">
      {fields.map(field => (
        <InfoField key={field.label} icon={field.icon} label={field.label} value={field.value} />
      ))}
    </div>
  );
};

export default CompanyAndClubProfileContainer;
