import api, { RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type MentionsParams = {
  ids: string;
};

type MentionsRequest = RequestWithParams<MentionsParams>;

type MentionsResponse = {
  data: UserType[];
};

export default async function mentions(request: MentionsRequest): Promise<MentionsResponse> {
  const { data } = await api.get(`/api/v1/users/mentions${requestParams(request.params)}`);
  return data;
}
