import available from "@src/api/users/username/available";

const checkUsername = async (username: string) => {
  try {
    const data = await available({ params: { username: encodeURIComponent(username) } });
    return data.available;
  } catch (error) {
    console.error("Failed to check username availability", error);
    throw error;
  }
};

export default checkUsername;
