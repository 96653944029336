import api, { RequestWithId } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type UserRequest = RequestWithId;

type UserResponse = {
  data: UserType;
};

export default async function user(request: UserRequest): Promise<UserResponse> {
  const { data } = await api.get(`/api/v1/users/${request.id}`);
  return data;
}
