import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import jobs from "@src/api/jobs/jobs";
import Panel from "@src/components/box/Panel";
import SuggestionsCenterItem from "@src/components/elements/sidebar/suggestions/SuggestionsCenterItem";
import { JobType } from "@src/types/JobType";

type Props = { id?: string; key?: string };

const JobsSuggestions = ({ id }: Props) => {
  const { t } = useTranslation();

  const jobsResult = useQuery({
    queryKey: ["jobs"],
    queryFn: () => jobs({ params: {} }),
  });

  if (!jobsResult.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel title={t("components.panels.popularJobs")} mobileBorder={false} key={id}>
        <div className="no-scrollbar flex gap-4 overflow-x-auto">
          {jobsResult.data.data.map((item: JobType) => {
            return (
              <SuggestionsCenterItem
                key={item.id}
                name={item.title}
                link={"/jobs/" + item.id}
                picture={item.user.avatar_url ?? `/images/placeholder/${item.user.type}.png`}
                imageStyle="rounded-xl w-[100px] h-[150px] object-cover"
                nameStyle="w-[100px]"
              />
            );
          })}
        </div>
      </Panel>
    </div>
  );
};
export default JobsSuggestions;
