import { t } from "i18next";
import React, { useEffect, useState } from "react";

type UploadedFile = {
  type: string;
  url: string;
};

type Props = {
  limit?: number;
  onChange?: (files: UploadedFile[]) => void;
};

const MultipleImageUpload: React.FC<Props> = ({ limit = 4, onChange }) => {
  const [files, setFiles] = useState<UploadedFile[]>([]);

  useEffect(() => {
    if (onChange) {
      onChange(files);
    }
  }, [files, onChange]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const remainingSlots = limit - files.length;
      if (selectedFiles.length > remainingSlots) {
        alert(t("main.shop.details.validationError", { count: remainingSlots }));
      } else {
        processFiles(Array.from(selectedFiles));
      }
    }
  };

  const processFiles = (files: File[]) => {
    const fileArray = files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<UploadedFile>(resolve => {
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            resolve({ type: file.type.split("/")[0], url: reader.result });
          }
        };
      });
    });

    Promise.all(fileArray).then(newFiles => {
      setFiles(prevFiles => [...prevFiles, ...newFiles]);
    });
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles) {
      const remainingSlots = limit - files.length;
      if (droppedFiles.length > remainingSlots) {
        alert(t("main.shop.details.validationError", { count: remainingSlots }));
      } else {
        processFiles(Array.from(droppedFiles));
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDeleteFile = (index: number) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div>
      <div
        aria-hidden
        className="flex flex-col items-start rounded-lg bg-white pt-4"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="grid grid-cols-3 gap-4 md:grid-cols-4">
          {files.map((file, index) => (
            <div key={file.url} className="relative">
              {file.type === "image" ? (
                <img
                  src={file.url}
                  alt={t("main.shop.details.uploadPreview") + `${index + 1}`}
                  className="size-32 rounded-lg border border-gray-300"
                />
              ) : (
                <video
                  controls
                  src={file.url}
                  className="size-32 rounded-lg border border-gray-300"
                />
              )}
              <button
                onClick={() => handleDeleteFile(index)}
                className="absolute right-2 top-2 flex size-6 items-center justify-center rounded-full bg-white/50 p-1 text-black"
              >
                X
              </button>
            </div>
          ))}
          <div className="flex w-full items-center justify-center">
            <div className="flex size-32 cursor-pointer flex-col justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100">
              <div className="flex flex-col items-center justify-center p-2">
                <svg
                  className="size-7 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-1 text-mini text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">{t("main.shop.details.clickToUpload")} </span>
                  {t("main.shop.details.dragAndDrop")}
                </p>
                <p className="text-mini text-gray-500 dark:text-gray-400">
                  {t("main.shop.details.fileType")}
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                accept="image/*,video/*"
                multiple
                onChange={handleFileChange}
                className="hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleImageUpload;
