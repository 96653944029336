import { useTranslation } from "react-i18next";

import Products from "@src/mocks/AllProducts.json";
import OrderedProduct from "@src/pages/marketplace/components/OrderedProduct";

const Orders = () => {
  const { t } = useTranslation();
  const products = Products.allProducts;

  return (
    <div className="flex flex-col rounded-lg border border-gray-300">
      <div className="rounded-t-lg bg-gray-100">
        <div className="my-2 flex flex-row gap-x-8 whitespace-nowrap p-2 text-xs font-semibold text-gray-500">
          <div className="flex flex-col">
            <div>{t("pages.paymentAndOrder.createOrder")}</div>
          </div>

          <div className="flex flex-col">
            <div>{t("main.shop.price.total")}</div>
          </div>

          <div className="flex w-full flex-col overflow-hidden text-ellipsis text-right text-gray-500">
            <div className="flex justify-end">{t("pages.paymentAndOrder.billnumber")}</div>
            <div className="flex flex-row items-center justify-end">
              <div className="border-r border-gray-500 pr-2">
                <a className="text-cyan-600" href="#">
                  {t("pages.paymentAndOrder.orderDetails")}
                </a>
              </div>
              <select className="border-0 bg-transparent py-0 text-xs text-cyan-600">
                <option>{t("pages.paymentAndOrder.bill")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {products.slice(0, 5).map(product => (
        <>
          <hr className="h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
          <div key={product.ID} className="p-4 md:p-3">
            <OrderedProduct product={product} />
          </div>
        </>
      ))}
    </div>
  );
};

export default Orders;
