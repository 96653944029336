import { HeartIcon, NewspaperIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import {
  HeartIcon as HeartIconSolid,
  NewspaperIcon as NewspaperIconSolid,
  VideoCameraIcon as VideoCameraIconSolid,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import ButtonPanel from "@src/components/box/ButtonPanel";
import Input from "@src/components/elements/input/Input";
import TabFilter from "@src/components/menu/TabFilter";
import PostLikes from "@src/pages/profile/PostLikes";
import UserLikes from "@src/pages/profile/UserLikes";
import WatchLikes from "@src/pages/profile/WatchLikes";

type Props = {
  type: string;
  userId: string;
  onClose: () => void;
  setTab?: (
    e:
      | "likes"
      | "feed"
      | "watch"
      | "following"
      | "follows"
      | "feed_likes"
      | "watch_likes"
      | undefined,
  ) => void;
};

const UserLikeList = (props: Props) => {
  const { userId } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const headlines: { [index: string]: string } = {
    likes: "Likes",
    feed_likes: "Likes",
    watch_likes: "Likes",
  };

  const hashtagTabs = [
    {
      name: t("components.shared.myLikes"),
      key: "likes",
      icon: <HeartIcon className="size-4" />,
      selectIcon: <HeartIconSolid className="size-4" />,
    },
    {
      name: t("main.profileViewTabs.watch.title"),
      key: "watch_likes",
      icon: <VideoCameraIcon className="size-4" />,
      selectIcon: <VideoCameraIconSolid className="size-4" />,
    },
    {
      name: t("main.profileViewTabs.feed.title"),
      key: "feed_likes",
      icon: <NewspaperIcon className="size-4" />,
      selectIcon: <NewspaperIconSolid className="size-4" />,
    },
  ];

  return (
    <ButtonPanel onClose={props.onClose} title={headlines[props.type]}>
      <Input
        name="search-contacts"
        placeholder={t("main.feedTabs.placeholder")}
        onChange={e => setSearchQuery(e)}
        clearable
      />
      <div className="mt-2">
        <div className="h-7">
          <TabFilter
            value={props.type}
            options={hashtagTabs}
            onChange={value => {
              navigate("/profile/" + userId + "/" + value, {
                state: {
                  backlink: location.state?.backlink ?? location.pathname,
                },
              });
            }}
            centered
          />
        </div>
        {props.type === "likes" && (
          <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-5">
            <UserLikes userId={userId} searchQuery={searchQuery} />
          </div>
        )}
        {props.type === "feed_likes" && <PostLikes userId={userId} searchQuery={searchQuery} />}
        {props.type === "watch_likes" && <WatchLikes userId={userId} searchQuery={searchQuery} />}
      </div>
    </ButtonPanel>
  );
};

export default UserLikeList;
