import { useTranslation } from "react-i18next";

import ContactMyVisitorsContainer from "@src/pages/contacts/my_visitors/ContactMyVisitorsContainer";

const ProfileVisitors = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="my-5">{t("main.contacts.statistics.profileVisitor")}</h2>
      <ContactMyVisitorsContainer />
    </div>
  );
};

export default ProfileVisitors;
