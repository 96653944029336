import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import BackButton from "@src/components/nav/BackButton";

const Support = () => {
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <form className="grid grid-flow-row gap-2 text-justify text-xs">
        <img
          src="/images/info/projekt-auftragsvergabe-header-5.jpg"
          className="h-[300px] w-full object-cover"
        />
        <div className="grid grid-flow-row gap-2 pb-4 max-xl:px-4">
          <div className="mb-2">{t("pages.support.text1")}</div>
          <h1 className="text-left text-xs font-semibold">{t("pages.successor.text5")}</h1>

          <Input
            label={t("pages.createAccount.label1")}
            type="text"
            name="company"
            placeholder={t("pages.createAccount.placeholder1")}
            value={company}
            onChange={e => {
              setCompany(e);
            }}
            required
          />
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <Input
              label={t("main.profileViewTabs.editProfile.form.firstName")}
              type="text"
              name="surname"
              placeholder="Max"
              value={surname}
              onChange={e => {
                setSurname(e);
              }}
              required
            />
            <Input
              label={t("main.profileViewTabs.editProfile.form.lastName")}
              type="text"
              name="name"
              placeholder="Mustermann"
              value={lastname}
              onChange={e => {
                setLastname(e);
              }}
              required
            />
            <Input
              label="E-Mail"
              type="text"
              name="email"
              placeholder="E-Mail"
              value={email}
              onChange={e => {
                setEmail(e);
              }}
              required
            />
            <Input
              label={t("main.profileViewTabs.editProfile.form.phone")}
              type="text"
              name="phone"
              placeholder="+49 1234 123456"
              value={phone}
              onChange={e => {
                setPhone(e);
              }}
              required
            />
          </div>
          <div>
            {t("pages.createAccount.text16")}
            <TextareaAutosize
              minRows={3}
              value={message}
              onChange={e => {
                setMessage(e.target.value);
              }}
              className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
              required
            />
          </div>

          <Checkbox name="agb" required checked={privacyCheck} onChange={e => setPrivacyCheck(e)}>
            {t("pages.createAccount.text17")}{" "}
            <NavLink to="/datenschutz" target="_blank" className="text-darkcyan">
              {t("pages.createAccount.text18")}
            </NavLink>{" "}
            {t("pages.createAccount.text19")}
          </Checkbox>
          <Checkbox
            name="newsletter"
            checked={newsletterCheck}
            onChange={e => setNewsletterCheck(e)}
          >
            {t("components.shared.acceptNewsletter")}
          </Checkbox>
          <Button submit>{t("buttons.send")}</Button>
        </div>
      </form>
    </>
  );
};

export default Support;
