import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import {
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  PlayIcon as PlayIconFull,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import WatchDropdown from "@src/pages/watch/WatchDropdown";
import WatchBookmarkButton from "@src/pages/watch/actions/WatchBookmarkButton";
import { WatchType } from "@src/types/WatchType";

type Props = {
  isMobile?: boolean;
  data: WatchType;
  checkAuth: () => boolean;
  likeMutation: UseMutationResult<void, AxiosError<unknown>, void, unknown>;
  setLikeEffect: Dispatch<SetStateAction<boolean>>;
  likeEffect: boolean;
  setCommentsPopupOpen: Dispatch<SetStateAction<boolean>>;
  commentsPopupOpen: boolean;
  setSharePopupOpen: Dispatch<SetStateAction<boolean>>;
  setSharePopupMobile: Dispatch<SetStateAction<boolean>>;
  queryKey?: (string | null)[];
};

const WatchSidebar = ({
  isMobile = false,
  data,
  checkAuth,
  likeMutation,
  setLikeEffect,
  likeEffect,
  setCommentsPopupOpen,
  commentsPopupOpen,
  setSharePopupOpen,
  setSharePopupMobile,
  queryKey,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className={`absolute w-[50px] rounded-xl text-xs text-gray-200 ${isMobile ? "bottom-2 right-3 z-10 py-1" : "pointer-events-none bottom-[40px] right-[10px] z-20 md:bottom-[30px]"}`}
    >
      <div className="flex flex-col items-center gap-3">
        <button
          className="pointer-events-auto flex flex-col items-center"
          onClick={() => checkAuth() && likeMutation.mutate()}
        >
          {data.liked && (
            <HeartIcon className={`size-8 text-red-600 ${likeEffect && "animate-ping-once"}`} />
          )}
          {!data.liked && (
            <HeartIcon
              className="size-8"
              onClick={() => {
                setLikeEffect(true);
              }}
              onAnimationEnd={() => {
                setLikeEffect(false);
              }}
            />
          )}
          <div>{data.like_count}</div>
        </button>
        <button className="pointer-events-auto flex flex-col items-center">
          <ChatBubbleOvalLeftIcon
            className="size-8"
            onClick={() => {
              setCommentsPopupOpen(!commentsPopupOpen);
            }}
          />
          <div>{data.comment_count}</div>
        </button>
        <button className="pointer-events-auto flex flex-col items-center">
          <WatchBookmarkButton postId={data.post_id} data={data} queryKey={queryKey} />
          <div>{data.bookmark_count}</div>
        </button>
        <button
          className="pointer-events-auto flex flex-col items-center"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <PlayIconFull className="size-8" />
          <div>{data.view_count}</div>
        </button>
        <button
          className="pointer-events-auto relative flex cursor-pointer flex-col items-center"
          onClick={e => {
            e.stopPropagation();
            if (checkAuth()) setSharePopupOpen(true);
          }}
        >
          <ArrowPathRoundedSquareIcon className="size-8" />
          <div>0</div>
          <button
            className="absolute left-0 top-0 size-full lg:hidden"
            onClick={() => {
              setSharePopupMobile(true);
            }}
          />
        </button>
        <button className="pointer-events-auto">
          <WatchDropdown
            isRepost={data.is_reposted}
            postId={data.post_id}
            videoUrl={data.media_url}
            authorId={data.author_id}
            bookmarked={data.bookmarked}
          />
        </button>
        <button
          className={`pointer-events-auto mr-12 flex items-center gap-2.5 rounded-xl border bg-gray-200 px-2.5 py-1 text-sm text-black ${isMobile ? "mt-24" : "mt-10"}`}
          onClick={() => checkAuth() && navigate("/watch/erstellen")}
        >
          <PlusIcon className="size-4 text-black" />
          {t("main.watchTabs.create.buttons.createButton")}
        </button>
      </div>
    </div>
  );
};

export default WatchSidebar;
