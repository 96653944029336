// This whole File is only for a workaround till s3 CORS is fixed. Can be removed after it is fixed
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ImageEditorState {
  imageEditor: string | File;
}

const initialState: ImageEditorState = {
  imageEditor: "",
};

const imageEditorSlice = createSlice({
  name: "imageEditor",
  initialState,
  reducers: {
    setImageEditor: (state, { payload }: PayloadAction<string | File>) => {
      state.imageEditor = payload;
    },
  },
});

export const { setImageEditor } = imageEditorSlice.actions;

export default imageEditorSlice.reducer;
