import {
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";

export type ReplacementType = {
  regex: RegExp;
  newString: string;
};

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  replacement?: Array<ReplacementType>;
  onChange?: (newValue: string) => void;
  type?: React.HTMLInputTypeAttribute;
  value?: string | number;
  disabled?: boolean;
  tooltip?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  title?: string;
  defaultValue?: string | number;
  clearable?: boolean;
  valid?: boolean;
  autoComplete?: string;
};

const Input = ({
  label,
  onChange,
  name,
  placeholder,
  type,
  value,
  disabled = false,
  tooltip,
  pattern,
  minLength,
  maxLength,
  required,
  title,
  defaultValue,
  clearable,
  autoComplete,
  valid = undefined,
  replacement,
}: Props) => {
  const [internalState, setInternalState] = useState(value ?? "");
  const [internalType, setInternalType] = useState(type ?? "");

  return (
    <div className="relative mt-3">
      {label && (
        <label
          htmlFor={name}
          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      {maxLength && (
        <label
          htmlFor={name}
          className="absolute -top-2 right-3 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
          {maxLength - (value?.toString()?.length ?? 0)}
        </label>
      )}
      <input
        disabled={disabled}
        name={name}
        id={name}
        type={internalType}
        autoComplete={autoComplete}
        onChange={e => {
          let newValue = e.target.value;
          if (replacement) {
            replacement.forEach(regexItem => {
              newValue = newValue.replaceAll(regexItem.regex, regexItem.newString);
            });
            const cursor = e.target.selectionStart;
            setTimeout(() => {
              e.target.setSelectionRange(cursor, cursor);
            }, 10);
          }
          setInternalState(newValue);
          if (onChange) onChange(newValue);
        }}
        value={internalState}
        className={`peer block w-full rounded-2xl border border-gray-300 px-3 py-2 shadow-sm outline-darkblue placeholder:text-gray-400 focus:border-darkblue focus:ring-darkblue invalid:focus:ring-tf-red-600 sm:text-sm ${!!(clearable || type === "password" || valid !== undefined) && "pr-8"}`}
        placeholder={placeholder}
        pattern={valid === false ? "^(?!x)x$" : pattern}
        minLength={minLength}
        maxLength={maxLength}
        required={valid ? required : true}
        title={title}
        defaultValue={defaultValue}
      />

      {!!((value || internalState) && clearable) && (
        <button
          onClick={() => {
            setInternalState("");
            if (onChange) onChange("");
          }}
          className="absolute right-0 top-0 size-5 -translate-x-1/2 translate-y-1/2"
        >
          <XMarkIcon />
        </button>
      )}
      {type === "password" && (
        <button
          onClick={e => {
            e.preventDefault();
            if (internalType === "password") {
              setInternalType("text");
            } else {
              setInternalType("password");
            }
          }}
          className="absolute right-0 top-0 size-5 -translate-x-1/2 translate-y-1/2"
        >
          {internalType === "password" ? <EyeSlashIcon /> : <EyeIcon />}
        </button>
      )}
      {valid !== undefined && (
        <>
          {valid ? (
            <CheckCircleIcon className="absolute right-0 top-0 size-5 -translate-x-1/2 translate-y-1/2 text-green-500" />
          ) : (
            <XCircleIcon className="absolute right-0 top-0 size-5 -translate-x-1/2 translate-y-1/2 text-red-500" />
          )}
        </>
      )}
      {tooltip && (
        <div className="absolute z-10 -mt-1 ml-2 hidden rounded-md border border-gray-500 bg-gray-50 px-2 py-1 text-left text-xs leading-3 peer-focus:block">
          {tooltip}
        </div>
      )}
    </div>
  );
};

export default Input;
