import React from "react";

import StarsRating from "@src/components/elements/input/StarsRating";

interface Review {
  user_image: string;
  user: string;
  rating: number;
  title: string;
  body: string;
}

interface ReviewProps {
  review: Review;
}

const Review: React.FC<ReviewProps> = ({ review }) => {
  return (
    <li className="py-3 sm:py-4">
      <div className="flex items-center">
        <div className="shrink-0">
          <img className="size-8 rounded-full" src={review.user_image} alt={review.user} />
        </div>
        <div className="ms-1 min-w-0 flex-1">
          <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
            {review.user}
          </p>
        </div>
      </div>
      <div className="flex flex-row">
        <p className="truncate text-sm text-yellow-500 dark:text-gray-400">
          <StarsRating rating={review.rating} />
        </p>
        <p className="ml-1 text-sm font-semibold"> {review.title}</p>
      </div>

      <p className="mt-2 text-sm text-gray-600">{review.body}</p>
    </li>
  );
};
export default Review;
