import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";

import job from "@src/api/jobs/job";
import jobs from "@src/api/jobs/jobs";
import ScoreTable from "@src/components/elements/shop/ScoreTable";
import JobCard from "@src/pages/jobs//components/JobCard";
import JobCarousel from "@src/pages/jobs//components/JobCarousel";
import JobReviews from "@src/pages/jobs/components/JobReviews";
import { JobType } from "@src/types/JobType";

type Props = {
  preloadedJob?: JobType;
};

const JobReviewsContainer = ({ preloadedJob }: Props) => {
  const navigate = useNavigate();
  const isPreview = preloadedJob !== undefined;
  const { id } = useParams();

  const jobsResult = useQuery({
    queryKey: ["jobs"],
    queryFn: () => jobs({ params: {} }),
  });

  const jobResult = useQuery({
    queryKey: [`job-${id}`],
    queryFn: () => job({ id: id ?? "" }),
    enabled: !isPreview && !!id,
    initialData: { data: preloadedJob },
  });

  if (!jobsResult.isSuccess) return <></>;

  // Determine which job object to use based on whether it's a preview or fetched via query
  const jobData = isPreview ? preloadedJob : jobResult.data.data;

  if (!jobData) return <div>Loading...</div>; // Handle loading state if job data is not available yet

  return (
    <div className={`${!isPreview && "xl:px-[180px]"}`}>
      <div className="flex w-full flex-col max-xl:px-4 md:flex-row">
        <div className="my-6">
          <ScoreTable />
        </div>
        <div className="ml-5 flex flex-col">
          <div className="mb-4">
            <div className="my-6 flex flex-row items-center">
              <NavLink to={"/profile/" + jobData.user.id + "/jobs"}>
                <div className="mr-2 size-12">
                  <img
                    className="size-full rounded-lg object-cover"
                    src={jobData.user.avatar_url}
                    alt=""
                  />
                </div>
              </NavLink>
              <NavLink to={`/jobs/${jobData.id}`}>
                <div className="text-sm font-semibold">{jobData.title}</div>
              </NavLink>
              {!isPreview && (
                <ArrowUturnLeftIcon
                  className="ml-auto size-6 cursor-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              )}
            </div>
            <JobReviews />
          </div>
        </div>
      </div>
      <div className="pt-2 max-xl:px-4">
        {t("main.navLinks.jobsPublished.similarJobs")}
        <div className="w-full">
          <JobCarousel>
            {jobsResult.data?.data.map(job => (
              <div key={job.id}>
                <SwiperSlide className="w-full p-2">
                  <JobCard job={job} />
                </SwiperSlide>
              </div>
            ))}
          </JobCarousel>
        </div>
      </div>
    </div>
  );
};

export default JobReviewsContainer;
