import api from "@src/api/api";
import { SidebarType } from "@src/types/SidebarType";

type SidebarResponse = {
  data: SidebarType;
};

export default async function sidebar(): Promise<SidebarResponse> {
  const { data } = await api.get("/api/v1/sidebar");
  return data;
}
