import { SetStateAction } from "react";

function resizeHandler(
  viewPort: VisualViewport | null,
  setBottomPadding: (value: SetStateAction<number>) => void,
  setInitHeight: (value: SetStateAction<number>) => void,
) {
  if (window.visualViewport && viewPort) {
    let iosHeight = 0;
    if (!/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
      iosHeight = viewPort.height;
      setInitHeight(viewPort.height);
    }
    setBottomPadding(Math.abs(iosHeight - viewPort.height));
  }
}

export default resizeHandler;
