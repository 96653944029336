import { useTranslation } from "react-i18next";

import NavSideLink from "@src/components/nav/NavSideLink";
import NavSideLinkList, { ListType } from "@src/components/nav/NavSideLinkList";

type Props = {
  isMobile?: boolean;
  onClose?: () => void;
  linkIndex: string;
  setLinkIndex: (index: string) => void;
};

const NavSideUser = ({ isMobile = false, onClose, linkIndex = "", setLinkIndex }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="top-[52px] z-10 w-full border-gray-300">
      <div className="flex flex-col gap-2">
        {NavSideLinkList(t).map((link: ListType) => (
          <NavSideLink
            link={link}
            key={link.href}
            isMobile={isMobile}
            onClose={onClose}
            linkIndex={linkIndex}
            setLinkIndex={newIndex => {
              setLinkIndex(newIndex);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default NavSideUser;
