import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import DownloadPDFInvoice from "@src/pages/marketplace/components/PDFInvoice";

const OrderDetails = () => {
  const navigate = useNavigate();
  const invoiceData = {
    billingNo: "ADUQ2189H1-0038",
    billingName: "John Doe",
    email: "sara@site.com",
    billingAddress1: "280 Suzanne Throughway,",
    billingAddress2: "Breannabury, OR 45801,",
    billingAddress3: "United States",
    date: "10 Jan 2023",
    paymentMethod: "Paypal",
    items: [
      { name: "Produkt 1", quantity: 2, price: 50 },
      { name: "Produkt 2", quantity: 7, price: 100 },
      { name: "Produkt 3", quantity: 9, price: 100 },
      { name: "Produkt 4", quantity: 1, price: 100 },
    ],
    total: 200,
  };
  return (
    <div className="my-4 px-4 sm:my-10 sm:px-6 lg:px-8">
      <ArrowUturnLeftIcon
        className="mb-5 ml-auto size-6 cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      />

      <div className="mb-5 flex items-center justify-between border-b border-gray-200 pb-5">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800">{t("main.shop.price.invoice")}</h2>
        </div>

        <div className="inline-flex gap-x-2">
          <a
            className="inline-flex items-center gap-x-2 rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 focus:bg-gray-50 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
            href="#"
          >
            <svg
              className="size-4 shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="7 10 12 15 17 10" />
              <line x1="12" y1="15" x2="12" y2="3" />
            </svg>
            <DownloadPDFInvoice invoiceData={invoiceData} />
          </a>
        </div>
      </div>

      <div className="flex flex-row">
        <div>
          <div className="grid space-y-3">
            <dl className="flex flex-col gap-x-3 text-sm sm:flex-row">
              <dt className="min-w-36 max-w-[200px] text-gray-500">
                {t("main.shop.price.billedTo")}:
              </dt>
              <dd className="text-gray-800">
                <a
                  className="inline-flex items-center gap-x-1.5 font-medium text-blue-600 decoration-2 hover:underline focus:underline focus:outline-none"
                  href="#"
                >
                  {invoiceData.email}
                </a>
              </dd>
            </dl>

            <dl className="flex flex-col gap-x-3 text-sm sm:flex-row">
              <dt className="min-w-36 max-w-[200px] text-gray-500">
                {t("components.shop.billingAddress")}:
              </dt>
              <dd className="font-medium text-gray-800">
                <span className="block font-semibold"> {invoiceData.billingName}</span>
                <address className="font-normal not-italic">
                  {invoiceData.billingAddress1}
                  <br />
                  {invoiceData.billingAddress2}
                  <br />
                  {invoiceData.billingAddress3}
                  <br />
                </address>
              </dd>
            </dl>

            <dl className="flex flex-col gap-x-3 text-sm sm:flex-row">
              <dt className="min-w-36 max-w-[200px] text-gray-500">
                {t("main.shop.price.shippingDetails")}:
              </dt>
              <dd className="font-medium text-gray-800">
                <span className="block font-semibold">{invoiceData.billingName}</span>
                <address className="font-normal not-italic">
                  {invoiceData.billingAddress1}
                  <br />
                  {invoiceData.billingAddress2}
                  <br />
                  {invoiceData.billingAddress3}
                  <br />
                </address>
              </dd>
            </dl>
          </div>
        </div>

        <div className="ml-auto">
          <div className="grid space-y-3">
            <dl className="flex flex-col gap-x-3 text-sm sm:flex-row">
              <dt className="min-w-36 max-w-[200px] text-gray-500">
                {t("main.shop.price.invoiceNumber")}:
              </dt>
              <dd className="font-medium text-gray-800"> {invoiceData.billingNo}</dd>
            </dl>

            <dl className="flex flex-col gap-x-3 text-sm sm:flex-row">
              <dt className="min-w-36 max-w-[200px] text-gray-500">
                {t("pages.marketplace.currency")}:
              </dt>
              <dd className="font-medium text-gray-800">{t("pages.paymentAndOrder.price")}</dd>
            </dl>

            <dl className="flex flex-col gap-x-3 text-sm sm:flex-row">
              <dt className="min-w-36 max-w-[200px] text-gray-500">
                {t("main.shop.price.dueDate")}:
              </dt>
              <dd className="font-medium text-gray-800"> {invoiceData.date}</dd>
            </dl>

            <dl className="flex flex-col gap-x-3 text-sm sm:flex-row">
              <dt className="min-w-36 max-w-[200px] text-gray-500">
                {t("components.shop.paymentmethod")}
              </dt>
              <dd className="font-medium text-gray-800"> {invoiceData.paymentMethod}</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="mt-6 space-y-4 rounded-lg border border-gray-200 p-4">
        <div className="hidden sm:grid sm:grid-cols-5">
          <div className="text-xs font-medium uppercase text-gray-500 sm:col-span-2">
            {t("main.shop.price.item")}
          </div>
          <div className="text-start text-xs font-medium uppercase text-gray-500">
            {t("main.shop.price.quantity")}
          </div>
          <div className="text-start text-xs font-medium uppercase text-gray-500">
            {t("main.shop.price.rate")}
          </div>
          <div className="text-end text-xs font-medium uppercase text-gray-500">
            {t("pages.marketplace.amount")}
          </div>
        </div>
        {invoiceData.items.map(item => (
          <>
            <div className="hidden border-b border-gray-200 sm:block"></div>

            <div className="grid grid-cols-3 gap-2 sm:grid-cols-5">
              <div className="col-span-full sm:col-span-2">
                <h5 className="text-xs font-medium uppercase text-gray-500 sm:hidden">
                  {t("main.shop.price.item")}
                </h5>
                <p className="font-medium text-gray-800">{item.name}</p>
              </div>
              <div>
                <h5 className="text-xs font-medium uppercase text-gray-500 sm:hidden">
                  {" "}
                  {t("main.shop.price.quantity")}
                </h5>
                <p className="text-gray-800">{item.quantity}</p>
              </div>
              <div>
                <h5 className="text-xs font-medium uppercase text-gray-500 sm:hidden">
                  {" "}
                  {t("main.shop.price.rate")}
                </h5>
                <p className="text-gray-800">{item.price.toFixed(2)}</p>
              </div>
              <div>
                <h5 className="text-xs font-medium uppercase text-gray-500 sm:hidden">
                  {t("pages.marketplace.amount")}
                </h5>
                <p className="text-gray-800 sm:text-end">
                  €{(item.quantity * item.price).toFixed(2)}
                </p>
              </div>
            </div>
          </>
        ))}
      </div>

      <div className="mt-8 flex sm:justify-end">
        <div className="w-full max-w-2xl space-y-2 sm:text-end">
          <div className="grid grid-cols-2 gap-3 sm:grid-cols-1 sm:gap-2">
            <dl className="grid gap-x-3 text-sm sm:grid-cols-5">
              <dt className="col-span-3 text-gray-500">{t("main.shop.price.subtotal")}:</dt>
              <dd className="col-span-2 font-medium text-gray-800">€2750.00</dd>
            </dl>

            <dl className="grid gap-x-3 text-sm sm:grid-cols-5">
              <dt className="col-span-3 text-gray-500">{t("main.shop.price.total")}:</dt>
              <dd className="col-span-2 font-medium text-gray-800">€2750.00</dd>
            </dl>

            <dl className="grid gap-x-3 text-sm sm:grid-cols-5">
              <dt className="col-span-3 text-gray-500">{t("main.shop.price.tax")}:</dt>
              <dd className="col-span-2 font-medium text-gray-800">€39.00</dd>
            </dl>

            <dl className="grid gap-x-3 text-sm sm:grid-cols-5">
              <dt className="col-span-3 text-gray-500">{t("main.shop.price.amountPaid")}:</dt>
              <dd className="col-span-2 font-medium text-gray-800">€2789.00</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
