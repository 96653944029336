import { PropsWithChildren } from "react";

type Props = {
  to: string;
};
const ExternalLink = (props: PropsWithChildren<Props>) => {
  return (
    <a href={props.to} target="_blank" rel="noopener noreferrer" className="text-lightblue">
      {props.children}
    </a>
  );
};

export default ExternalLink;
