import api, { RequestWithParams, requestParams } from "@src/api/api";
import { BookmarkType } from "@src/types/BookmarkType";

type BookmarksParams = {
  page?: number;
};

type BookmarksRequest = RequestWithParams<BookmarksParams>;

type BookmarksResponse = {
  data: BookmarkType[];
  current_page: number;
  total_pages: number;
};

export default async function bookmarks(request: BookmarksRequest): Promise<BookmarksResponse> {
  const { data } = await api.get(
    `/api/v1/bookmarks${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
