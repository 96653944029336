import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import myProfileViewsStatistics from "@src/api/contacts/myProfileViewsStatistics";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type Props = {
  searchQuery?: string;
};

const PageView = ({ searchQuery }: Props) => {
  const { t } = useTranslation();

  const { result: myProfileViewsStatisticsResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", searchQuery],
    queryFn: async ({ pageParam }) =>
      myProfileViewsStatistics({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });
  const allData = myProfileViewsStatisticsResult.data?.pages.flatMap(page => page.data) || [];
  const labels = allData.map(item => {
    const periodNumber = Number(item.period);
    const date = new Date(periodNumber * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}`;
  });

  const counts = allData.map(item => item.count);
  const data = {
    labels: labels,
    datasets: [
      {
        label: t("main.contacts.statistics.profileViews"),
        data: counts,
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "#fff",
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        min: 1,
        max: 1000,
      },
    },
  };

  return (
    <div>
      <h2 className="mb-2 text-lg font-semibold text-gray-800">
        {t("main.contacts.statistics.profileViews")}
      </h2>
      <Line data={data} options={options} />
    </div>
  );
};

export default PageView;
