import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import GroupsCreateContainer from "@src/pages/groups/GroupsCreateContainer";

type Props = {
  tab?: string;
  subTab?: string;
};

const GroupsContainer = ({ tab = "toYou", subTab = "" }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getTabLabel = (key: string) => {
    switch (key) {
      case "requested":
        return t("main.header.links.groups.tabs.requested");

      case "statistics":
        return t("main.header.links.groups.tabs.statistics");

      case "trending":
        return t("main.header.links.groups.tabs.trending");

      case "pending":
        return t("main.header.links.groups.tabs.pending");

      case "following":
        return t("main.header.links.groups.tabs.following");

      case "myGroups":
        return t("main.header.links.groups.tabs.myGroups");

      case "toYouAll":
      default:
        return t("main.header.links.groups.tabs.toYou");
    }
  };

  return (
    <TabController defaultTab={tab}>
      <TabHeaders hideOnTabs={["create"]}>
        <TabHeader id="myGroups" url="/gruppen/meine_gruppen">
          {t("main.header.links.groups.tabs.myGroups")}
        </TabHeader>
        <TabHeader id="toYou" url="/gruppen/für_dich">
          {t("main.header.links.groups.tabs.toYou")}
        </TabHeader>
        <TabHeader id="create" url="/gruppen/erstellen" hideMobile>
          {t("main.header.links.groups.tabs.create")}
        </TabHeader>
        <TabHeader id="following" url="/gruppen/following">
          {t("main.header.links.groups.tabs.following")}
        </TabHeader>
        <TabHeader id="trending" url="/gruppen/trending">
          {t("main.header.links.groups.tabs.trending")}
        </TabHeader>
      </TabHeaders>
      {tab !== "create" ? (
        <Input
          name="groups-keywords"
          placeholder={t("components.shared.search", {
            tab: getTabLabel(subTab || tab),
          })}
          clearable
        />
      ) : (
        <></>
      )}

      <TabBodies>
        <TabBody id="myGroups"></TabBody>
        <TabBody id="toYou">
          <TabController defaultTab={subTab} isSubTab>
            <TabHeaders>
              <TabHeader id="toYouAll" url="/gruppen/für_dich">
                {t("main.navLinks.createAd.tabs.overview")}
              </TabHeader>
              <TabHeader id="requested" url="/gruppen/für_dich/angefragt">
                {t("main.navLinks.createAd.tabs.requested")}
              </TabHeader>
              <TabHeader id="pending" url="/gruppen/für_dich/ausstehend">
                {t("main.contacts.tabs.pending")}
              </TabHeader>
              <TabHeader id="statistics" url="/gruppen/für_dich/statistik">
                {t("main.contacts.tabs.statistics")}
              </TabHeader>
            </TabHeaders>
            <TabBodies>
              <TabBody id="toYouAll">
                <></>
              </TabBody>
              <TabBody id="requested">
                <></>
              </TabBody>
              <TabBody id="pending">
                <></>
              </TabBody>
              <TabBody id="statistics">
                <></>
              </TabBody>
            </TabBodies>
          </TabController>
        </TabBody>
        <TabBody id="create">
          <GroupsCreateContainer onClose={() => navigate("/gruppen/für_dich")} />
        </TabBody>
        <TabBody id="following"></TabBody>
        <TabBody id="trending"></TabBody>
      </TabBodies>
    </TabController>
  );
};

export default GroupsContainer;
