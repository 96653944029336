import { useState } from "react";
import { useTranslation } from "react-i18next";

import jobs from "@src/api/jobs/jobs";
import Input from "@src/components/elements/input/Input";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import JobsListItem from "@src/pages/jobs/JobsListItem";
import { JobType } from "@src/types/JobType";

const ProfileDetailsJobs = ({ userId = "" }: { userId?: string }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const { result: jobsResult } = useInfiniteQueryOnScroll({
    queryKey: ["jobs", searchQuery],
    queryFn: ({ pageParam }) =>
      jobs({ params: { page: pageParam, q: searchQuery, user_id: userId } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <Input
        placeholder={t("main.jobsTabs.placeholder")}
        name="jobs_keywords"
        onChange={e => setSearchQuery(e)}
        clearable
      />
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-5">
        {jobsResult.data?.pages.map(page =>
          page?.data?.map((job: JobType) => (
            <JobsListItem key={job.id} job={job} showButtons={false} />
          )),
        )}
      </div>
    </>
  );
};

export default ProfileDetailsJobs;
