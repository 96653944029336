import api, { RequestWithParams, requestParams } from "@src/api/api";

type AvailableParams = {
  username: string;
};

type AvailableRequest = RequestWithParams<AvailableParams>;

type AvailableResponse = {
  available: boolean;
};

export default async function available(request: AvailableRequest): Promise<AvailableResponse> {
  const { data } = await api.get(
    `/api/v1/users/username/available${requestParams(request.params)}`,
  );
  return data;
}
