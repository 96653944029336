import api, { RequestWithData, RequestWithId } from "@src/api/api";

type RequestData = {
  text: string;
  adult: boolean;
  media: string[];
  link?: string;
  set_active_at?: number;
  link_preview_id?: string;
  watch?: boolean;
};

type UpdateRequest = RequestWithId & RequestWithData<RequestData>;

export default async function update(request: UpdateRequest): Promise<void> {
  const { data } = await api.patch(`/api/v1/posts/${request.id}`, request.data);
  return data;
}
