import api, { RequestWithParams, requestParams } from "@src/api/api";
import { PostType } from "@src/types/PostType";

type FeedParams = {
  page?: number | null;
  q?: string | null;
  user_id?: string | null;
  liked_by?: string | null;
};

type FeedRequest = RequestWithParams<FeedParams>;

export type FeedResponse = {
  data: PostType[];
  current_page: number;
  total_pages: number;
};

export default async function feed(request: FeedRequest): Promise<FeedResponse> {
  const { data } = await api.get(`/api/v1/feed${requestParams({ page: 0, ...request.params })}`);
  return data;
}
