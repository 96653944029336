import api, { RequestWithId } from "@src/api/api";
import { PostType } from "@src/types/PostType";

type PostRequest = RequestWithId;

type PostResponse = PostType;

export default async function post(request: PostRequest): Promise<PostResponse> {
  const { data } = await api.get(`/api/v1/post/${request.id}`);
  return data;
}
