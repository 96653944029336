import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { t } from "i18next";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";

import AllProducts from "@src/mocks/AllProducts.json";
import Product from "@src/pages/marketplace/components/Product";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
);

const PurchaseOverview = () => {
  const allProductsFromFile = AllProducts.allProducts;

  const mockRecentPurchases = [
    { title: "Smartphone", date: "2024-07-10", amount: 799.99 },
    { title: "Laptop", date: "2024-07-05", amount: 1299.99 },
    { title: "Headphones", date: "2024-06-25", amount: 199.99 },
    { title: "Watch", date: "2024-06-20", amount: 299.99 },
  ];

  const pieData = {
    labels: ["Electronics", "Clothing", "Accessories", "Others"],
    datasets: [
      {
        label: t("main.sell.created.categories"),
        data: [40, 30, 20, 10],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
      },
    ],
  };

  const barData = {
    labels: mockRecentPurchases.map(purchase => purchase.date),
    datasets: [
      {
        label: t("main.marketplace.tabs.purchaseAmount"),
        data: mockRecentPurchases.map(purchase => purchase.amount),
        backgroundColor: "#4BC0C0",
      },
    ],
  };

  const lineData = {
    labels: mockRecentPurchases.map(purchase => purchase.date),
    datasets: [
      {
        label: t("main.marketplace.tabs.spendingTrend"),
        data: mockRecentPurchases.map(purchase => purchase.amount),
        fill: false,
        borderColor: "#4BC0C0",
        tension: 0.1,
      },
    ],
  };

  const doughnutData = {
    labels: [
      t("main.marketplace.tabs.completed"),
      t("main.contacts.tabs.pending"),
      t("main.marketplace.tabs.cancel"),
    ],
    datasets: [
      {
        label: t("main.marketplace.tabs.orderStatus"),
        data: [60, 30, 10],
        backgroundColor: ["#4BC0C0", "#FFCE56", "#FF6384"],
        hoverBackgroundColor: ["#4BC0C0", "#FFCE56", "#FF6384"],
      },
    ],
  };

  return (
    <>
      <div className="mb-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg bg-blue-100 p-4 text-center">
          <h3 className="text-lg font-medium text-blue-700">
            {t("main.marketplace.tabs.totalExpenditure")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-blue-900">€{"2000"}</p>
        </div>

        <div className="rounded-lg bg-green-100 p-4 text-center">
          <h3 className="text-lg font-medium text-green-700">
            {t("main.marketplace.tabs.totalOders")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-green-900">{"3"}</p>
        </div>

        <div className="rounded-lg bg-yellow-100 p-4 text-center">
          <h3 className="text-lg font-medium text-yellow-700">
            {t("main.marketplace.tabs.avarageOderValue")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-yellow-900">€{"200"}</p>
        </div>

        <div className="rounded-lg bg-purple-100 p-4 text-center">
          <h3 className="text-lg font-medium text-purple-700">
            {t("main.marketplace.tabs.recentPurchase")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-purple-900">
            {mockRecentPurchases.length}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
        <div className="rounded-lg bg-white p-4 shadow">
          <h3 className="mb-4 text-lg font-medium text-gray-700">
            {t("main.marketplace.tabs.categoryDistribution")}
          </h3>
          <Pie data={pieData} />
        </div>

        <div className="rounded-lg bg-white p-4 shadow">
          <h3 className="mb-4 text-lg font-medium text-gray-700">
            {t("main.marketplace.tabs.recentPurchase")}
          </h3>
          <Bar data={barData} />
        </div>

        <div className="rounded-lg bg-white p-4 shadow">
          <h3 className="mb-4 text-lg font-medium text-gray-700">
            {t("main.marketplace.tabs.spendingTrend")}
          </h3>
          <Line data={lineData} />
        </div>

        <div className="rounded-lg bg-white p-4 shadow">
          <h3 className="mb-4 text-lg font-medium text-gray-700">
            {t("main.marketplace.tabs.oderStatus")}
          </h3>
          <Doughnut data={doughnutData} />
        </div>
      </div>

      <h3 className="mt-4 text-xl font-semibold text-gray-800">
        {t("main.marketplace.tabs.recentPurchase")}
      </h3>

      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
        {allProductsFromFile.map(product => (
          <div key={product.ID} className="flex justify-center">
            <Product product={product} />
          </div>
        ))}
      </div>
    </>
  );
};

export default PurchaseOverview;
