import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  key?: string;
  to: string;
  counter?: number;
  active?: boolean;
  state?: { backlink: string };
};

const TabLink = ({ to, children, counter, active, state }: PropsWithChildren<Props>) => (
  <NavLink
    to={to}
    state={state}
    onClick={e => {
      e.currentTarget.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }}
  >
    {({ isActive }) => (
      <span
        className={`relative flex items-center gap-1 whitespace-nowrap border-b-2 border-darkblue ${active || isActive ? "text-gray-900" : "border-transparent text-gray-500"}`}
      >
        {children}
        {counter !== undefined ? (
          <span className="mx-1 inline-flex items-center rounded-full bg-lightgray px-2 text-xs font-light">
            {counter}
          </span>
        ) : null}
      </span>
    )}
  </NavLink>
);

export default TabLink;
