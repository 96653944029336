import api, { RequestWithData } from "@src/api/api";
import { LoginType } from "@src/types/LoginType";

type LoginData = {
  email: string;
  password: string;
};

export type LoginRequest = RequestWithData<LoginData>;

type LoginResponse = {
  data: LoginType;
};

export default async function login(request: LoginRequest): Promise<LoginResponse> {
  const { data } = await api.post("/api/v1/auth/login", request.data);
  return {
    data: {
      user: data.data,
      token: data.token,
      refreshToken: data.refresh_token,
    },
  };
}
