import api, { RequestWithId, RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type FollowingParams = {
  page?: number;
  q?: string;
};

type FollowingRequest = RequestWithId & RequestWithParams<FollowingParams>;

type FollowingResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function following(request: FollowingRequest): Promise<FollowingResponse> {
  const { data } = await api.get(
    `/api/v1/users/${request.id}/following${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
