import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EnvelopeIcon,
  PencilIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import ButtonsBar from "@src/components/elements/form/ButtonsBar";
import Button from "@src/components/elements/input/Button";
import StarsRating from "@src/components/elements/input/StarsRating";
import ScoreTable from "@src/components/elements/shop/ScoreTable";
import StatsHeader from "@src/components/elements/shop/StatsHeader";
import { mediaData } from "@src/data/mediaData";
import ImageGrid from "@src/pages/marketplace/shop/item/ImageGrid";
import { MediaType } from "@src/types/MediaType";

const AssignmentsDetails = () => {
  const { id } = useParams();
  const [textMinimized, setTextMinimized] = useState(true);
  const userid = "";
  const sampleText =
    "WCPN (104.9 FM) is a non-commercial educational radio station licensed to Lorain, Ohio, featuring a public radio format as a repeater of Kent–licensed WKSU. Owned by Ideastream Public Media, the station serves the western portion of Greater Cleveland and parts of surrounding Northeast Ohio. By virtue of WKSU, studios are located at Playhouse Square in Downtown Cleveland,[2] while WCPN's transmitter resides in the Cleveland suburb of Avon. In addition to a standard analog transmission, WCPN broadcasts over four HD Radio channels and streams online. Signing on in 1975 under the WZLE call sign, the station originally focused on Lorain County with a full-service format of local news and easy listening music dubbed  mellow gold . A 1983 sale of WZLE to a local non-profit Christian group saw the station switch to Christian radio programming, eventually focusing on contemporary Christian music by the early 1990s. Jacor's purchase of WZLE in 1998 resulted in a format change to contemporary hit radio as the first home of WAKS the following year. A complex seven-station asset swap involving Jacor's successor company Clear Channel, Salem Communications and WCLV (95.5 FM) owner Radio Seaway on July 3, 2001, resulted in 104.9 FM becoming the second home of WCLV and its classical music format. Converted to non-commercial status and donated to Ideastream in 2013, WCLV's programming and call letters moved to 90.3 FM on March 28, 2022; in turn, this station became a full-power satellite of co-managed WKSU, bearing the WCPN calls. ";
  const navigate = useNavigate();

  const media: Array<MediaType> = mediaData; //Todo: replace with real data
  //! Also add translation after logik is implemented

  return (
    <div className="xl:px-[180px]">
      {/* Title */}
      <div className="flex flex-row items-start justify-between max-xl:px-4">
        <div className="text-xl">Title Title Title</div>

        <ArrowUturnLeftIcon
          className="size-6 cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      <StatsHeader />

      <div className="flex flex-col gap-4 divide-y divide-black text-darkblue">
        <div className="relative flex h-fit flex-col gap-12 xl:flex-row">
          {/* Left side */}
          <div className="left-0 top-0 flex h-fit shrink-0 flex-col gap-2 xl:sticky xl:w-2/5">
            <ImageGrid
              images={media}
              likeButton={() => {
                return;
              }}
              shareButton={() => {
                return;
              }}
            />
            <div className="max-xl:px-4">
              <ButtonsBar
                spread
                likeButton={{
                  id: `${id}`,
                  type: "assignment",
                  liked: false,
                  like_count: 0,
                }}
                viewButton={{ view_count: 0 }}
                chatButton={{
                  id: userid,
                }}
                shareButton={{
                  title: "Profil",
                  body: new URL(`/assignments/${id}`, window.location.href).toString(),
                }}
                itemId={id ?? ""}
                itemType="assignments"
              />
            </div>
          </div>
          {/* Right side */}
          <div className="flex w-full flex-col justify-between gap-2 text-sm max-xl:px-4 xl:flex-row">
            <div className="flex flex-col">
              <div className="font-semibold">{t("components.assignments.description")}</div>
              <div className={`text-sm ${textMinimized && "line-clamp-5"}`}>{sampleText}</div>
              <button
                className="mt-1 flex w-full flex-row items-center justify-center gap-2 rounded-full border border-gray-500 text-sm"
                onClick={() => setTextMinimized(!textMinimized)}
              >
                {textMinimized ? t("buttons.keepReading") : t("buttons.less")}
                {textMinimized ? (
                  <ChevronDownIcon className="size-4" />
                ) : (
                  <ChevronUpIcon className="size-4" />
                )}
              </button>
            </div>
            {/* Info side */}
            <div className="flex flex-col gap-8 text-gray-800 xl:w-[250px]">
              <div className="flex flex-col gap-8 text-gray-800">
                {/* Info */}
                <div className="flex h-fit flex-col rounded-2xl border border-gray-200 bg-gray-100 px-2 py-1 text-sm">
                  <div className="mb-2 whitespace-nowrap border-b border-gray-300 pb-2 font-bold">
                    {t("main.navLinks.jobsPublished.general")}
                  </div>
                  <div className="border-b text-sm">{t("components.shared.wanted")}</div>
                  <div className="inline-flex gap-1.5">
                    <div className="whitespace-nowrap">{t("components.shared.addNumber")}</div>
                    3234623233
                    <div>/</div>
                    <div className="whitespace-nowrap">{t("components.shared.private")}</div>
                  </div>

                  <div className="gap-1 text-gray-800">
                    <div>{t("components.shared.fromTo")}:</div>
                    <div>03.04.2005 bis 05.06.2007</div>
                  </div>

                  <div className="inline-flex gap-1 text-gray-800">
                    <div className="font-semibold">
                      {t("main.navLinks.jobsPublished.location")}:
                    </div>
                    02433 Meissen, Sachsen
                  </div>
                </div>

                {/* Conditions */}
                <div className="flex h-fit flex-col rounded-2xl border border-gray-200 bg-gray-100 px-2 py-1 text-sm">
                  <div className="mb-2 whitespace-nowrap border-b border-gray-300 pb-2 font-bold">
                    {t("main.navLinks.jobsPublished.condition")}
                  </div>
                  <div className="inline-flex gap-1 text-gray-800">
                    <div>{t("components.assignments.compensation")}</div>
                    12,34 € pro Stunde
                  </div>

                  <div className="inline-flex gap-1 text-gray-800">
                    <div>Vergütung in €:</div>
                    90000
                  </div>

                  <div className="inline-flex gap-1 text-gray-800">
                    <div className="text-darkred">Ab 18 freigegeben</div>
                  </div>

                  <div className="flex flex-col text-sm leading-5">
                    <div className="inline-flex gap-1 text-gray-800">
                      <div>Mitarbeiter:</div> 4004 Personen
                    </div>
                  </div>
                </div>

                {/* Info */}
                <div className="flex h-fit flex-col rounded-2xl border border-gray-200 bg-gray-100 px-2 py-1 text-sm">
                  <div className="mb-2 whitespace-nowrap border-b border-gray-300 pb-2 font-bold">
                    Anbieterinformationen
                  </div>

                  {/* Contact partner description*/}
                  <div className="inline-flex gap-1 text-gray-800">
                    <div>John Titor</div>
                  </div>
                  <div className="inline-flex gap-1 text-gray-800">Leipziger Straße 12</div>
                  <div>03429 Dresden</div>
                  <div className="inline-flex items-center gap-1 whitespace-normal break-normal text-sm text-gray-800">
                    <EnvelopeIcon className="size-4" />
                    qwertz@qwertzmail.com
                  </div>
                  <div className="inline-flex items-center gap-1 whitespace-normal break-all text-sm text-gray-800">
                    <PhoneIcon className="size-4" />
                    +01 858 2030405060708090
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Ad description */}
        <div className="flex flex-col gap-2 pt-2 max-xl:px-4">
          <div className="inline-flex gap-1 text-sm font-bold">
            {t("main.shop.details.seller")}:<NavLink to="/profile/">username</NavLink>
          </div>
          <div className="text-xs text-gray-800">
            <div className="float-left mr-1 whitespace-nowrap">
              {t("main.shop.details.addedSince")}:
            </div>
            00.00.0000
          </div>
          <div className="break-all text-sm">{id}</div>
        </div>
        {/* Comments and score*/}
        <div className="flex flex-col gap-2 pt-2 max-xl:px-4">
          <div className="flex flex-col gap-8 xl:flex-row">
            <ScoreTable />
            <div className="w-full">
              <div className="text-base font-bold">{t("main.shop.details.customerReviews")}:</div>
              <div className="mt-3 flex max-w-md flex-col gap-4 pl-4">reviewbox</div>
            </div>
          </div>

          <form
            name="loginForm"
            className="flex flex-col"
            method="post"
            encType="multipart/form-data"
          >
            <div className="mb-4 mt-6 inline-flex items-center gap-4">
              <PencilIcon className="size-4" />
              <div>{t("main.shop.details.reviewThis")}</div>
            </div>
            <StarsRating rating={1} editable />
            <div className="rounded-xl border border-gray-400">
              <textarea
                name="reviewText"
                className="w-full rounded-xl bg-transparent px-2 py-1"
                placeholder="Bewertung schreiben..."
              />
            </div>
            <div className="flex justify-end gap-3">
              <Button fitWidth>{t("main.shop.buttons.close")}</Button>
              <Button fitWidth>{t("main.shop.buttons.reviewSend")}</Button>
            </div>
          </form>
        </div>

        <div className="pt-2 max-xl:px-4">
          <div className="inline-flex gap-1">
            {t("main.shop.details.moreProductsFrom")}
            <div className="text-darkred">
              <NavLink to="/profile/">
                <a className="whitespace-nowrap">username</a>
              </NavLink>
            </div>
          </div>
          <div className="mt-2 flex flex-row justify-between gap-4">878</div>
        </div>
        <div className="pt-2">
          {t("main.shop.details.similarProducts")}
          <div className="mt-2 flex flex-row justify-between gap-4"></div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentsDetails;
