import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import carts from "@src/api/carts/carts";
import SidebarCartItem from "@src/pages/marketplace/shop/SidebarCartItem";
import { setCart } from "@src/state/cart/cartSlice";
import { useAppSelector } from "@src/state/hooks";
import { CartType } from "@src/types/CartType";

const SideBarCart = () => {
  const { user } = useAppSelector(state => state.user);
  const { cartContent } = useAppSelector(state => state.cart);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cartsResult = useQuery({
    queryKey: ["carts"],
    queryFn: () => carts(),
    enabled: !!user,
  });

  useEffect(() => {
    if (cartsResult.isSuccess) {
      dispatch(setCart(cartsResult.data.data));
    }
  }, [cartsResult, dispatch]);

  if (!cartsResult.isSuccess) return <></>;

  return (
    <>
      <div className="grid grid-flow-row text-center">
        <div className="text-sm">{t("main.shop.price.subtotal")}</div>
        <div className="font-semibold">
          {cartContent && cartContent.length > 0
            ? cartContent
                .map(item => item.quantity * (item.price_gross / 100))
                .reduce((sum, amount) => sum + amount)
                .toFixed(2)
            : 0}
          €
        </div>
      </div>
      <div className="w-full border-b border-gray-300 px-2 pb-3 pt-1">
        <NavLink
          className="flex w-full justify-center rounded-lg border border-gray-300 px-2 text-mini"
          to="/marketplace/warenkorb"
        >
          {t("main.shop.buttons.goToCart")}
        </NavLink>
      </div>
      <div className="flex flex-row">
        <div className="h-[90vh] overflow-y-scroll">
          {cartContent?.map((item: CartType) => (
            <SidebarCartItem item={item} key={item.product_id} />
          ))}
        </div>
      </div>
    </>
  );
};

export default SideBarCart;
