import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface HeaderImageState {
  headerImage: File | null;
}

const initialState: HeaderImageState = {
  headerImage: null,
};

const headerImageSlice = createSlice({
  name: "headerImage",
  initialState,
  reducers: {
    setHeaderImage: (state, { payload }: PayloadAction<File>) => {
      state.headerImage = payload;
    },
  },
});

export const { setHeaderImage } = headerImageSlice.actions;

export default headerImageSlice.reducer;
