import { useTranslation } from "react-i18next";

const BigTile = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full rounded-lg border border-gray-300 bg-white px-4 py-3">
      <span className="text-base font-semibold">{t("main.marketplace.tabs.titleSummerOffer")}</span>
      <a>
        <div className="relative mt-2 overflow-hidden rounded-lg">
          <img
            className="h-42 max-h-full w-full object-cover"
            src="https://cdn.pixabay.com/photo/2017/04/04/04/25/technology-2200260_1280.jpg"
            alt=""
          />
        </div>
        <a href="">
          <span className="text-xs">{t("main.marketplace.tabs.discoverMore")}</span>
        </a>
      </a>
    </div>
  );
};

export default BigTile;
