import { XMarkIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import chatsArchive from "@src/api/chats/archive/chats";
import chats from "@src/api/chats/chats";
import create from "@src/api/chats/create";
import UserComboBox from "@src/components/elements/form/UserComboBox";
import TabFilter from "@src/components/menu/TabFilter";
import BackButton from "@src/components/nav/BackButton";
import ChatContainer from "@src/pages/messenger/ChatContainer";
import Messenger from "@src/pages/messenger/Messenger";
import MessengerGroups from "@src/pages/messenger/MessengerGroups";
import ForwardMessagePopup from "@src/pages/messenger/chat/popup/ForwardMessagePopup";
import GroupInfo from "@src/pages/messenger/group/GroupInfo";
import UserInfo from "@src/pages/messenger/group/UserInfo";
import { useAppSelector } from "@src/state/hooks";
import { MediaType } from "@src/types/MediaType";
import isMobile from "@src/utils/isMobile";

const MessengerContainer = () => {
  const { user } = useAppSelector(state => state.user);
  const { t, i18n } = useTranslation();
  const [selectedChats, setSelectedChats] = useState<string[]>([]);
  const [tab, setTab] = useState<"chats" | "archive" | "group" | "groupinfo" | "userinfo">("chats");
  const queryClient = useQueryClient();
  const location = useLocation();
  const [selectedForm, setSelectedForm] = useState("chats");
  const [showSelections, setShowSelections] = useState(false);
  const [selectedChat, setSelectedChat] = useState<string | false>(location.state?.chatId || false);
  const [returnChat, setReturnChat] = useState<string | false>(location.state?.chatId || false);
  const [forwardMessage, setForwardMessage] = useState<
    | {
        text: string;
        link?: string;
        media?: MediaType[];
      }
    | false
  >(false);
  const [searchClick, setSearchClick] = useState<(visible: boolean) => void>();

  const chatsResult = useQuery({
    queryKey: ["chats", tab],
    queryFn: () => (tab === "archive" ? chatsArchive() : chats()),
    enabled: !!user,
  });

  const createMutation = useMutation({
    mutationKey: ["chats", "create"],
    mutationFn: create,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ["chats"] }).then(() => {
        setSelectedChat(data.id);
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const getTabLabel = (root: string, key: string) => {
    if (i18n.exists(`${root}.tabsAlt.${key}`)) return t(`${root}.tabsAlt.${key}`);
    if (i18n.exists(`${root}.tabs.${key}`)) return t(`${root}.tabs.${key}`);
    return "";
  };

  if (!user) return <></>;
  if (!chatsResult.isSuccess) return <></>;

  return (
    <>
      <BackButton />
      <div className="relative w-full gap-2 lg:grid lg:grid-cols-2">
        {forwardMessage && (
          <ForwardMessagePopup onClose={() => setForwardMessage(false)} message={forwardMessage} />
        )}
        <div className={`${selectedChat && "hidden lg:block"}`}>
          <>
            {(tab === "chats" || tab === "archive") && (
              <div className="lg:relative lg:h-fit lg:rounded-2xl lg:border lg:border-gray-300">
                {isMobile && (
                  <NavLink to="/news" className="absolute right-2 top-2 size-5">
                    <XMarkIcon />
                  </NavLink>
                )}

                <div className="p-2 max-md:pt-8">
                  <TabFilter
                    options={[
                      {
                        name: t("main.header.links.messenger.tabs.chats"),
                        key: "chats",
                      },
                      {
                        name: t("main.header.links.messenger.tabs.archive"),
                        key: "archive",
                      },
                      {
                        name: t("main.header.links.messenger.tabs.groups"),
                        key: "group",
                      },
                    ]}
                    value={tab}
                    onChange={s => setTab(s as "chats" | "archive" | "group")}
                  />
                </div>
                <div className="px-2">
                  <UserComboBox
                    onSelect={user => createMutation.mutate({ id: user.id })}
                    placeholder={t("components.shared.search", {
                      tab: getTabLabel("main.header.links.messenger", tab),
                    })}
                  />
                </div>
                <div className="mt-4 flex flex-row items-center justify-end gap-x-4 pb-2 pr-2 pt-1 text-xs max-md:pr-4">
                  {showSelections && (
                    <button
                      onClick={() => {
                        const newSelectedChats: Array<string> = [];
                        if (selectedChats.length < chatsResult.data.data.length) {
                          chatsResult.data.data.forEach(element => {
                            newSelectedChats.push(element.id);
                          });
                        }
                        setSelectedChats(newSelectedChats);
                      }}
                    >
                      {t("main.header.links.messenger.selectAll")}
                    </button>
                  )}
                  <input
                    id="chat-checkbox"
                    name="chat-checkbox"
                    type="checkbox"
                    checked={showSelections}
                    onChange={e => {
                      setShowSelections(e.target.checked);
                    }}
                    className="my-auto size-4 cursor-pointer rounded-full border-gray-300 text-blue-500 focus:ring-offset-0"
                  />
                </div>
                <div className="no-scrollbar flex h-[calc(100dvh-170px)] flex-col gap-2 overflow-y-auto lg:h-[calc(100dvh-220px)]">
                  {chatsResult.data.data.map(chat => (
                    <Messenger
                      key={chat.id}
                      chat={chat}
                      checked={selectedChats.includes(chat.id)}
                      showCheckbox={showSelections}
                      isSelected={chat.id === selectedChat}
                      onChange={checked => {
                        if (checked) {
                          setSelectedChats([...selectedChats, chat.id]);
                        } else {
                          setSelectedChats(selectedChats.filter(id => id !== chat.id));
                        }
                      }}
                      onClick={() => setSelectedChat(chat.id)}
                    />
                  ))}
                </div>
              </div>
            )}
            {tab === "group" && (
              <MessengerGroups
                chats={chatsResult.data.data}
                selectedChats={selectedChats}
                setSelectedChats={setSelectedChats}
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
                onClose={() => setTab("chats")}
                setSelectedChat={setSelectedChat}
              />
            )}
            {tab === "groupinfo" && (
              <GroupInfo
                groupId={returnChat.toString()}
                onClose={() => {
                  setSelectedChat(returnChat);
                  setReturnChat(false);
                  setTab("chats");
                  if (searchClick) searchClick(true);
                }}
              />
            )}
            {tab === "userinfo" && (
              <UserInfo
                userId={returnChat.toString()}
                onClose={() => {
                  setSelectedChat(returnChat);
                  setReturnChat(false);
                  setTab("chats");
                  if (searchClick) searchClick(true);
                }}
              />
            )}
          </>
        </div>

        {selectedChat && (
          <ChatContainer
            markedChats={selectedChats}
            chatId={selectedChat}
            switchTab={(newTab: "chats" | "archive" | "group" | "groupinfo" | "userinfo") => {
              setTab(newTab);
              setReturnChat(selectedChat);
              if (isMobile) {
                setSelectedChat(false);
              }
            }}
            exitChat={() => setSelectedChat(false)}
            forwardMessage={(text, link, media) => {
              setForwardMessage({ text, link, media });
            }}
            searchClick={{
              setFunction: setSearchClick,
              getFunction: searchClick,
            }}
          />
        )}
      </div>
    </>
  );
};

export default MessengerContainer;
