import api, { RequestWithData } from "@src/api/api";
import { CheckoutSessionType } from "@src/types/CheckoutSessionType";

type RequestData = {
  product_id: string;
  quantity: number;
};

type CheckoutSessionRequest = RequestWithData<RequestData>;

type CheckoutSessionResponse = {
  data: CheckoutSessionType;
};

export default async function checkoutSession(
  request: CheckoutSessionRequest,
): Promise<CheckoutSessionResponse> {
  const { data } = await api.post("/api/v1/payment/checkout-session", request.data);
  return data;
}
