import "@src/components/elements/posts/form/shared/editorTheme.css";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HashtagNode } from "@lexical/hashtag";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from "@lexical/react/LexicalAutoLinkPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { $getSelection, EditorState } from "lexical";
import { useEffect, useState } from "react";

import { MentionNode } from "@src/components/elements/posts/form/shared/MentionNode";
import MentionsPlugin from "@src/components/elements/posts/form/shared/MentionsPlugin";
import editTheme from "@src/utils/editTheme";

type Props = {
  value: string;
  onChange: (text: string) => void;
};

function onError(error: Error) {
  console.error(error);
}

const theme = editTheme("comment-editor-placeholder");

function onChange(editorState: EditorState, onChange: (text: string) => void) {
  onChange(JSON.stringify(editorState));
}

const Placeholder = () => {
  return <div className="comment-editor-placeholder">Dein Kommentar...</div>;
};

const URL_REGEX =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, text => {
    return text.startsWith("http") ? text : `https://${text}`;
  }),
];

const InitialEditorStatePlugin = ({ initialState }: { initialState: string }) => {
  const [initialized, setInitialized] = useState(false);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      try {
        const state = editor.parseEditorState(initialState);
        editor.setEditorState(state);
      } catch (e) {
        console.log(e);
        editor.update(() => {
          const selection = $getSelection();
          selection?.insertText(initialState);
        });
      }
    }
  }, [editor, initialState]);

  return null;
};

const CommentTextEdit = (props: Props) => {
  const initialConfig = {
    namespace: "PostTextEdit",
    theme,
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      MentionNode,
      HashtagNode,
    ],
  };

  return (
    <div className="flex w-full gap-3">
      <div className="flex grow text-sm">
        <div className="grid w-full grid-cols-1 gap-1">
          <LexicalComposer initialConfig={initialConfig}>
            <div className="comment-editor-container">
              <div className="editor-inner">
                <RichTextPlugin
                  contentEditable={<ContentEditable className="comment-editor-input" />}
                  placeholder={<Placeholder />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <InitialEditorStatePlugin initialState={props.value} />
                <HistoryPlugin />
                <AutoFocusPlugin />
                <MentionsPlugin />
                <ListPlugin />
                <LinkPlugin />
                <AutoLinkPlugin matchers={MATCHERS} />
                <HashtagPlugin />
                <OnChangePlugin onChange={editorState => onChange(editorState, props.onChange)} />
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              </div>
            </div>
          </LexicalComposer>
        </div>
      </div>
    </div>
  );
};

export default CommentTextEdit;
