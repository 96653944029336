import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BackButton from "@src/components/nav/BackButton";
import glossaryDe from "@src/locales/de/glossary.json";
import glossaryEn from "@src/locales/en/glossary.json";

type Translation = {
  headline: string;
  text: string | { [key: string]: string };
};

const RenderText = ({ item }: { item: Translation }) => {
  if (typeof item.text === "string") {
    return <p>{item.text}</p>;
  } else {
    return item.text
      ? Object.values(item.text).map(text => <p key={item.headline}>{text}</p>)
      : null;
  }
};

const GlossaryContainer = () => {
  const { t, i18n } = useTranslation();

  type GlossaryType = {
    [entry: string]: Translation;
  };

  const [sortedTranslations, setSortedTranslations] = useState<Translation[]>([]);

  useEffect(() => {
    let translations: GlossaryType = {};

    if (!translations) {
      return;
    }

    if (i18n.language === "en") {
      translations = glossaryEn;
    } else if (i18n.language === "de") {
      translations = glossaryDe;
    }

    const entries = Object.values(translations).filter(
      (entry): entry is Translation =>
        typeof entry !== "string" && Object.prototype.hasOwnProperty.call(entry, "headline"),
    );
    const sorted = entries.sort((a, b) => a.headline.localeCompare(b.headline));

    setSortedTranslations(sorted);
  }, [i18n]);

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-6 pb-4 text-justify text-xs text-gray-700">
        <div className="text-left text-smbase font-bold">{t("pages.glossary.header")}</div>
        <div className="flex flex-col gap-3">
          {sortedTranslations.map(item => (
            <div key={item.headline}>
              <h1 className="text-xs font-semibold">{item.headline}</h1>
              <RenderText item={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GlossaryContainer;
