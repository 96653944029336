import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import BackButton from "@src/components/nav/BackButton";

const TermsOfUseContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <h1 className="text-left text-smbase font-bold">{t("main.signIn.userAgreement")}</h1>
        <div>{t("pages.copyrightContainer.text2")} 22.03.2024</div>
        <div>{t("pages.tosContainer.text1")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text8")}:</h2>
        <ul className="list-inside list-decimal">
          <li>{t("pages.tosContainer.text2")}</li>
          <li>{t("pages.tosContainer.text3")}</li>
          <li>{t("pages.tosContainer.text4")}</li>
          <li>{t("pages.tosContainer.text5")}</li>
          <li>{t("pages.tosContainer.text6")}</li>
          <li>{t("pages.tosContainer.text7")}</li>
          <li>{t("pages.conditionContainer.header")}</li>
          <li>{t("pages.tosContainer.text9")}</li>
          <li>{t("pages.tosContainer.text10")}</li>
          <li>{t("pages.tosContainer.text11")}</li>
        </ul>

        <h2 className="font-semibold">1. {t("pages.tosContainer.text2")}</h2>
        <h2 className="font-semibold">1.1 {t("pages.tosContainer.text12")}</h2>
        <div>
          {t("pages.tosContainer.text13.1")}
          <NavLink to="/cookies" className="text-darkcyan">
            {t("pages.tosContainer.cookiepolicy")}
          </NavLink>
          {t("pages.tosContainer.text13.2")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.tosContainer.privacypolicy")}
          </NavLink>
          {t("pages.tosContainer.text13.3")}
        </div>
        <div>{t("pages.tosContainer.text14")}</div>
        <h2 className="font-semibold">{t("components.assignments.category.service")}</h2>
        <div>{t("pages.tosContainer.text15")}</div>
        <h2 className="font-semibold">TRADEFOOX</h2>
        <div>{t("pages.tosContainer.text16")}</div>
        <div>{t("pages.tosContainer.text17")}</div>
        <div>{t("pages.tosContainer.text18")}</div>
        <div>{t("pages.tosContainer.text19")}</div>
        <div>{t("pages.tosContainer.text20")}</div>
        <div>
          {t("pages.tosContainer.text21.1")}
          <NavLink to="/cookies" className="text-darkcyan">
            {t("pages.tosContainer.cookiepolicy")}
          </NavLink>
          {t("pages.tosContainer.text21.2")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.tosContainer.privacypolicy")}
          </NavLink>
          {t("pages.tosContainer.text21.3")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.tosContainer.privacypolicy")}
          </NavLink>
          {t("pages.tosContainer.text21.4")}
        </div>
        <h2 className="font-semibold">1.2 {t("pages.tosContainer.text22")}</h2>
        <div>{t("pages.tosContainer.text23")}</div>
        <h2 className="font-semibold">1.3 {t("pages.tosContainer.text24")}</h2>
        <div>{t("pages.tosContainer.text25")}</div>
        <div>
          {t("pages.tosContainer.text26.1")}
          <NavLink to="/cookies" className="text-darkcyan">
            {t("pages.tosContainer.cookiepolicy")}
          </NavLink>
          {t("pages.tosContainer.text26.2")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.tosContainer.privacypolicy")}
          </NavLink>
          {t("pages.tosContainer.text26.3")}
        </div>
        <h2 className="font-semibold">2. {t("pages.tosContainer.text27")}</h2>
        <h2 className="font-semibold">2.1 {t("pages.tosContainer.text28")}</h2>
        <div>{t("pages.tosContainer.text29")}</div>
        <div>{t("pages.tosContainer.text30")}</div>
        <div>{t("pages.tosContainer.text31")}</div>
        <div>{t("pages.tosContainer.text32")}</div>
        <h2 className="font-semibold">2.2 {t("pages.tosContainer.text33")}</h2>
        <div>{t("pages.tosContainer.text34")}</div>
        <div>{t("pages.tosContainer.text35")}</div>
        <div>
          {t("pages.tosContainer.text36.1")}
          <NavLink to="/community" className="text-darkcyan">
            {t("pages.tosContainer.communitypolicy")}
          </NavLink>
          {t("pages.tosContainer.text36.2")}
        </div>
        <div>{t("pages.tosContainer.text37")}</div>
        <h2 className="font-semibold">2.3 {t("pages.tosContainer.text38")}</h2>
        <div>{t("pages.tosContainer.text39")}</div>
        <div>{t("pages.tosContainer.text40")}</div>
        <div>{t("pages.tosContainer.text41")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.tosContainer.text42")}</li>
          <li>{t("pages.tosContainer.text43")}</li>
          <li>{t("pages.tosContainer.text44")}</li>
          <li>{t("pages.tosContainer.text45")}</li>
          <li>{t("pages.tosContainer.text46")}</li>
        </ul>
        <div>{t("pages.tosContainer.text47")}</div>
        <h2 className="font-semibold">2.4 {t("pages.tosContainer.text48")}</h2>
        <div>{t("pages.tosContainer.text49")}</div>
        <div>{t("pages.tosContainer.text50")}</div>
        <div>{t("pages.tosContainer.text51")}</div>
        <h2 className="font-semibold">2.5 {t("buttons.share")}</h2>
        <div>{t("pages.tosContainer.text52")}</div>
        <div>{t("pages.tosContainer.text53")}</div>
        <div>{t("pages.tosContainer.text54")}</div>
        <h2 className="font-semibold">3.3. {t("pages.tosContainer.text4")}</h2>
        <div>{t("pages.tosContainer.text55")}</div>
        <div>{t("pages.tosContainer.text56")}</div>
        <div>{t("pages.tosContainer.text57")}</div>
        <div>{t("pages.tosContainer.text58")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.tosContainer.text59")}</li>
          <div>
            {t("pages.tosContainer.text60.1")}
            <NavLink to="/datenschutz" className="text-darkcyan">
              {t("pages.tosContainer.privacypolicy")}
            </NavLink>
            {t("pages.tosContainer.text60.2")}
          </div>
          <li>{t("pages.tosContainer.text61")}</li>
          <li>{t("pages.tosContainer.text62")}</li>
          <li>{t("pages.tosContainer.text63")}</li>
        </ul>
        <div>
          {t("pages.tosContainer.text64.1")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.tosContainer.privacypolicy")}
          </NavLink>
          {t("pages.tosContainer.text64.2")}
        </div>
        <div>
          {t("pages.tosContainer.text65.1")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.tosContainer.privacypolicy")}
          </NavLink>
          {t("pages.tosContainer.text65.2")}
        </div>
        <div>{t("pages.tosContainer.text66")}</div>
        <div>
          {t("pages.tosContainer.text67.1")}
          <NavLink to="/urheberrecht" className="text-darkcyan">
            {t("pages.tosContainer.intellectualProperty.1")}
          </NavLink>
          {t("pages.tosContainer.text67.2")}
        </div>
        <h2 className="font-semibold">3.2 {t("pages.tosContainer.text68")}</h2>
        <div>{t("pages.tosContainer.text69")}</div>
        <div>{t("pages.tosContainer.text70")}</div>
        <div>
          {t("pages.tosContainer.text71.1")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.tosContainer.privacypolicy")}
          </NavLink>
          {t("pages.tosContainer.text71.2")}
        </div>
        <h2 className="font-semibold">3.3 {t("pages.tosContainer.text72")}</h2>
        <div>{t("pages.tosContainer.text73")}</div>
        <div>{t("pages.tosContainer.text74")}</div>
        <div>{t("pages.tosContainer.text75")}</div>
        <div>
          {t("pages.tosContainer.text76.1")}
          <NavLink to="/community" className="text-darkcyan">
            {t("pages.tosContainer.communitypolicy")}
          </NavLink>
          {t("pages.tosContainer.text76.2")}
        </div>
        <div>{t("pages.tosContainer.text77")}</div>
        <h2 className="font-semibold">3.4 {t("pages.tosContainer.text78")}</h2>
        <div>{t("pages.tosContainer.text79")}</div>
        <div>
          {t("pages.tosContainer.text80.1")}
          <NavLink to="/community" className="text-darkcyan">
            {t("pages.tosContainer.communitypolicy")}
          </NavLink>
          {t("pages.tosContainer.text80.2")}
        </div>
        <h2 className="font-semibold">3.5 {t("pages.tosContainer.text81")}</h2>
        <div>
          {t("pages.tosContainer.text82.1")}
          <NavLink to="/urheberrecht" className="text-darkcyan">
            {t("pages.tosContainer.intellectualProperty.1")}
          </NavLink>
          {t("pages.tosContainer.text82.2")}
        </div>
        <div>
          {t("pages.tosContainer.text83.1")}
          <NavLink to="/urheberrecht" className="text-darkcyan">
            {t("pages.tosContainer.intellectualProperty.1")}
          </NavLink>
          {t("pages.tosContainer.text83.2")}
        </div>
        <h2 className="font-semibold">3.6 {t("pages.tosContainer.text84")}</h2>
        <div>{t("pages.tosContainer.text85")}</div>
        <div>{t("pages.tosContainer.text86")}</div>
        <h2 className="font-semibold">4. {t("pages.tosContainer.text5")}</h2>
        <h2 className="font-semibold">4.1 {t("pages.tosContainer.text87")}</h2>
        <div>{t("pages.tosContainer.text88")}</div>
        <div>{t("pages.tosContainer.text89")}</div>
        <h2 className="font-semibold">4.2 {t("pages.legalNoticeContainer.text8")}</h2>
        <div>{t("pages.tosContainer.text91")}</div>
        <div>{t("pages.tosContainer.text92")}</div>
        <div>{t("pages.tosContainer.text93")}</div>
        <h2 className="font-semibold">4.3 {t("pages.tosContainer.text94")}</h2>
        <div>{t("pages.tosContainer.text95")}</div>
        <div>{t("pages.tosContainer.text96")}</div>
        <h2 className="font-semibold">5. {t("pages.tosContainer.text97")}</h2>
        <div>{t("pages.tosContainer.text98")}</div>
        <div>{t("pages.tosContainer.text99")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.tosContainer.text100")}</li>
          <li>{t("pages.tosContainer.text101")}</li>
          <li>{t("pages.tosContainer.text102")}</li>
          <li>{t("pages.tosContainer.text103")}</li>
        </ul>
        <div>{t("pages.tosContainer.text104")}</div>
        <h2 className="font-semibold">6. {t("pages.tosContainer.text105.1")}</h2>
        <div>{t("pages.tosContainer.text105.2")}</div>
        <div>{t("pages.tosContainer.text106")}</div>
        <div>{t("pages.tosContainer.text107")}</div>
        <h2 className="font-semibold">7. {t("pages.conditionContainer.header")}</h2>
        <div>{t("pages.tosContainer.text108")}</div>
        <div>{t("pages.tosContainer.text109")}</div>
        <div>{t("pages.tosContainer.text110")}</div>
        <div>{t("pages.tosContainer.text111")}</div>
        <div>{t("pages.tosContainer.text112")}</div>
        <h2 className="font-semibold">8. {t("pages.tosContainer.text113")}</h2>
        <div>
          {t("pages.tosContainer.text114.1")}
          <NavLink to="/community" className="text-darkcyan">
            {t("pages.tosContainer.communitypolicy")}
          </NavLink>
          {t("pages.tosContainer.text114.2")}
        </div>
        <h2 className="font-semibold">8.1. {t("pages.tosContainer.text115")}</h2>
        <h2 className="font-semibold">{t("pages.tosContainer.text116")}</h2>
        <ul>
          <li>
            {t("pages.tosContainer.text117.1")}
            {t("pages.tosContainer.intellectualProperty.3")}
            {t("pages.tosContainer.text117.2")}
          </li>
          <li>{t("pages.tosContainer.text118")}</li>
          <li>{t("pages.tosContainer.text119")}</li>
          <li>{t("pages.tosContainer.text120")}</li>
        </ul>
        <h2 className="font-semibold">8.2. {t("pages.tosContainer.text121.1")}</h2>
        <h2 className="font-semibold">{t("pages.tosContainer.text121.2")}</h2>
        <ul>
          <li>{t("pages.tosContainer.text122")}</li>
          <li>{t("pages.tosContainer.text123")}</li>
          <li>{t("pages.tosContainer.text124")}</li>
          <li>{t("pages.tosContainer.text125")}</li>
          <li>{t("pages.tosContainer.text126")}</li>
          <div>
            {t("pages.tosContainer.text127.1")}
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.tosContainer.intellectualProperty.1")}
            </NavLink>
            {t("pages.tosContainer.text127.2")}
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.tosContainer.copyright")}
            </NavLink>
            {t("pages.tosContainer.text127.3")}
          </div>
          <div>
            {t("pages.tosContainer.text128.1")}
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.tosContainer.intellectualProperty.2")}
            </NavLink>
            {t("pages.tosContainer.text128.2")}
          </div>
          <li>{t("pages.tosContainer.text129")}</li>
          <li>{t("pages.tosContainer.text130")}</li>
          <li>{t("pages.tosContainer.text131")}</li>
          <li>{t("pages.tosContainer.text132")}</li>
          <li>{t("pages.tosContainer.text133")}</li>
          <li>{t("pages.tosContainer.text134")}</li>
          <li>{t("pages.tosContainer.text135")}</li>
          <li>{t("pages.tosContainer.text136")}</li>
          <li>{t("pages.tosContainer.text137")}</li>
          <li>{t("pages.tosContainer.text138")}</li>
          <li>
            {t("pages.tosContainer.text139.1")}
            <NavLink to="/community" className="text-darkcyan">
              {t("pages.tosContainer.communitypolicy")}
            </NavLink>
            {t("pages.tosContainer.text139.2")}
          </li>
        </ul>
        <h2 className="font-semibold">9. {t("pages.tosContainer.text10")}</h2>
        <div>{t("pages.tosContainer.text140")}</div>
        <div>
          {t("pages.tosContainer.text141.1")}
          <NavLink to="/urheberrecht" className="text-darkcyan">
            {t("pages.tosContainer.intellectualProperty.1")}
          </NavLink>
          {t("pages.tosContainer.text141.2")}
          <NavLink to="/urheberrecht" className="text-darkcyan">
            {t("pages.tosContainer.intellectualProperty.1")}
          </NavLink>
          {t("pages.tosContainer.text141.3")}
        </div>
        <h2 className="font-semibold">10. {t("pages.tosContainer.text142")}</h2>
        <div>
          {t("pages.tosContainer.text143.1")}
          <NavLink to="/impressum" className="text-darkcyan">
            {t("pages.tosContainer.contactInfo")}
          </NavLink>
          {t("pages.tosContainer.text143.2")}
          <NavLink to="/help" className="text-darkcyan">
            {t("pages.tosContainer.helpCenter")}
          </NavLink>
          {t("pages.tosContainer.text143.3")}
        </div>
        <div>
          {t("pages.tosContainer.text144.1")}
          <NavLink to="/support" className="text-darkcyan">
            {t("pages.tosContainer.contact")}
          </NavLink>
          {t("pages.tosContainer.text144.2")}
          <NavLink to="/impressum" className="text-darkcyan">
            {t("pages.tosContainer.imprint")}
          </NavLink>
          {t("pages.tosContainer.text144.3")}
        </div>
      </div>
    </>
  );
};

export default TermsOfUseContainer;
