import { t } from "i18next";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";

import {
  categoryData,
  customerDemographicsData,
  customerDemographicsOptions,
  inventoryLevelData,
  inventoryLevelOptions,
  orderStatusData,
  revenueDistributionData,
  revenueGrowthData,
  revenueGrowthOptions,
  salesChannelData,
  salesChannelOptions,
  salesTrendData,
  topSellingData,
  topSellingOptions,
} from "@src/data/statisticsData";
import Products from "@src/mocks/AllProducts.json";
import Product from "@src/pages/marketplace/components/Product";
import MarketplaceCarousels from "@src/pages/marketplace/shop/MarketplaceCarousels";

const StatisticsContainer = () => {
  return (
    <div className="container">
      <div className="mb-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-blue-300 bg-blue-100 p-4 text-center">
          <h3 className="text-lg font-medium text-blue-700">
            {t("main.marketplace.tabs.totalExpenditure")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-blue-900">€{"2000"}</p>
        </div>

        <div className="rounded-lg border border-green-300 bg-green-100 p-4 text-center">
          <h3 className="text-lg font-medium text-green-700">
            {t("main.marketplace.tabs.totalOders")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-green-900">{"3"}</p>
        </div>

        <div className="rounded-lg border border-yellow-300 bg-yellow-100 p-4 text-center">
          <h3 className="text-lg font-medium text-yellow-700">
            {t("main.marketplace.tabs.avarageOderValue")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-yellow-900">€{"200"}</p>
        </div>

        <div className="rounded-lg border border-purple-300 bg-purple-100 p-4 text-center">
          <h3 className="text-lg font-medium text-purple-700">
            {t("main.marketplace.tabs.recentPurchase")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-purple-900">4</p>
        </div>
      </div>
      <div className="mb-5 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.salesTrend")}</h2>
          <Line data={salesTrendData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.productCategories")}</h2>
          <Bar data={categoryData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">
            {t("main.shop.details.revenueDistribution")}
          </h2>
          <Doughnut data={revenueDistributionData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.orderStatus")}</h2>
          <Pie data={orderStatusData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">
            {t("main.shop.details.customerDemographics")}
          </h2>
          <Bar data={customerDemographicsData} options={customerDemographicsOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.salesChannels")}</h2>
          <Bar data={salesChannelData} options={salesChannelOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.inventoryLevel")}</h2>
          <Bar data={inventoryLevelData} options={inventoryLevelOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.revenueGrowth")}</h2>
          <Bar data={revenueGrowthData} options={revenueGrowthOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.topSellingProduct")}</h2>
          <Bar data={topSellingData} options={topSellingOptions} />
        </div>
      </div>
      <MarketplaceCarousels />
      <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
        {Products.allProducts.map(product => (
          <div key={product.ID} className="flex justify-center">
            <Product product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticsContainer;
