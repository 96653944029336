import { useTranslation } from "react-i18next";

import Panel from "@src/components/box/Panel";
import MyProfileWidget from "@src/components/elements/MyProfileWidget";

type Props = {
  small?: boolean;
};
const SideBarMenu = ({ small }: Props) => {
  const { t } = useTranslation();

  return (
    <Panel
      title={t("main.panel.profile")}
      mobileBorder={false}
      noBorder={small}
      transparent={small}
    >
      <MyProfileWidget />
    </Panel>
  );
};

export default SideBarMenu;
