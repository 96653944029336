import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

import Input from "@src/components/elements/input/Input";

type Props = {
  label?: string;
  values?: Array<string>;
  name: string;
  placeholder?: string;
  addText?: string;
};

const Tags = ({
  label,
  name,
  placeholder = "neuer Text",
  values = [],
  addText = "hinzufügen",
}: Props) => {
  /**
   * Remove tag
   * @param index
   */
  const removeTag = (index: number) => {
    const newTags: Array<string> = JSON.parse(JSON.stringify(values));
    newTags.splice(index, 1);
  };

  /**
   * Add tag
   * @param tag
   */
  const addTag = (tag: string) => {
    const newTags: Array<string> = JSON.parse(JSON.stringify(values));
    newTags.push(tag);
  };

  /**
   * Change tag
   * @param text
   * @param index
   */
  const changeTag = (text: string, index: number) => {
    const newTags: Array<string> = JSON.parse(JSON.stringify(values));
    newTags[index] = text;
  };

  return (
    <div>
      {label && (
        <label htmlFor={name} className="mb-1 block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="flex flex-wrap gap-2 rounded-2xl border border-gray-300 px-3 py-2 shadow-sm sm:text-sm">
        {values.map((tag: string, index: number) => {
          return (
            <div
              contentEditable={true}
              key={tag}
              onInput={e => changeTag(e.currentTarget.textContent ?? "", index)}
              className="group inline-flex w-fit min-w-10 items-center gap-1 rounded-2xl px-2 py-0.5 outline-darkblue ring-1 ring-gray-400 focus:border-darkblue focus:ring-darkblue"
              suppressContentEditableWarning={true}
            >
              {tag}
              <button
                onClick={() => {
                  removeTag(index);
                }}
                className="ml-auto hidden outline-none group-hover:block"
              >
                <XCircleIcon className="size-4 text-red-600" />
              </button>
            </div>
          );
        })}
        <button
          className="inline-flex w-fit items-center gap-1 rounded-2xl px-2 py-0.5 outline-darkblue ring-1 ring-gray-400 focus:border-darkblue focus:ring-darkblue"
          onClick={() => {
            addTag(placeholder);
          }}
        >
          {addText}
          <PlusCircleIcon className="size-4 text-green-600" />
        </button>
      </div>
      <Input name={name} type="hidden" value={JSON.stringify(values)} />
    </div>
  );
};

export default Tags;
