import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserType } from "@src/types/UserType";

interface UserState {
  user: UserType | null;
  token: string | null;
  refreshToken: string | null;
}

const initialState: UserState = {
  user: null,
  token: null,
  refreshToken: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<UserType>) => {
      state.user = payload;
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    setRefreshToken: (state, { payload }: PayloadAction<string>) => {
      state.refreshToken = payload;
    },
    signOut: state => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export const { setUser, setToken, setRefreshToken, signOut } = userSlice.actions;

export default userSlice.reducer;
