const checkHideLinkText = (lexicalObjectRaw: string) => {
  try {
    const lexicalObject = JSON.parse(lexicalObjectRaw);
    if (lexicalObject["root"]["children"].length !== 1) return false;
    if (lexicalObject["root"]["children"][0]["children"].length !== 1) return false;
    return (
      lexicalObject["root"]["children"][0]["children"][0]["type"] === "link" ||
      lexicalObject["root"]["children"][0]["children"][0]["type"] === "autolink"
    );
  } catch {
    return false;
  }
};

export default checkHideLinkText;
