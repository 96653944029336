import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import ExternalLink from "@src/components/elements/shared/ExternalLink";
import BackButton from "@src/components/nav/BackButton";

const CopyRightContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <h1 className="text-left text-smbase font-bold">{t("pages.copyrightContainer.text1")}</h1>
        <div>{t("pages.copyrightContainer.text2")} 26. März 2024</div>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text3")}</h2>
        <div>
          {t("pages.copyrightContainer.text4.1")}
          <NavLink
            to="/nutzungsbedingungen"
            className="text-darkcyan"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("pages.privacyContainer.userAgreement")}
          </NavLink>
          {t("pages.copyrightContainer.text4.2")}
        </div>
        <div>{t("pages.copyrightContainer.text5")}</div>
        <div>{t("pages.copyrightContainer.text6")}</div>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text7")}</h2>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text8")}</h2>
        <div>
          {t("pages.copyrightContainer.text9.1")}
          <ExternalLink to="https://www.dmca.com/">
            {t("pages.copyrightContainer.text9.2")}
          </ExternalLink>
          {t("pages.copyrightContainer.text9.3")}
          <NavLink
            to="/support"
            target="_blank"
            rel="noopener noreferrer"
            className="text-darkcyan"
          >
            {t("pages.copyrightContainer.text9.4")}
          </NavLink>
          {t("pages.copyrightContainer.text9.5")}
        </div>
        <ul className="list-inside list-decimal">
          <li>{t("pages.copyrightContainer.text10")}</li>
          <li>{t("pages.copyrightContainer.text11")}</li>
          <li>{t("pages.copyrightContainer.text12")}</li>
          <li>{t("pages.copyrightContainer.text13")}</li>
          <li>{t("pages.copyrightContainer.text14")}</li>
          <li>{t("pages.copyrightContainer.text15")}</li>
        </ul>
        <div>{t("pages.copyrightContainer.text16")}</div>
        <div>
          {t("pages.copyrightContainer.text17.1")}
          <NavLink
            to="/support"
            className="text-darkcyan"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("pages.copyrightContainer.text17.2")}
          </NavLink>
          {t("pages.copyrightContainer.text17.3")}
        </div>
        <div>{t("pages.copyrightContainer.text18")}</div>
        <div className="flex flex-col gap-1">
          <div>André Fischer</div>
          <div>SCC Group</div>
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>Telefon: +49 (0) 371 / 432 32 05 82</div>
          <div>E-Mail: datenschutz@tradefoox.com</div>
          <div>{t("pages.copyrightContainer.text19")}</div>
        </div>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text20")}</h2>
        <div>
          {t("pages.copyrightContainer.text21.1")}
          <ExternalLink to="https://www.dmca.com/">
            {t("pages.copyrightContainer.text21.2")}
          </ExternalLink>
          {t("pages.copyrightContainer.text21.3")}
        </div>
        <ul className="list-inside list-decimal">
          <li>{t("pages.copyrightContainer.text22")}</li>
          <li>{t("pages.copyrightContainer.text23")}</li>
          <li>{t("pages.copyrightContainer.text24")}</li>
          <li>{t("pages.copyrightContainer.text25")}</li>
          <li>
            {t("pages.copyrightContainer.text26.1")}
            <NavLink
              to="/support"
              target="_blank"
              rel="noopener noreferrer"
              className="text-darkcyan"
            >
              {t("pages.copyrightContainer.text26.2")}
            </NavLink>
            {t("pages.copyrightContainer.text26.3")}
          </li>
        </ul>
        <h2 className="font-semibold">{t("pages.copyrightContainer.text27")}</h2>
        <div>
          {t("pages.copyrightContainer.text28.1")}
          <NavLink to="/help" className="text-darkcyan" target="_blank" rel="noopener noreferrer">
            {t("pages.copyrightContainer.text28.2")}
          </NavLink>
          {t("pages.copyrightContainer.text28.3")}
        </div>
        <ul className="list-inside list-decimal">
          <li>
            <NavLink
              to="/nutzungsbedingungen"
              className="text-darkcyan"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("pages.copyrightContainer.text29")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/marken_patentrecht"
              className="text-darkcyan"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("pages.copyrightContainer.text30")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/community"
              className="text-darkcyan"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("pages.copyrightContainer.text31")}
            </NavLink>
          </li>
        </ul>
        <div>{t("pages.copyrightContainer.text32")}</div>
      </div>
    </>
  );
};

export default CopyRightContainer;
