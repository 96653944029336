import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SearchConfigState {
  keywords: string;
}

const initialState: SearchConfigState = {
  keywords: "",
};

const searchSlice = createSlice({
  name: "searchConfig",
  initialState,
  reducers: {
    setKeywords: (state, action: PayloadAction<string>) => {
      state.keywords = action.payload;
    },
    clearKeywords: state => {
      state.keywords = "";
    },
  },
});

export const { setKeywords, clearKeywords } = searchSlice.actions;

export default searchSlice.reducer;
