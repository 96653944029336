import { PaperAirplaneIcon, PhotoIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import React, { useState } from "react";

import comment from "@src/api/posts/comment";
import UserWidget from "@src/components/elements/UserWidget";
import CommentTextEdit from "@src/components/elements/posts/form/shared/CommentTextEdit";
import { useAppSelector } from "@src/state/hooks";
import { CommentType } from "@src/types/CommentType";
import getName from "@src/utils/getName";

type Props = {
  postId: string;
  answer?: CommentType;
  onSuccess?: () => void;
  parentId?: string;
};

const CreatePostComment = ({ postId, answer, onSuccess, parentId }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [uploadingCount, setUploadingCount] = useState(0);

  const [text, setText] = useState(
    answer
      ? JSON.stringify({
          root: {
            children: [
              {
                children: [
                  {
                    id: answer.author.id,
                    name: getName(answer.author),
                    type: "mention",
                    version: 1,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "paragraph",
                version: 1,
              },
            ],
            direction: null,
            format: "",
            indent: 0,
            type: "root",
            version: 1,
          },
        })
      : "",
  );
  const [clearTextField, setClearTextField] = useState(false);
  const queryClient = useQueryClient();
  const [emojiOpen, setEmojiOpen] = useState(false);
  const fileInputRef = React.createRef<HTMLInputElement>();

  const commentMutation = useMutation({
    mutationKey: ["create-post-comment"],
    mutationFn: comment,
    onSuccess: () => {
      setText("");
      setClearTextField(true);
      setTimeout(() => {
        setClearTextField(false);
      }, 100);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      queryClient.invalidateQueries({ queryKey: ["comments"] });
      if (onSuccess) onSuccess();
    },
    onError: () => {
      alert("Failed");
    },
  });

  const handleEmojiSelect = (emoji: EmojiClickData) => {
    setText(data => data + String.fromCodePoint(parseInt(emoji.unified, 16)));
  };

  if (!user) {
    return null;
  }

  return (
    <>
      {emojiOpen && <EmojiPicker onEmojiClick={emoji => handleEmojiSelect(emoji)} width="100%" />}

      <div className="flex gap-3">
        {<UserWidget user={user} size={30} />}
        <div className="grow overflow-hidden">
          <div className="flex flex-col rounded-2xl bg-gray-100">
            {!clearTextField && <CommentTextEdit value={text} onChange={setText} />}
            <div className="flex w-full flex-row gap-2 pl-2 pr-4">
              <button onClick={() => setEmojiOpen(value => !value)} className="max-md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="size-5 cursor-pointer rounded-full text-yellow-600 hover:bg-gray-200"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                  ></path>
                </svg>
              </button>
              <button
                onClick={() => {
                  if (fileInputRef.current) fileInputRef.current.click();
                }}
              >
                <PhotoIcon className="size-7 text-darkblue" />
              </button>
              <button
                onClick={() =>
                  commentMutation.mutate({ id: postId, data: { text, parent_id: parentId } })
                }
                className="ml-auto"
              >
                <PaperAirplaneIcon className="ml-auto size-5 text-darkblue" />
              </button>
            </div>

            <input
              type="file"
              className="ml-auto"
              accept="*"
              onChange={e => {
                if (e.target.files && e.target.files.length + uploadingCount > 10) {
                  alert("Max 10 Medien pro Nachricht");
                  e.target.value = "";
                  return;
                }
                for (const _file of e.target.files || []) {
                  setUploadingCount(count => count + 1);
                  // upload.mutate(file);
                }
                e.target.value = "";
              }}
              multiple={true}
              hidden
              ref={fileInputRef}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePostComment;
