import api from "@src/api/api";
import type { UserType } from "@src/types/UserType";

type MeResponse = {
  data: UserType;
};

export default async function me(): Promise<MeResponse> {
  const { data } = await api.get("/api/v1/me");
  return data;
}
