import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AppContext } from "@src/App";
import Alert from "@src/components/elements/Alert";
import Button from "@src/components/elements/input/Button";
import TabLink from "@src/components/elements/shared/tab/TabLink";
import TabMenu from "@src/components/elements/shared/tab/TabMenu";
import AccountDeactivationPage from "@src/pages/account/AccountDeactivationPage";
import ChangeEmailContainer from "@src/pages/auth/ChangeEmailContainer";
import ChangePasswordContainer from "@src/pages/auth/ChangePasswordContainer";
import ChangeUsernameContainer from "@src/pages/auth/ChangeUsernameContainer";
import PushMessagesContainer from "@src/pages/pushmessages/PushMessagesContainer";
import { useAppSelector } from "@src/state/hooks";
import isMobile from "@src/utils/isMobile";

type Props = {
  tab?: string;
};

const AccountEditContainer = ({ tab = "password" }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  if (appContext?.setFullscreen && isMobile) appContext.setFullscreen(true);

  const tabs = [
    { key: "pushmessages", name: t("main.panel.profileWidgets.pushMessages") },
    { key: "passwort", name: t("main.panel.profileWidgets.changePassword") },
    { key: "email", name: t("main.panel.profileWidgets.changeEmail") },
    { key: "username", name: t("main.panel.profileWidgets.changeUsername") },
    { key: "logout", name: t("main.panel.profileWidgets.logout") },
    {
      key: "löschen",
      name: t("main.panel.profileWidgets.deleteAccount"),
    },
  ];

  if (!user) return <span>Loading...</span>;

  return (
    <>
      <div className="px-3">
        <button
          className="flex w-fit items-center gap-4 rounded-full lg:hidden"
          onClick={() => {
            navigate("/news/für_dich");
          }}
        >
          <div className="rounded-full">
            <XMarkIcon className="size-5 text-gray-700" />
          </div>
        </button>
      </div>
      <div className="max-md:p-3">
        <TabMenu>
          {tabs.map((tab: { key: string; name: string }) => {
            return (
              <TabLink key={tab.key} to={"/verwaltung/account/" + tab.key}>
                {tab.name}
              </TabLink>
            );
          })}
        </TabMenu>

        {tab === "pushmessages" && <PushMessagesContainer />}
        {tab === "password" && <ChangePasswordContainer />}
        {tab === "löschen" && <AccountDeactivationPage />}
        {tab === "email" && <ChangeEmailContainer />}

        {tab === "username" && <ChangeUsernameContainer />}

        {tab === "logout" && (
          <div className="mx-auto">
            <Button onClick={() => setShowAlert(true)}>
              {t("main.panel.profileWidgets.logout")}
            </Button>
          </div>
        )}

        {showAlert && (
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            buttons={Alert.ButtonVariants.YesNo}
            variant={Alert.MessageVariants.Info}
            timeout={0}
            message={t("components.shared.logoutQuestion")}
            title="Logout"
            yesClick={() => {
              navigate("/logout");
            }}
          />
        )}
      </div>
    </>
  );
};

export default AccountEditContainer;
