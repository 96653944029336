import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { useState } from "react";

import me from "@src/api/auth/me";
import { UserType } from "@src/types/UserType";

type MutationFn<TData, TVariables> = (variables: TVariables) => Promise<TData>;

const useChangeMutation = <TData, TVariables>(
  mutationFn: MutationFn<TData, TVariables>,
  mutationKey: string,
  options?: {
    onSettled?: () => void;
    onSuccess?: (data: UserType) => void;
    onError?: () => void;
  },
): {
  mutation: UseMutationResult<TData, unknown, TVariables, unknown>;
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess: boolean;
} => {
  const [showAlert, setShowAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const mutation = useMutation<TData, unknown, TVariables>({
    mutationKey: [mutationKey],
    mutationFn,
    onSuccess: () => {
      setIsSuccess(true);
      setShowAlert(true);
      me()
        .then(userData => {
          if (options?.onSuccess) {
            options.onSuccess(userData.data);
          }
        })
        .catch(error => {
          console.error("Error fetching user data:", error);
        });
    },
    onError: () => {
      setIsSuccess(false);
      setShowAlert(true);
      if (options?.onError) {
        options.onError();
      }
    },
    onSettled: () => {
      if (options?.onSettled) {
        options.onSettled();
      }
    },
  });

  return { mutation, showAlert, setShowAlert, isSuccess };
};

export default useChangeMutation;
