import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import React from "react";
import { v4 as uuid } from "uuid";

enum NotificationStyle {
  Announcement,
  Header,
  Toasts,
}

enum NotificationTypes {
  Error = "error",
  Info = "info",
  Warning = "warning",
  Success = "success",
}

interface NotificationsState {
  value: Record<NotificationStyle, Record<string, Notification>>;
}

interface Notification {
  uuid: string;
  title?: string;
  text: string;
  type: NotificationTypes;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  priority: number;
  duration?: number;
}

interface NotificationPayload {
  title?: string;
  text: string;
  type: NotificationTypes;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  style: NotificationStyle;
  priority: number;
  duration?: number;
}

const initialState: NotificationsState = {
  value: {
    [NotificationStyle.Announcement]: {},
    [NotificationStyle.Header]: {},
    [NotificationStyle.Toasts]: {},
  },
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<NotificationPayload>) => {
      const localUuid = uuid();
      state.value[action.payload.style][localUuid] = {
        ...action.payload,
        uuid: localUuid,
      };
    },
    remove: (state, action: PayloadAction<{ uuid: string; style: NotificationStyle }>) => {
      delete state.value[action.payload.style][action.payload.uuid];
    },
  },
});

export default notificationsSlice.reducer;
