import { ArrowPathIcon, ArrowUpOnSquareIcon, HeartIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";

import Button from "@src/components/elements/input/Button";

interface Product {
  title: string;
  imgUrl: string;
  price: string;
  MRP: string;
  ID: string;
  category: string;
  subCategory: string;
  description?: string;
}

interface ProductProps {
  product: Product;
}

const OrderedProduct: React.FC<ProductProps> = ({ product }: ProductProps) => {
  return (
    <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
      <div className="flex flex-row">
        <div className="mr-1">
          <img className="size-[80px] rounded-lg object-contain" src={product.imgUrl} />
        </div>
        <div className="w-full min-w-0 flex-1 space-y-3 md:order-2 md:max-w-md">
          <div className="h-12 overflow-hidden py-0">
            <a
              href={"/marketplace/01fc5aed-d880-460b-bd87-a1cecec3502f"}
              className="text-cyan-700 hover:underline"
            >
              <h3 className="mb-2 h-12 overflow-hidden text-xs font-medium">{product.title}</h3>
            </a>
          </div>

          <div className="flex items-center gap-3">
            <button
              type="button"
              className="inline-flex items-center text-xs font-medium text-gray-500 hover:text-gray-900 hover:underline dark:text-gray-400 dark:hover:text-white"
            >
              <HeartIcon className="size-4" />
              <div className="hidden lg:block"> {t("main.shop.buttons.addToFavourites")}</div>
            </button>
            <button
              type="button"
              className="inline-flex items-center text-xs font-medium text-gray-500 hover:text-gray-900 hover:underline dark:text-gray-400 dark:hover:text-white"
            >
              <ArrowUpOnSquareIcon className="size-4" />
              <div className="hidden lg:block"> {t("buttons.share")}</div>
            </button>
            <button
              type="button"
              className="inline-flex items-center text-xs font-medium text-gray-500 hover:text-gray-900 hover:underline dark:text-gray-400 dark:hover:text-white"
            >
              <ArrowPathIcon className="size-4" />
              <div className="hidden lg:block"> {t("main.marketplace.tabs.buyAgain")}</div>
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between md:order-3 md:justify-end">
        <div className="w-full flex-col md:w-[150px]">
          <Button
            onClick={() => {
              console.log("click");
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.Cyan}
          >
            <p className="text-xs">{t("pages.paymentAndOrder.trackOrder")}</p>
          </Button>
          <Button
            onClick={() => {
              console.log("click");
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.White}
          >
            <p className="text-xs"> {t("pages.paymentAndOrder.productReview")}</p>
          </Button>
          <Button
            onClick={() => {
              console.log("click");
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.White}
          >
            <p className="text-xs">{t("pages.paymentAndOrder.contactSeller")}</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderedProduct;
