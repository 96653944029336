import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import UserWidgetFollowButton from "@src/components/elements/UserWidgetFollowButton";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import FullScreenImage from "@src/components/elements/shared/FullScreenImage";
import { useAppSelector } from "@src/state/hooks";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";
import { UserType } from "@src/types/UserType";
import getName from "@src/utils/getName";

type Props = {
  user: UserType;
  follow?: boolean;
  showName?: boolean;
  sideName?: boolean;
  online?: boolean;
  size?: number;
  verified?: boolean;
  key?: string;
  nameStyle?: string;
  avatarFullscreen?: boolean;
  showBirthday?: boolean;
  showInfoBubble?: boolean;
};

export const UserAvatar = ({
  size,
  placeholder,
  user,
  sideName = false,
  openAvatar,
  setOpenAvatar,
  online,
  followed,
  userData,
  verified,
  showName,
  nameStyle,
}: {
  size: number;
  placeholder: string;
  user: UserType;
  sideName?: boolean;
  openAvatar: boolean;
  setOpenAvatar: () => void;
  online: boolean;
  followed: boolean;
  userData: UserType | null;
  verified: boolean;
  showName: boolean;
  nameStyle?: string;
}) => {
  return (
    <>
      <div
        className={`relative shrink-0 rounded-full border border-gray-300 bg-white ${!sideName && "mx-auto"}`}
        style={{
          width: `${size.toString()}px`,
          height: `${size.toString()}px`,
        }}
      >
        <div className="flex size-full items-center justify-center overflow-hidden rounded-full">
          <img
            className="w-full"
            src={user.avatar_url ? user.avatar_url : placeholder}
            alt={getName(user)}
          />
          {!!openAvatar && (
            <FullScreenImage
              onClose={() => setOpenAvatar}
              imageUrl={user.avatar_url ? user.avatar_url : placeholder}
            />
          )}
        </div>
        {online && (
          <div className="absolute bottom-[2%] right-[2%] size-1/4 rounded-full border-white bg-green-500" />
        )}
        {!followed && userData && <UserWidgetFollowButton user={user} me={userData} size={size} />}
      </div>
      {showName && (
        <div
          className={`flex h-4 w-full items-center justify-center gap-1 truncate whitespace-nowrap px-2 text-left text-xs text-gray-600 ${!sideName && "mt-2"} ${sideName && "h-[50px] justify-start pl-3 text-center text-sm font-bold leading-[50px]"}`}
        >
          <div
            className={`truncate ${verified ? "max-w-[calc(100%-16px)]" : "w-full"} ${!sideName ? "text-center" : "text-left"} ${nameStyle}`}
          >
            {getName(user)}
          </div>
          {verified && <VerifyBadge type={user.type} />}
        </div>
      )}
    </>
  );
};

const UserWidget = ({
  user,
  showName = false,
  follow = true,
  online = true,
  verified = false,
  showBirthday = false,
  showInfoBubble = true,
  nameStyle,
  ...props
}: Props) => {
  const { user: userData } = useAppSelector(state => state.user);
  const self = userData && userData.id === user.id;
  const followed = (self || !follow || user.followed) ?? false;
  online = online && true;
  const size = props.size ?? 50;
  const placeholder = `/images/placeholder/${user.type}.png`;
  const [openAvatar, setOpenAvatar] = useState(false);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLAnchorElement>(null);
  const userBubbleModal = useUserBubbleModal();

  const handleMouseOver = () => {
    if (containerRef.current && showInfoBubble) {
      const rect = containerRef.current.getBoundingClientRect();
      userBubbleModal.open({
        position: { x: rect.x, y: rect.y },
        user,
      });
    }
  };

  return (
    <NavLink
      to={`/profile/${user.id}`}
      onClick={e => {
        userBubbleModal.close();
        if (props.avatarFullscreen) {
          setOpenAvatar(!openAvatar);
          e.preventDefault();
        }
      }}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => {
        userBubbleModal.close();
      }}
      ref={containerRef}
    >
      <div
        className={`relative w-full cursor-pointer ${props.sideName && "flex"} ${props.avatarFullscreen && "pointer-events-auto"} `}
      >
        <UserAvatar
          followed={followed}
          online={online}
          openAvatar={openAvatar}
          setOpenAvatar={() => setOpenAvatar(false)}
          placeholder={placeholder}
          sideName={props.sideName}
          size={size}
          user={user}
          userData={userData}
          verified={verified}
          showName={showName}
          nameStyle={nameStyle}
        />
      </div>
      {showBirthday && (
        <div className="ml-10">
          <p className="ml-5 truncate text-sm text-gray-500 dark:text-gray-400">
            {t("main.panel.profileWidgets.birthday", {
              date: moment(user.person?.birthday).format("DD-MM-YYYY"),
            })}
          </p>
        </div>
      )}
    </NavLink>
  );
};

export default UserWidget;
