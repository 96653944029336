import api, { RequestWithId } from "@src/api/api";
import { ChatType } from "@src/types/ChatType";

type CreateRequest = RequestWithId;

type CreateResponse = {
  data: ChatType;
};

export default async function create(request: CreateRequest): Promise<CreateResponse> {
  const { data } = await api.get(`/api/v1/chats/create/${request.id}`);
  return data;
}
