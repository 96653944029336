import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { t } from "i18next";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 24,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  table: {
    width: "auto",
    margin: "10px 0",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
});

interface InvoiceData {
  billingNo: string;
  billingName: string;
  email: string;
  billingAddress1: string;
  billingAddress2: string;
  billingAddress3: string;
  date: string;
  paymentMethod: string;
  total: number;
  items: { name: string; quantity: number; price: number }[];
}

interface InvoiceProps {
  invoiceData: InvoiceData;
}

const PDFInvoice = ({ invoiceData }: InvoiceProps) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>{t("main.shop.price.invoice")}</Text>
        <Text style={styles.text}>{invoiceData.billingName}</Text>
        <Text style={styles.text}>{invoiceData.billingAddress1}</Text>
        <Text style={styles.text}>{invoiceData.billingAddress2}</Text>
        <Text style={styles.text}>{invoiceData.billingAddress3}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>{t("main.shop.price.item")}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("main.shop.price.item")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("main.shop.price.quantity")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}> {t("main.shop.price.rate")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}> {t("pages.marketplace.amount")}</Text>
            </View>
          </View>
          {invoiceData.items.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>€{item.price.toFixed(2)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>€{(item.quantity * item.price).toFixed(2)}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>
          {t("main.shop.price.subtotal")}: €{invoiceData.total.toFixed(2)}
        </Text>
      </View>
    </Page>
  </Document>
);

const DownloadLink = ({ invoiceData }: InvoiceProps) => (
  <PDFDownloadLink document={<PDFInvoice invoiceData={invoiceData} />} fileName="invoice.pdf">
    {({ loading }) => (loading ? t("main.loading") : t("pages.messenger.download"))}
  </PDFDownloadLink>
);

export default DownloadLink;
