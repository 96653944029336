import api, { RequestWithData } from "@src/api/api";

type ResetData = {
  email: string;
};

type ResetRequest = RequestWithData<ResetData>;

export default async function reset(request: ResetRequest): Promise<void> {
  const { data } = await api.post("/api/v1/auth/reset", request.data);
  return data;
}
