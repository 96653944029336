import api from "@src/api/api";
import { AssignmentType } from "@src/types/AssignmentType";

type AssignmentsResponse = {
  data: AssignmentType[];
};

export default async function assignments(): Promise<AssignmentsResponse> {
  const { data } = await api.get("/api/v1/assignments");
  return data;
}
