import { useDispatch, useSelector } from "react-redux";

import { clearKeywords, setKeywords } from "@src/state/modal/searchSlice";
import { AppDispatch, RootState } from "@src/state/store";

const useSearch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const keywords = useSelector((state: RootState) => state.search.keywords);

  const onChange = (newValue: string) => {
    dispatch(setKeywords(newValue));
  };

  const clear = () => {
    dispatch(clearKeywords());
  };

  return { keywords, onChange, clear };
};

export default useSearch;
