import api, { RequestWithParams, requestParams } from "@src/api/api";

type ActivateParams = {
  code: string;
};

type ActivateRequest = RequestWithParams<ActivateParams>;

export default async function activate(request: ActivateRequest): Promise<void> {
  const { data } = await api.get(`/api/v1/activate${requestParams(request.params)}`);
  return data;
}
