import { useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

import Input from "@src/components/elements/input/Input";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";

const MessageFormButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <>
      <ModalWrapper
        onCancel={() => {
          setOpen(false);
        }}
        open={open}
      >
        <div className="flex flex-col gap-2">
          <Input type="text" name="Name" placeholder={t("main.navLinks.jobApplicationForm.name")} />
          <Input
            type="text"
            name="Email"
            placeholder={t("main.navLinks.jobApplicationForm.email")}
          />
          <Input
            type="Text"
            name="Phone"
            placeholder={t("main.navLinks.jobApplicationForm.phone")}
          />

          <TextareaAutosize
            minRows={3}
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder={t("main.navLinks.jobApplicationForm.description")}
            maxLength={250}
            className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
          />
          <div className="flex flex-col gap-1">
            <div className="text-left text-sm">
              {t("main.navLinks.jobApplicationForm.motivaton")}
            </div>
            <input
              type="file"
              accept="image/*"
              className="mb-0.5 truncate border-b border-gray-400 px-1 py-0.5 text-left text-xs"
              multiple
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-left text-sm">{t("main.navLinks.jobApplicationForm.cv")}</div>
            <input
              type="file"
              accept="image/*"
              className="mb-0.5 truncate border-b border-gray-400 px-1 py-0.5 text-left text-xs"
              multiple
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-left text-sm">
              {t("main.navLinks.jobApplicationForm.othersDocuments")}
            </div>
            <input
              type="file"
              accept="image/*"
              className="mb-0.5 truncate border-b border-gray-400 px-1 py-0.5 text-left text-xs"
              multiple
            />
          </div>
        </div>
      </ModalWrapper>

      <button
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("pages.watch.text59")}
      </button>
    </>
  );
};

export default MessageFormButton;
