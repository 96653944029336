import iFollow from "@src/api/contacts/iFollow";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import { UserType } from "@src/types/UserType";

type Props = {
  searchQuery?: string;
};

const ContactIFollowContainer = ({ searchQuery }: Props) => {
  const { result: iFollowResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "i-follow", searchQuery],
    queryFn: async ({ pageParam }) => iFollow({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {iFollowResult.data?.pages.map(page =>
          page.data.map((item: UserType) => (
            <ContactItem key={item.id} mode="default" user={item} />
          )),
        )}
      </div>
    </>
  );
};

export default ContactIFollowContainer;
