import api, { RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type IFollowParams = {
  page?: number;
  q?: string;
};

type IFollowRequest = RequestWithParams<IFollowParams>;

type IFollowResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function iFollow(request: IFollowRequest): Promise<IFollowResponse> {
  const { data } = await api.get(
    `/api/v1/contacts/i_follow${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
