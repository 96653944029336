import Age from "@src/pages/contacts/contacts/statistics/Age";
import CountryCity from "@src/pages/contacts/contacts/statistics/CountryCity";
import Gender from "@src/pages/contacts/contacts/statistics/Gender";
import PageView from "@src/pages/contacts/contacts/statistics/PageView";
import StatisticsItem from "@src/pages/contacts/contacts/statistics/StatisticsItem";
import { UserType } from "@src/types/UserType";

type Props = {
  user: UserType;
};

const PrivateStatisticsContainer = ({ user }: Props) => {
  return (
    <div className="p-4">
      <div className="mb-4 flex flex-col gap-4 md:flex-col lg:flex-row">
        <div className="w-full rounded-lg border bg-white p-4 lg:border lg:shadow-md">
          <StatisticsItem />
        </div>
        <div className="w-full rounded-lg border bg-white p-4 lg:border lg:shadow-md">
          <PageView />
        </div>
      </div>

      <div className="mb-4 flex flex-col gap-4 lg:flex-row">
        <div className="w-full rounded-lg border bg-white p-4 lg:border lg:shadow-md">
          <Age user={user} />
        </div>

        <div className="w-full rounded-lg border bg-white p-4 lg:border lg:shadow-md">
          <Gender user={user} />
        </div>
      </div>
      <div className="mb-4 flex flex-col gap-4 lg:flex-row">
        <CountryCity user={user} />
      </div>
    </div>
  );
};

export default PrivateStatisticsContainer;
