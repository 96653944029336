import api, { RequestWithId, RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type LikesParams = {
  page?: number;
  q?: string;
};

type LikesRequest = RequestWithId & RequestWithParams<LikesParams>;

type LikesResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function likes(request: LikesRequest): Promise<LikesResponse> {
  const { data } = await api.get(
    `/api/v1/users/${request.id}/likes${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
