import api, { RequestWithData } from "@src/api/api";

type RequestData = {
  title: string;
  description: string;
  price_gross: number;
  price_net: number;
  stock: number;
  tax: number;
};

type CreateRequest = RequestWithData<RequestData>;

export default async function create(request: CreateRequest): Promise<void> {
  const { data } = await api.post("/api/v1/marketplace/products", request.data);
  return data;
}
