import api, { RequestWithId } from "@src/api/api";
import { ChatType } from "@src/types/ChatType";

type ChatRequest = RequestWithId;

type ChatResponse = ChatType;

export default async function chat(request: ChatRequest): Promise<ChatResponse> {
  const { data } = await api.get(`/api/v1/chats/${request.id}`);
  return data;
}
