import api, { RequestWithId, RequestWithParams, requestParams } from "@src/api/api";
import { MessageType } from "@src/types/MessageType";

type MessagesParams = {
  q?: string;
};

type MessagesRequest = RequestWithId & RequestWithParams<MessagesParams>;

type MessagesResponse = {
  data: MessageType[];
};

export default async function messages(request: MessagesRequest): Promise<MessagesResponse> {
  const { data } = await api.get(
    `/api/v1/chats/${request.id}/messages${requestParams(request.params)}`,
  );
  return { data };
}
