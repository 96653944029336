import { useTranslation } from "react-i18next";

import BackButton from "@src/components/nav/BackButton";

const HelpContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-6 pb-4 text-justify text-xs text-gray-700">
        <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text01")}</div>
        <div>{t("pages.helpContainer.text02")}</div>
        <div className="text-mini font-bold">{t("pages.helpContainer.text03")}</div>

        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text04")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img alt="" src="/images/support/registrieren.png" />
              <div className="flex flex-col gap-4">
                <div className="text-xs">{t("pages.helpContainer.text06")}</div>
                <ul className="list-disc text-xs">
                  <li>{t("pages.helpContainer.text07")}</li>
                  <li>{t("pages.helpContainer.text08")}</li>
                  <li>{t("pages.helpContainer.text09")}</li>
                  <li>{t("pages.helpContainer.text10")}</li>
                  <li>{t("pages.helpContainer.text11")}</li>
                </ul>
                <div className="text-xs">{t("pages.helpContainer.text12")}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text13")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img alt="" src="/images/support/registrieren.png" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text14")}</div>
                <div className="font-bold">{t("pages.helpContainer.text15")}:</div>
                <div>{t("pages.helpContainer.text16")}</div>
                <div className="font-bold">{t("pages.helpContainer.text17")}:</div>
                <div>{t("pages.helpContainer.text18")}</div>
                <div className="font-bold">{t("pages.helpContainer.text19")}:</div>
                <div>{t("pages.helpContainer.text20")}</div>
                <div>{t("pages.helpContainer.text21")}</div>
                <div>{t("pages.helpContainer.text22")}</div>
                <div>{t("pages.helpContainer.text23")}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text24")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img alt="" src="/images/support/anmeldung.png" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text25")}</div>
                <div>{t("pages.helpContainer.text26")}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text27")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img alt="" src="/images/support/profil_bearbeiten.png" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text28")}</div>
                <div className="font-bold">{t("pages.helpContainer.text30")}</div>
                <ul className="list-disc">
                  <li>{t("pages.helpContainer.text31")}</li>
                  <li>{t("pages.helpContainer.text32")}</li>
                  <li>{t("pages.helpContainer.text33")}</li>
                  <li>{t("pages.helpContainer.text34")}</li>
                  <li>{t("pages.helpContainer.text35")}</li>
                  <li>{t("pages.helpContainer.text36")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text37")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img alt="" src="/images/support/profilvollstaendigkeit.png" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text38")}</div>
                <ul>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text39.1")}</div>
                    <div>{t("pages.helpContainer.text39.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text40.1")}</div>
                    <div>{t("pages.helpContainer.text40.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text41.1")}</div>
                    <div>{t("pages.helpContainer.text41.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text42.1")}</div>
                    <div>{t("pages.helpContainer.text42.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text43.1")}</div>
                    <div>{t("pages.helpContainer.text43.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text44.1")}</div>
                    <div>{t("pages.helpContainer.text44.2")}</div>
                  </li>
                </ul>
                <div>{t("pages.helpContainer.text45")}</div>
                <ul>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text46.1")}</div>
                    <div>{t("pages.helpContainer.text46.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text47.1")}</div>
                    <div>{t("pages.helpContainer.text47.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text48.1")}</div>
                    <div>{t("pages.helpContainer.text48.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text49.1")}</div>
                    <div>{t("pages.helpContainer.text49.2")}</div>
                  </li>
                  <li>
                    <div className="float-left mr-1">{t("pages.helpContainer.text50.1")}</div>
                    <div>{t("pages.helpContainer.text50.2")}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text51")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img alt="" src="/images/support/tradefoox_profil.png" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text52")}</div>
                <div>
                  <div className="font-bold">{t("pages.helpContainer.text53")}</div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text54.1")}</div>
                    <div>{t("pages.helpContainer.text54.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text55.1")}</div>
                    <div>{t("pages.helpContainer.text55.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text56.1")}</div>
                    <div>{t("pages.helpContainer.text56.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text57.1")}</div>
                    <div>{t("pages.helpContainer.text57.2")}</div>
                  </div>
                </div>
                <div>
                  <div className="font-bold">{t("pages.helpContainer.text58")}</div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text59.1")}</div>
                    <div>{t("pages.helpContainer.text59.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text60.1")}</div>
                    <div>{t("pages.helpContainer.text60.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text61.1")}</div>
                    <div>{t("pages.helpContainer.text61.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text62.1")}</div>
                    <div>{t("pages.helpContainer.text62.2")}</div>
                  </div>
                </div>
                <div>
                  <div className="font-bold">{t("pages.helpContainer.text63")}</div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text64.1")}</div>
                    <div>{t("pages.helpContainer.text64.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text65.1")}</div>
                    <div>{t("pages.helpContainer.text65.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text66.1")}</div>
                    <div>{t("pages.helpContainer.text66.2")}</div>
                  </div>
                  <div>
                    <div className="float-left mr-1">{t("pages.helpContainer.text67.1")}</div>
                    <div>{t("pages.helpContainer.text67.2")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpContainer;
