import api, { RequestWithId } from "@src/api/api";
import { ProductType } from "@src/types/ProductType";

type ProductRequest = RequestWithId;

type ProductResponse = {
  data: ProductType;
};

export default async function product(request: ProductRequest): Promise<ProductResponse> {
  const { data } = await api.get(`/api/v1/marketplace/product/${request.id}`);
  return data;
}
