import { AtSymbolIcon, HashtagIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import update from "@src/api/posts/update";
import LegacyLinkPreview from "@src/components/elements/LegacyLinkPreview";
import PopupWrapper from "@src/components/elements/PopupWrapper";
import ProgressCircle from "@src/components/elements/ProgressCircle";
import Button from "@src/components/elements/input/Button";
import { findAutolink } from "@src/components/elements/posts/form/CreatePost";
import PostMedia from "@src/components/elements/posts/form/shared/PostMedia";
import PostTextEdit from "@src/components/elements/posts/form/shared/PostTextEdit";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import { PostType } from "@src/types/PostType";
import blurHandler from "@src/utils/blurHandler";
import resizeHandler from "@src/utils/resizeHandler";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  post: PostType;
};

const EditPost = ({ onSuccess, post, onClose }: Props) => {
  const [adult, setAdult] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [text, setText] = useState(post.text);
  const { t } = useTranslation();
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
      data_url?: string;
    }>
  >(
    post.media.map(m => ({
      id: m.id,
      key: m.key,
      file_name: m.file_name,
      type: m.type,
      thumbnail: m.thumbnail,
      data_url: m.data_url,
    })),
  );
  const queryClient = useQueryClient();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [disabledLink, setDisabledLink] = useState(false);
  const [atClick, setAtClick] = useState<() => void>();
  const [hashtagClick, setHashtagClick] = useState<() => void>();
  const [addMediaClick, setAddMediaClick] = useState<() => void>();
  const [bottomPadding, setBottomPadding] = useState(0);
  const [initHeight, setInitHeight] = useState(window.visualViewport?.height ?? 0);
  const viewPort = window.visualViewport;
  window.visualViewport?.addEventListener("scroll", () => blurHandler(setBottomPadding));
  window.visualViewport?.addEventListener("resize", () =>
    resizeHandler(viewPort, setBottomPadding, setInitHeight),
  );

  let parsed = { root: undefined };

  if (!disabledLink) {
    try {
      parsed = JSON.parse(text);
    } catch {
      /* empty */
    }
  }

  const link = !disabledLink && parsed.root && findAutolink(parsed.root);

  const updateMutation = useMutation({
    mutationKey: ["post"],
    mutationFn: update,
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <PopupWrapper onClose={onClose}>
      <div className="flex flex-col gap-2 max-lg:h-screen max-lg:max-h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:max-w-[600px]">
        <div className="font-semibold">{t("main.feedTabs.feetCreate.editPost")}</div>
        <div className="flex items-center gap-x-3">
          <PostUserSelect />
          <ProgressCircle value={text.length} maxValue={10000} />
        </div>
        {link && (
          <div className="max-h-[200px] overflow-y-auto text-sm scrollbar-thin">
            <LegacyLinkPreview
              link={link}
              onChange={setLinkPreviewId}
              onDelete={() => {
                setLinkPreviewId(undefined);
                setDisabledLink(true);
              }}
              small={false}
            />
          </div>
        )}
        <div
          className="max-h-[300px] max-lg:grow max-lg:overflow-auto"
          style={{
            maxHeight:
              "calc(100% - 200px - " +
              (bottomPadding > 0 && bottomPadding < initHeight ? bottomPadding : 0) +
              "px)",
          }}
        >
          <PostTextEdit
            value={text}
            onChange={setText}
            textRef={textareaRef}
            atClick={{ getFunction: atClick, setFunction: setAtClick }}
            hashtagClick={{
              getFunction: hashtagClick,
              setFunction: setHashtagClick,
            }}
            handleBlur={() => {
              blurHandler(setBottomPadding);
            }}
          />
        </div>
        <PostMedia
          onChange={data => setMedia(value => [...value, data])}
          media={media}
          onDelete={id => setMedia(data => data.filter(item => item.id !== id))}
          addClick={{
            getFunction: addMediaClick,
            setFunction: setAddMediaClick,
          }}
        />
        <div className="flex flex-row items-baseline justify-between">
          <button onClick={atClick}>
            <AtSymbolIcon className="size-6 text-darkblue" />
          </button>
          <button onClick={hashtagClick}>
            <HashtagIcon className="size-6 text-darkblue" />
          </button>
          <button onClick={addMediaClick}>
            <PhotoIcon className="size-6 text-darkblue" />
          </button>
          <div className="max-sm:hidden"></div>

          <div className="w-fit">
            <Button
              onClick={() =>
                updateMutation.mutate({
                  id: post.id,
                  data: {
                    text,
                    adult,
                    media: media.map(m => m.id),
                    link_preview_id: linkPreviewId,
                    link: disabledLink ? undefined : link || "",
                  },
                })
              }
            >
              <PaperAirplaneIcon className="size-5" />
            </Button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default EditPost;
