import api, { RequestWithData } from "@src/api/api";

type CreateData = {
  name: string;
  members: string[];
  avatar?: string;
};

type CreateRequest = RequestWithData<CreateData>;

export default async function create(request: CreateRequest): Promise<void> {
  const { data } = await api.post("/api/v1/chats/groups", request.data);
  return data;
}
