import { useTranslation } from "react-i18next";

import Panel from "@src/components/box/Panel";
import UserWidget from "@src/components/elements/UserWidget";
import { UserType } from "@src/types/UserType";
import getEmployeeInformation from "@src/utils/getEmployeeInformation";

type Props = { user: UserType; minimized?: boolean };
const EmployeeContainer = ({ user, minimized = true }: Props) => {
  const { t } = useTranslation();

  const department = (
    <div className="flex flex-row gap-x-1">
      <div
        dangerouslySetInnerHTML={getEmployeeInformation(user, t, {
          department: true,
        })}
      />
    </div>
  );

  const jobPosition = (
    <div className="flex flex-row gap-x-1">
      <div
        dangerouslySetInnerHTML={getEmployeeInformation(user, t, {
          jobPosition: true,
        })}
      />
    </div>
  );

  const website = (
    <div className="flex flex-row gap-x-1">
      <div
        dangerouslySetInnerHTML={getEmployeeInformation(user, t, {
          website: true,
        })}
      />
    </div>
  );

  const phoneNumber = (
    <div className="flex flex-row gap-x-1">
      <div
        dangerouslySetInnerHTML={getEmployeeInformation(user, t, {
          phoneNumber: true,
        })}
      />
    </div>
  );

  const email = (
    <div className="flex flex-row gap-x-1">
      <div
        dangerouslySetInnerHTML={getEmployeeInformation(user, t, {
          email: true,
        })}
      />
    </div>
  );

  return (
    <div className="flex justify-between">
      <Panel>
        <div>
          <div className="flex flex-col text-sm">
            <UserWidget user={user} showName={true} follow={false} verified={true} />

            {!minimized && (
              <div className="flex w-full flex-col gap-1">
                <div className="flex flex-col justify-between text-xs">
                  <div className="flex flex-row gap-x-1">
                    <div className="truncate"></div>
                  </div>
                  {department}
                  {jobPosition}
                  {website}
                  {phoneNumber}
                  {email}
                </div>
              </div>
            )}
            {minimized && (
              <div>
                {department}
                {jobPosition}
                {website}
                {phoneNumber}
                {email}
              </div>
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default EmployeeContainer;
