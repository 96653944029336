import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import BackButton from "@src/components/nav/BackButton";

type NewspaperType = {
  title: string;
  link: string;
  thumbnail: string;
};

const Newspapers = () => {
  const { t } = useTranslation();

  const localNewspapers: Array<NewspaperType> = [
    {
      title: "Amtsblatt Landkreis Bautzen",
      link: "https://www.landkreis-bautzen.de/von-zeit-zu-zeit-aus-der-arbeit-der-landkreisverwaltung.php",
      thumbnail: "/images/info/amtsblatt_landkreis_bautzen.jpg",
    },
    {
      title: "Amtsblatt Erzgebirkskreis",
      link: "https://www.erzgebirgskreis.de/de/aktuelles/landkreiskurier/",
      thumbnail: "/images/info/amtsblatt_erzgebirgskreis.jpg",
    },
    {
      title: "Amtsblatt Landkreis Görlitz",
      link: "https://www.kreis-goerlitz.de/city_info/webaccessibility/index.cfm?item_id=852940&waid=392",
      thumbnail: "/images/info/amtsblatt_landkreis_goerlitz.jpg",
    },
    {
      title: "Amtsblatt Landkreis Leipzig",
      link: "https://www.landkreisleipzig.de/amtsblatt.html",
      thumbnail: "/images/info/amtsblatt_landkreis_leipzig.jpg",
    },
    {
      title: "Amtsblatt Landkreis Meißen",
      link: "http://www.kreis-meissen.org/60.html",
      thumbnail: "/images/info/amtsblatt_landkreis_meissen.jpg",
    },
    {
      title: "Amtsblatt Landkreis Mittelsachsen",
      link: "https://www.landkreis-mittelsachsen.de/das-amt/neuigkeiten/mittelsachsenkurier.html",
      thumbnail: "/images/info/amtsblatt_mittelsachsen.jpg",
    },
    {
      title: "Amtsblatt Landkreis Sächsische Schweiz",
      link: "https://www.landratsamt-pirna.de/amtsblatt.html",
      thumbnail: "/images/info/amtsblatt_landkreis_saechsische_schweiz_1.jpg",
    },
    {
      title: "Amtsblatt Landkreis Nordsachsen",
      link: "https://www.landkreis-nordsachsen.de/amtsblaetter.html",
      thumbnail: "/images/info/amtsblatt_landkreis_nordsachsen.jpg",
    },
    {
      title: "Amtsblatt Vogtlandkreis",
      link: "https://www.vogtlandkreis.de/B%C3%BCrgerservice-und-Verwaltung/Infos-und-Services/Kreis-Journal-Vogtland/",
      thumbnail: "/images/info/amtsblatt_landkreis_vogtland.jpg",
    },
    {
      title: "Amtsblatt Landkreis Zwickau",
      link: "https://www.landkreis-zwickau.de/aktuelles-amtsblatt",
      thumbnail: "/images/info/amtsblatt_landkreis_zwickau.jpg",
    },
  ];

  const communityNewspapers: Array<NewspaperType> = [
    {
      title: "Amtsblatt Kreisfreie Stadt Chemnitz",
      link: "https://www.chemnitz.de/chemnitz/de/aktuell/publikationen/amtsblatt/2021/28692.html",
      thumbnail: "/images/info/amtsblatt_chemnitz.jpg",
    },
    {
      title: "Amtsblatt Kreisfreie Stadt Dresden",
      link: "https://www.dresden.de/de/rathaus/aktuelles/amtsblatt.php",
      thumbnail: "/images/info/amtsblatt_dresden.jpg",
    },
    {
      title: "Amtsblatt Kreisfreie Stadt Leipzig",
      link: "https://www.leipzig.de/buergerservice-und-verwaltung/stadtverwaltung/amtsblatt/#c226910",
      thumbnail: "/images/info/amtsblatt_leipzig.jpg",
    },
    {
      title: "Altenberger Bote",
      link: "https://www.rathaus-altenberg.de/altenberger-bote/",
      thumbnail: "/images/info/altenberger_bote.jpg",
    },
    {
      title: "Amtsberger Anzeiger",
      link: "https://amtsberg.eu/buergerinfo/?content=108&pkat=14",
      thumbnail: "/images/info/amtsberger_anzeiger.jpg",
    },
    {
      title: "Stadtkurier Zschopau",
      link: "https://www.zschopau.de/buergerservice/stadtkurier",
      thumbnail: "/images/info/amtsblatt_zschopau.jpg",
    },
  ];

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-justify text-xs text-gray-700">
        <section className="relative">
          <img
            src="/images/info/startup_unternehmen_tipps_und_hilfe_tradefoox.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit bg-white/80 px-1 text-2xl text-darkcyan">
              {t("pages.newspaper.text1")}
            </div>
            <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
              {t("pages.newspaper.text2")}
            </div>
          </div>
        </section>

        <article className="text-xs max-xl:px-4">
          <div className="font-semibold">{t("pages.newspaper.text3")}</div>
          <div className="grid grid-flow-row gap-1">
            {t("pages.newspaper.text4")}
            <div />
            {t("pages.newspaper.text5")}
          </div>
        </article>
        <h3 className="text-center font-semibold">{t("pages.newspaper.text6")}</h3>
        <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
          {localNewspapers.map((newspaper: NewspaperType, index) => {
            return (
              <NavLink to={newspaper.link} key={index} target="_blank">
                <div className="group relative h-[225px] overflow-hidden shadow-lg">
                  <img
                    src={newspaper.thumbnail}
                    className="h-[225px] w-full object-cover group-hover:scale-150 group-hover:brightness-50"
                  />
                  <h2 className="absolute top-2 w-full break-words px-2 text-center text-xs font-semibold group-hover:text-base group-hover:font-semibold group-hover:text-gray-100">
                    {newspaper.title}
                  </h2>
                </div>
              </NavLink>
            );
          })}
        </div>
        <h3 className="text-center font-semibold">{t("pages.newspaper.text7")}</h3>
        <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
          {communityNewspapers.map((newspaper: NewspaperType, index) => {
            return (
              <NavLink to={newspaper.link} key={index} target="_blank">
                <div className="group relative h-[225px] overflow-hidden shadow-lg">
                  <img
                    src={newspaper.thumbnail}
                    className="h-[225px] w-full object-cover group-hover:scale-150 group-hover:brightness-50"
                  />
                  <h2 className="absolute top-2 w-full break-words px-2 text-center text-xs font-semibold group-hover:text-base group-hover:font-semibold group-hover:text-gray-100">
                    {newspaper.title}
                  </h2>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Newspapers;
