import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import block, { BlockRequest } from "@src/api/users/block";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import { hideBlockUserModal } from "@src/state/blockUserModal/actions";
import { RootState } from "@src/state/store";
import wait from "@src/utils/wait";

const BlockUserModal = () => {
  const dispatch = useDispatch();
  const blockUserModalState = useSelector((state: RootState) => state.blockUserModal);
  const queryClient = useQueryClient();

  const blockMutation = useMutation({
    mutationKey: ["block"],
    mutationFn: async (request: BlockRequest) => {
      const data = await block(request);
      await wait(2000);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
  });

  const handleClose = () => {
    dispatch(hideBlockUserModal());
    setTimeout(blockMutation.reset, 500);
  };

  return (
    <ModalWrapper
      open={blockUserModalState.isVisible}
      onCancel={handleClose}
      onConfirm={() => blockMutation.mutate({ id: blockUserModalState.userId ?? "" })}
      title="Nutzer Blockieren"
      loading={blockMutation.isPending}
      success={blockMutation.isSuccess}
      failed={blockMutation.isError}
    >
      Möchtest du diesen Benutzer wirklich blockieren?
    </ModalWrapper>
  );
};

export default BlockUserModal;
