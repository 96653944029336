import { HeartIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import StarsRating from "@src/components/elements/input/StarsRating";
import DemoProducts from "@src/mocks/MarketplaceProduct.json";

export const FollowerContainer = () => {
  const demoProducts = DemoProducts.demoProducts;
  const { t } = useTranslation();

  return (
    <div className="relative mt-5 flex flex-col justify-center overflow-hidden">
      <div className="mx-auto w-full">
        <div className="mb-5 grid w-full grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
          {demoProducts.map(product => (
            <div
              key={product._id}
              className="relative flex flex-col overflow-hidden rounded-lg border border-gray-300 shadow-lg transition-all duration-300 hover:translate-y-1 hover:shadow-lg"
            >
              <a
                href={"/marketplace/01fc5aed-d880-460b-bd87-a1cecec3502f"}
                className="absolute right-0 top-2 z-30 mr-3 mt-2 hover:text-orange-600"
              >
                <div className="size-6 rounded-full p-1 text-gray-600">
                  <HeartIcon />
                </div>
              </a>
              <a href="" className="absolute left-0 top-0 z-20 size-full">
                &nbsp;
              </a>
              <div className="h-auto overflow-hidden">
                <div className="relative mt-3 h-32 overflow-hidden">
                  <img src={product.image} alt="" className="size-full object-contain" />
                </div>
              </div>
              <div className="bg-white px-3 py-4">
                <h3 className="mb-2 h-12 overflow-hidden text-xs font-medium">{product.title}</h3>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <StarsRating rating={4.5} />
                    <div className="text-xs text-gray-600">
                      {t("main.shop.details.starsRating", { count: 522 })}
                    </div>
                    <h3 className="font-light">
                      €<span className="text-2xl font-normal">{product.price}</span>
                    </h3>
                  </div>
                  <div className="relative z-40 flex items-center gap-2">
                    <a href="">
                      <ShoppingCartIcon className="size-8" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
