import api, { RequestWithId } from "@src/api/api";
import { JobType } from "@src/types/JobType";

type JobRequest = RequestWithId;

type JobResponse = {
  data: JobType;
};

export default async function job(request: JobRequest): Promise<JobResponse> {
  const { data } = await api.get(`/api/v1/jobs/${request.id}`);
  return data;
}
