import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import apiStatistics from "@src/api/users/statistics";
import { UserType } from "@src/types/UserType";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface StatisticsData {
  genderCount: Record<string, number>;
  genderPercentage: Record<string, number>;
}
type Props = {
  user: UserType;
};

const Gender = ({ user }: Props) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState<StatisticsData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await apiStatistics({ id: user.id });

        setStatistics(data);
      } catch (error) {
        console.error("Error fetching user statistics", error);
      }
    };

    fetchData();
  }, [user]);

  const genderColors: { [key: string]: string } = {
    male: "#18677d",
    female: "#17bdeb",
    diverse: "#0d272e",
  };

  const genderNames: { [key: string]: string } = {
    male: "Männlich",
    female: "Weiblich",
    diverse: "Divers",
  };
  const pieData = {
    labels: Object.keys(statistics?.genderPercentage || {}).map(gender => genderNames[gender]),
    datasets: [
      {
        data: Object.values(statistics?.genderPercentage || {}),
        backgroundColor: Object.keys(statistics?.genderPercentage || {}).map(
          gender => genderColors[gender],
        ),
      },
    ],
  };
  if (!statistics) {
    return <h1>Loading.....</h1>;
  }
  return (
    <div>
      {Object.keys(statistics.genderPercentage).length > 0 && (
        <div>
          <h1 className="mb-2 text-xl font-bold">
            {t("main.profileViewTabs.editProfile.form.gender")}
          </h1>
          <div className="h-72">
            <Doughnut
              data={pieData}
              options={{
                cutout: "60%",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gender;
