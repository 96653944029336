import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import me from "@src/api/auth/me";
import unblock from "@src/api/users/unblock";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import Button from "@src/components/elements/input/Button";
import ContactsFollowButton from "@src/pages/contacts/buttons/ContactsFollowButton";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { useAppSelector } from "@src/state/hooks";
import { UserType } from "@src/types/UserType";
import getName from "@src/utils/getName";

type Props = {
  user: UserType;
  verified?: boolean;
};

const UserDetailsWidget = ({ user, verified = false }: Props) => {
  const { user: meUser } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const placeholder = `/images/placeholder/${user.type}.png`;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [isFollowed, setIsFollowed] = useState(user.followed);

  const meResult = useQuery({
    queryKey: ["me"],
    queryFn: () => me(),
    enabled: !!meUser,
  });

  const unblockMutation = useMutation({
    mutationKey: [`unblock-${user.id}`],
    mutationFn: unblock,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      queryClient.invalidateQueries({ queryKey: ["blocked"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const handleFollowStatusChange = (followed: boolean) => {
    setIsFollowed(followed);
  };

  const self = meResult.isSuccess && meResult.data.data.id === user.id;

  return (
    <div className="flex flex-col gap-1 rounded-xl border bg-white p-2 text-black">
      <div className="flex flex-row px-1 text-base font-semibold text-gray-900 dark:text-white">
        <div>
          <img
            className="size-12 rounded-full"
            src={user?.avatar_url || placeholder}
            alt="Neil image"
          />
        </div>
        <div className="ml-auto w-24">
          {user.blocked ? (
            <Button
              onClick={() => {
                if (user.blocked) {
                  unblockMutation.mutate({ id: user.id });
                } else {
                  dispatch(showBlockUserModal(user.id));
                }
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              <MinusCircleIcon className="size-4 shrink-0" />

              {user.blocked ? t("buttons.unblock") : t("buttons.block")}
            </Button>
          ) : (
            !self && (
              <ContactsFollowButton
                followerId={user.id}
                isFollowed={isFollowed}
                onFollowStatusChange={handleFollowStatusChange}
              />
            )
          )}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="truncate">
          <NavLink to={`/profile/${user.id}`} className="group">
            <div className="flex items-center">
              <div className="ms-2 min-w-0 flex-1">
                <p className="truncate text-sm font-medium text-gray-900 dark:text-black">
                  {(getName(user) || "").length > 30
                    ? `${(getName(user) || "").substring(0, 30)}...`
                    : getName(user)}
                </p>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                  @
                  {(user.username || "").length > 20
                    ? `${(user.username || "").substring(0, 20)}...`
                    : user.username}
                  {verified && <VerifyBadge type={user.type} />}
                </p>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-4 p-2 text-xs">
        <div className="grid grid-rows-2 text-center">
          <div className="font-semibold">
            <NavLink to={"/profile/" + user.id + "/likes"}>{t("main.likes.title")}</NavLink>
          </div>
          {user.like_count}
        </div>

        <div className="grid grid-rows-2 text-center">
          <div className="font-semibold">
            <NavLink to={"/profile/" + user.id + "/follows"}>{t("main.followers.title")}</NavLink>
          </div>
          {user.follower_count}
        </div>

        <div className="grid grid-rows-2 text-center">
          <div className="font-semibold">
            <NavLink to={"/profile/" + user.id + "/following"}>{t("main.following.title")}</NavLink>
          </div>
          {user.following_count}
        </div>
      </div>
    </div>
  );
};

export default UserDetailsWidget;
