import myFollowers from "@src/api/contacts/myFollowers";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import { UserType } from "@src/types/UserType";

type Props = {
  searchQuery?: string;
};

const ContactMyFollowersContainer = ({ searchQuery }: Props) => {
  const { result: myFollowersResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "my-followers", searchQuery],
    queryFn: async ({ pageParam }) => myFollowers({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {myFollowersResult.data?.pages.map(page =>
          page.data.map((item: UserType) => (
            <ContactItem key={item.id} mode="my-followers" user={item} />
          )),
        )}
      </div>
    </>
  );
};

export default ContactMyFollowersContainer;
