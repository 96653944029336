import { HeartIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { Link } from "react-router-dom";

import StarsRating from "@src/components/elements/input/StarsRating";

interface Product {
  title: string;
  imgUrl: string;
  price: string;
  MRP: string;
  ID: string;
  category: string;
  subCategory: string;
  description?: string;
}

interface ProductProps {
  product: Product;
}

const Product: React.FC<ProductProps> = ({ product }: ProductProps) => {
  return (
    <li className="relative list-none rounded-lg border border-gray-300 text-center text-sm">
      <Link to={"/marketplace/01fc5aed-d880-460b-bd87-a1cecec3502f"} className="space-y-2">
        <div className="relative mt-3 flex items-center justify-center">
          <img
            className="mx-auto max-h-[80px] rounded-lg lg:max-h-40"
            src={product.imgUrl}
            alt={product.title}
          />
          <div className="absolute right-0 top-0 mr-3 mt-0 p-1 text-gray-600">
            <HeartIcon className="size-5" />
          </div>
        </div>

        <div className="space-y-1 px-2 pb-2">
          <h2 className="line-clamp-3 text-sm leading-5 md:text-sm md:font-medium">
            {product.title}
          </h2>

          <a className="truncate text-sm text-yellow-500 dark:text-gray-400">
            <StarsRating rating={4} />
          </a>
          <h3 className="font-light">
            €<span className="mr-1 text-2xl font-normal">{product.price}</span>
            <span className="text-gray-500 line-through">€{product.MRP}</span>
          </h3>
          <div className="flex justify-center rounded-lg bg-yellow-400 px-5 py-1 text-center text-sm font-medium text-black hover:bg-yellow-500 focus:ring-4">
            <div className="size-5">
              <ShoppingCartIcon />
            </div>
            <div className="ml-1">{t("main.marketplace.tabs.addToCart")}</div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default Product;
