import { FC } from "react";
import { useTranslation } from "react-i18next";

import Panel from "@src/components/box/Panel";
import SuggestionsHeader from "@src/components/elements/sidebar/suggestions/SuggestionsHeader";
import SuggestionsItem from "@src/components/elements/sidebar/suggestions/SuggestionsItem";

const items = [
  {
    name: "Buch Welpe, Hund, Familienhund, Mischling, Rasse1",
    picture: "/placeholder.png",
    link: "",
  },
  {
    name: "Buch Welpe, Hund, Familienhund, Mischling, Rasse2",
    picture: "/placeholder.png",
    link: "",
  },
  {
    name: "Buch Welpe, Hund, Familienhund, Mischling, Rasse3",
    picture: "/placeholder.png",
    link: "",
  },
];

type Props = { key?: string };

const Suggestions: FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Panel title={t("main.recommendations.title")}>
      <SuggestionsHeader
        text="Marketplace"
        action={t("main.shoppingVenture.sell")}
        link="/marketplace/create"
      />
      {items.map(item => (
        <SuggestionsItem key={item.name} name={item.name} link={item.link} picture={item.picture} />
      ))}
      <SuggestionsHeader
        text={t("main.recommendations.advertisements")}
        action={t("buttons.publish")}
        link="/inserate/create"
      />
      {items.map(item => (
        <SuggestionsItem key={item.name} name={item.name} link={item.link} picture={item.picture} />
      ))}
      <SuggestionsHeader
        text={t("main.recommendations.jobs")}
        action={t("buttons.publish")}
        link="/jobs/create"
      />
      {items.map(item => (
        <SuggestionsItem key={item.name} name={item.name} link={item.link} picture={item.picture} />
      ))}
    </Panel>
  );
};
export default Suggestions;
