import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import BackButton from "@src/components/nav/BackButton";

const MarketingContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="grid grid-flow-row gap-4 text-justify text-xs">
        <div className="text-left text-smbase font-bold">{t("pages.marketingContainer.text1")}</div>
        <p>{t("pages.marketingContainer.text2")}</p>
        <NavLink to="/media/dateien/mediadaten_tradefoox.pdf" className="text-cyan-700">
          {t("pages.marketingContainer.text3")}
        </NavLink>
        <p className="font-bold">{t("pages.marketingContainer.text4")}</p>
        <img src="/images/info/tf-ad-placement.jpg" className="w-full object-contain" />
        <p className="font-bold">{t("pages.marketingContainer.text5")}</p>
        <img src="/images/info/tf-newsletter.jpg" className="w-full object-contain" />
        <p className="font-bold">{t("pages.partner.text1")}</p>
        <p>{t("pages.partner.text2")}</p>
        <p>{t("pages.partner.text4")}:</p>
        <ul className="list-inside list-disc">
          <li>{t("pages.partner.text5")}</li>
          <li>{t("pages.partner.text6")}</li>
          <li>{t("pages.partner.text7")}</li>
          <li>{t("pages.partner.text8")}</li>
          <li>{t("pages.partner.text9")}</li>
        </ul>
        <p className="font-bold">{t("pages.partner.text10")}</p>
        <p>{t("pages.partner.text11")}</p>
        <form>
          <Input placeholder={t("main.contacts.tabs.company")} name="company" />
          <Input placeholder={t("main.groupsInfoForm.country")} name="country" />
          <Input placeholder={t("main.groupsInfoForm.website")} name="website" />
          <Input placeholder={t("main.groupsInfoForm.email")} name="email" />
          <Input placeholder={t("pages.createAccount.text16")} name="message" />
          <Checkbox
            name="privacypolicy"
            onChange={() => {
              console.log("checkeda");
            }}
            checked
          >
            {t("pages.marketingContainer.text6")}
          </Checkbox>
          <Input placeholder="" name="form" />
          <Button
            onClick={() => {
              console.log("");
            }}
            variant={Button.Variants.Transparent}
          >
            {t("buttons.send")}
          </Button>
        </form>
        <p className="text-base">{t("pages.marketingContainer.text7")}:</p>
        <img src="/images/info/tf-partner.jpg" className="w-full object-contain" />
        <p>{t("pages.actGlobal.text1")}</p>
        <p className="text-center font-bold">{t("pages.actGlobal.text2")}</p>
        <p className="-mt-4 text-center">{t("pages.actGlobal.text3")}</p>
        <p className="font-bold">{t("pages.actGlobal.text4")}</p>
        <p className="-mt-4">
          <ul className="list-inside list-disc">
            <li>{t("pages.actGlobal.list1")}</li>
            <li>{t("pages.actGlobal.list2")}</li>
            <li>{t("pages.actGlobal.list3")}</li>
            <li>{t("pages.actGlobal.list4")}</li>
          </ul>
        </p>
        <p className="font-bold">{t("pages.actGlobal.text5")}</p>
        <p className="-mt-4">
          <ul className="list-inside list-disc">
            <li>{t("pages.actGlobal.list5")}</li>
            <li>{t("pages.actGlobal.list6")}</li>
            <li>{t("pages.actGlobal.list7")}</li>
            <li>{t("pages.actGlobal.list8")}</li>
          </ul>
        </p>
        <p className="font-bold">{t("pages.actGlobal.text6")}</p>
        <p className="-mt-4">
          <ul className="list-inside list-disc">
            <li>{t("pages.actGlobal.text7")}</li>
          </ul>
        </p>
        <p className="font-bold">{t("pages.actGlobal.text8")}</p>
        <p className="-mt-4">
          <ul className="list-inside list-disc">
            <li>{t("pages.actGlobal.text9")}</li>
          </ul>
        </p>
        <p className="font-bold">{t("pages.actGlobal.text10")}</p>
        <p className="text-center text-smbase font-bold">{t("pages.actGlobal.text11")}</p>
      </div>
    </>
  );
};

export default MarketingContainer;
