import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import login, { LoginRequest } from "@src/api/auth/login";
import Alert from "@src/components/elements/Alert";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePopupModal from "@src/state/modal/usePopupModal";
import { setRefreshToken, setToken, setUser } from "@src/state/user/userSlice";
import trackFormSubmission from "@src/utils/trackFormSubmission";

const useLoginMutation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const popupModal = usePopupModal();

  return useMutation({
    mutationKey: ["login"],
    mutationFn: (request: LoginRequest) => {
      trackFormSubmission("loginForm");
      return login(request);
    },
    onSuccess: ({ data }) => {
      dispatch(setUser(data.user));
      dispatch(setToken(data.token));
      dispatch(setRefreshToken(data.refreshToken));
      queryClient.invalidateQueries({ queryKey: ["me"] });
      setTimeout(
        () => navigate(data.user.start_page ? `/${data.user.start_page}` : "/news/für_dich"),
        200,
      );
      loginModal.close();
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message={t("main.alerts.loginError")}
          title={t("main.signIn.login")}
        />,
      );
    },
  });
};

export default useLoginMutation;
