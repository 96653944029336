import React from "react";
import { useTranslation } from "react-i18next";

import { CV } from "@src/types/CVType";
import { UserType } from "@src/types/UserType";

const padZero = (num: number) => (num < 10 ? `0${num}` : num.toString());

const ItemList = ({ list, title }: { list: CV[]; title: string }) => {
  if (list.length > 0)
    return (
      <div className="rounded-xl border bg-gray-100">
        <h2 className="pl-4 pt-2 text-lg">{title}</h2>
        {list.map((item, index) => {
          const startDate = new Date(item.start);
          const endDate = new Date(item.end);

          const formattedStartDate = `${padZero(startDate.getDate())}.${padZero(startDate.getMonth() + 1)}.${startDate.getFullYear()}`;
          const formattedEndDate = `${padZero(endDate.getDate())}.${padZero(endDate.getMonth() + 1)}.${endDate.getFullYear()}`;

          return (
            <div
              key={startDate + index.toString()}
              className="flex gap-5 rounded-xl border-gray-300 p-2"
            >
              <div className="rounded-lg border border-gray-300 bg-white p-2 shadow-md">
                <p className="text-sm">
                  {formattedStartDate} - {formattedEndDate}
                </p>
              </div>
              <div className="w-3/4 rounded-lg border border-gray-300 bg-white p-2 shadow-md">
                <p>{item.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  return <></>;
};

const CVList: React.FC<{ user: UserType }> = ({ user }) => {
  const cvs = user.person?.cv || [];
  const educations = user.person?.education || [];

  const { t } = useTranslation();

  if (cvs.length === 0 && educations.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ fontSize: "2em", color: "#18677d", lineHeight: "1.1" }}>
          {t("main.profileViewTabs.editProfile.form.cv.noData")}
        </h1>
        <div
          style={{
            fontSize: "1.2em",
            color: "#555",
            textAlign: "center",
            maxWidth: "500px",
            marginTop: "50px",
          }}
        >
          {t("main.profileViewTabs.editProfile.form.cv.noDataSubtext")}
        </div>
      </div>
    );
  }

  return (
    <>
      <ItemList list={cvs} title={t("main.profileViewTabs.editProfile.form.cv.career")} />
      <ItemList list={educations} title={t("main.profileViewTabs.editProfile.form.cv.training")} />
    </>
  );
};

export default CVList;
