import { NewspaperIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import BackButton from "@src/components/nav/BackButton";
import PostsContainer from "@src/pages/hashtags/PostsContainer";

type Props = {
  tab?: string;
};

const HashtagContainer = ({ tab = "feed" }: Props) => {
  const { hashtag } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-2">
        <div className="truncate font-bold">#{hashtag}</div>
        <Input
          placeholder={t("main.feedTabs.placeholder")}
          name="search_keywords"
          onChange={e => setSearchQuery(e)}
          clearable
        />
        <TabController defaultTab={tab} isSubTab>
          <TabHeaders headerCSS="mb-2" center>
            <TabHeader id="feed" url={"/tags/" + hashtag + "/feed"}>
              <NewspaperIcon className="float-left mr-1.5 size-4" /> {t("main.navLinks.news")}
            </TabHeader>
            <TabHeader id="watch" url={"/tags/" + hashtag + "/watch"}>
              <VideoCameraIcon className="float-left mr-1.5 size-4" /> {t("main.navLinks.watch")}
            </TabHeader>
          </TabHeaders>
          <TabBodies>
            <TabBody id="watch">
              <PostsContainer searchQuery={searchQuery} type="watch" hashtag={hashtag} />
            </TabBody>
            <TabBody id="feed">
              <PostsContainer searchQuery={searchQuery} type="feed" hashtag={hashtag} />
            </TabBody>
          </TabBodies>
        </TabController>
      </div>
    </>
  );
};

export default HashtagContainer;
