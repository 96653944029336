import { TFunction } from "i18next";

import { UserType } from "@src/types/UserType";

type EmployeeOptions = {
  zipCode?: boolean;
  street?: boolean;
  website?: boolean;
  phone_number?: boolean;
  email?: boolean;
  phoneNumber?: boolean;
  country?: boolean;
  department?: boolean;
  jobPosition?: boolean;
};

const getEmployeeInformation = (user: UserType, t: TFunction, options?: EmployeeOptions) => {
  const {
    website = false,
    department = false,
    jobPosition = false,
    phoneNumber = false,
    email = false,
  } = options || {};

  let infoString = "";

  if (department) {
    const departmentValue = user.person?.department;
    if (departmentValue)
      infoString += `<span style="font-weight: bold; font-size: 0.9em; line-height: 0.9;"> ${t("main.profileViewTabs.editProfile.form.department")}:</span> ${departmentValue}`;
  }

  if (jobPosition) {
    const jobPositionValue = user.person?.job_position;
    if (jobPositionValue)
      infoString += ` <span style="font-weight: bold; font-size: 0.9em; line-height: 0.9;">${t("main.profileViewTabs.editProfile.form.position")}:</span> ${jobPositionValue}`;
  }

  if (website) {
    if (user.website)
      infoString += ` <span style="font-weight: bold; font-size: 0.9em; line-height: 0.9;">${t("main.profileViewTabs.editProfile.form.website")}:</span> ${user.website}`;
  }

  if (phoneNumber) {
    if (user.phone_number)
      infoString += ` <span style="font-weight: bold; font-size: 0.9em; line-height: 0.9;">${t("main.profileViewTabs.editProfile.form.phone")}:</span> ${user.phone_number}`;
  }

  if (email) {
    if (user.email)
      infoString += ` <span style="font-weight: bold; font-size: 0.9em; line-height: 0.9;">${t("main.profileViewTabs.editProfile.form.email")}:</span> ${user.email}`;
  }

  return { __html: infoString.trimEnd() };
};

export default getEmployeeInformation;
