import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AvatarState {
  avatar: File | null;
}

const initialState: AvatarState = {
  avatar: null,
};

const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    setAvatar: (state, { payload }: PayloadAction<File>) => {
      state.avatar = payload;
    },
  },
});

export const { setAvatar } = avatarSlice.actions;

export default avatarSlice.reducer;
