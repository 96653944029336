import { AxiosProgressEvent } from "axios";

import api, { RequestWithFile } from "@src/api/api";
import { MediaType } from "@src/types/MediaType";

type UploadRequest = RequestWithFile;

type UploadResponse = {
  data: MediaType;
};

export default async function upload(
  request: UploadRequest,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<UploadResponse> {
  const formData = new FormData();
  formData.append("file", request.file);
  const { data } = await api.post("/api/v1/avatar/upload", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress,
  });
  return data;
}
