import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";

import bookmark from "@src/api/posts/bookmark";
import remove from "@src/api/posts/bookmark/remove";
import TextView from "@src/components/elements/textedit/TextView";
import { WatchType } from "@src/types/WatchType";

type Props = {
  post: WatchType;
  key?: string;
};

const SoundsItem = ({ post }: Props) => {
  const queryClient = useQueryClient();

  const bookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: () => bookmark({ id: post.post_id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const removeMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: () => remove({ id: post.post_id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div className="flex flex-row justify-between gap-2" key={post.media_id}>
      <NavLink
        to={`/watch/für_dich?featured=${post.media_id}`}
        className="aspect-square h-[65px] rounded-xl object-cover"
      >
        <img
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.src = "/placeholder.png";
          }}
          src={post.media_thumbnail}
          className="aspect-square h-[65px] rounded-xl object-cover"
        />
      </NavLink>
      <TextView value={post.post_text} disableTopMargin hashtagType="feed" />
      <button
        onClick={() => {
          if (post.bookmarked) {
            removeMutation.mutate();
          } else {
            bookmarkMutation.mutate();
          }
        }}
      >
        {post.bookmarked ? (
          <BookmarkIconSolid className="my-auto ml-auto size-5 shrink-0 text-darkblue" />
        ) : (
          <BookmarkIcon className="my-auto ml-auto size-5 shrink-0 text-darkblue" />
        )}
      </button>
    </div>
  );
};

export default SoundsItem;
