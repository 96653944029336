import React from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import Products from "@src/mocks/AllProducts.json";
import CartProducts from "@src/pages/marketplace/components/CartProducts";
import Product from "@src/pages/marketplace/components/Product";
import MarketplaceCarousels from "@src/pages/marketplace/shop/MarketplaceCarousels";
import { useAppSelector } from "@src/state/hooks";

const ShoppingCartContainer = () => {
  const { t } = useTranslation();
  const products = Products.allProducts;
  const { cartContent } = useAppSelector(state => state.cart);
  const [_totalPrice, setTotalPrice] = React.useState(0);

  React.useEffect(() => {
    if (cartContent && cartContent.length !== 0) {
      setTotalPrice(
        cartContent
          .map(item => item.price_gross * item.quantity)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      );
    } else {
      setTotalPrice(0);
    }
  }, [cartContent]);

  return (
    <div className="flex flex-col">
      <section className="bg-white antialiased dark:bg-gray-900">
        <div className="2xl:px-0">
          <div className="mt-6 flex flex-col sm:mt-4 md:flex-row md:gap-4">
            <div className="mb-4 w-full md:w-3/5">
              <div className="space-y-4">
                {products.slice(0, 5).map(product => (
                  <div
                    key={product.ID}
                    className="rounded-lg border border-gray-300 bg-white p-4 shadow-sm md:p-3 dark:border-gray-700 dark:bg-gray-800"
                  >
                    <CartProducts product={product} />
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full space-y-4 md:w-2/5">
              <div className="space-y-4 rounded-lg border border-gray-300 bg-white p-4 shadow-sm sm:p-6 dark:border-gray-700 dark:bg-gray-800">
                <span className="text-sm font-semibold">{t("main.shop.price.oderSummary")}</span>

                <div className="space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-xs font-normal text-gray-500 dark:text-gray-400">
                        {t("main.shop.price.total")}
                      </dt>
                      <dd className="text-xs font-medium text-gray-900 dark:text-white">
                        €7,592.00
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-xs font-normal text-gray-500 dark:text-gray-400">
                        {t("main.shop.price.tax")}
                      </dt>
                      <dd className="text-xs font-medium text-gray-900 dark:text-white">€799</dd>
                    </dl>
                  </div>

                  <dl className="flex items-center justify-between gap-4 border-t border-gray-300 pt-2 dark:border-gray-700">
                    <span className="text-sm font-semibold">{t("main.shop.price.subtotal")}</span>
                    <dd className="text-sm font-bold text-gray-900 dark:text-white">€8,191.00</dd>
                  </dl>
                </div>

                <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small}>
                  {t("main.shop.price.checkout")}
                </Button>

                <div className="flex items-center justify-center gap-2">
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {t("main.shop.price.or")}
                  </span>
                  <a
                    href="#"
                    title=""
                    className="inline-flex items-center gap-2 text-sm font-medium underline hover:no-underline"
                  >
                    {t("main.shop.price.continueShopping")}
                    <svg
                      className="size-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div className="space-y-4 rounded-lg border border-gray-300 bg-white p-4 shadow-sm sm:p-6 dark:border-gray-700 dark:bg-gray-800">
                <form className="space-y-4">
                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                      {t("main.shop.price.haveVoucher")}
                    </label>
                    <Input
                      name="voucher"
                      placeholder={t("main.shop.price.haveVoucherPlaceholder")}
                      clearable
                    />
                  </div>
                  <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small}>
                    {t("main.shop.price.applyCode")}
                  </Button>
                </form>
              </div>
              <div className="space-y-4 rounded-lg border border-gray-300 bg-white p-4 shadow-sm sm:p-6 dark:border-gray-700 dark:bg-gray-800">
                <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                  {t("main.shop.price.shareArticle")}
                </label>

                <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small}>
                  {t("buttons.share")} {4} {t("main.shoppingVenture.basket.article")}
                </Button>
              </div>
            </div>
          </div>

          <MarketplaceCarousels />
          <div className="mb-5 xl:block">
            <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
            <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
              {products.map(product => (
                <div key={product.ID} className="flex justify-center">
                  <Product product={product} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShoppingCartContainer;
