import { useTranslation } from "react-i18next";

import { MediaType } from "@src/types/MediaType";

type Props = {
  image?: MediaType;
  loading: boolean;
  onClick: () => void;
  placeholder?: string;
};

const Image = ({
  loading,
  placeholder,
  image,
}: {
  loading: boolean;
  placeholder?: string;
  image?: MediaType;
}) => {
  if (loading) return <div className="absolute left-0 top-0 size-full animate-pulse bg-gray-500" />;
  return image ? <img src={`${image.data_url}`} alt="" /> : <img src={placeholder} alt="" />;
};

const AvatarEditElement = ({
  image,
  loading,
  onClick,
  placeholder = "/images/placeholder/club.png",
}: Props) => {
  const { t } = useTranslation();

  return (
    <button
      className="group relative mx-auto flex size-32 cursor-pointer flex-col justify-center overflow-hidden rounded-full border bg-gray-100"
      onClick={onClick}
    >
      <Image image={image} loading={loading} placeholder={placeholder} />
      <div className="absolute bottom-0 left-0 h-12 w-full bg-gray-500 text-center leading-7 opacity-0 transition-opacity group-hover:opacity-70">
        <span>{t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit")}</span>
      </div>
    </button>
  );
};

export default AvatarEditElement;
