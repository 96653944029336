import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SocialIcon } from "react-social-icons";

import { AppContext } from "@src/App";
import Button from "@src/components/elements/input/Button";
import isMobile from "@src/utils/isMobile";

const LoginExternal = () => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);

  const services = [
    {
      label: t("main.signIn.external.google"),
      icon: "google",
      authPath: "google",
    },
    {
      label: t("main.signIn.external.instagram"),
      icon: "instagram",
      authPath: "instagram",
    },
    {
      label: t("main.signIn.external.facebook"),
      icon: "facebook",
      authPath: "facebook",
    },
    {
      label: t("main.signIn.external.amazon"),
      icon: "../images/icons/amazon.webp",
      authPath: "amazon",
    },
    /*  { label: t("main.signIn.external.tiktok"), icon: "tiktok", authPath: "tiktok" },
    { label: t("main.signIn.external.snapchat"), icon: "snapchat", authPath: "snapchat" },
    { label: t("main.signIn.external.pinterest"), icon: "pinterest", authPath: "pinterest" },
    { label: t("main.signIn.external.twitter"), icon: "twitter", authPath: "twitter" },
    { label: t("main.signIn.external.vk"), icon: "vk", authPath: "vk" },
    { label: t("main.signIn.external.reddit"), icon: "reddit", authPath: "reddit" },
    { label: t("main.signIn.external.tumblr"), icon: "tumblr", authPath: "tumblr" },
    { label: t("main.signIn.external.apple"), icon: "####", authPath: "apple" },
    { label: t("main.signIn.external.threads"), icon: "####", authPath: "threads" },
    { label: t("main.signIn.external.truthsocial"), icon: "####", authPath: "truthSocial" },
    { label: t("main.signIn.external.paypal"), icon: "####", authPath: "paypal" },
    { label: t("main.signIn.external.linkedin"), icon: "linkedin", authPath: "linkedin" }, */
  ];

  const redirectToSocialAuth = (authPath: string) => {
    window.location.href = `${__API_URL || ""}/api/v1/auth/social/${authPath}`;
  };

  useEffect(() => {
    if (appContext?.setFullscreen && isMobile) appContext.setFullscreen(true);

    return () => {
      if (appContext?.setFullscreen && isMobile) appContext.setFullscreen(false);
    };
  }, [appContext]);

  return (
    <div className="grid grid-flow-row content-start gap-1 lg:gap-0">
      {services.map(service => {
        return (
          <>
            <Button
              onClick={() => redirectToSocialAuth(service.authPath)}
              variant={Button.Variants.White}
              size={Button.Sizes.Large}
            >
              <div className="relative flex w-full justify-center">
                {service.authPath === "amazon" ? (
                  <img
                    src={service.icon}
                    alt={service.label}
                    style={{
                      width: "28px",
                      height: "28px",
                      position: "absolute",
                      left: "0px",
                      top: "50%",
                      transform: "translate(0px,-50%)",
                    }}
                  />
                ) : (
                  <SocialIcon
                    network={service.icon}
                    className="overflow-hidden"
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "999px",
                      overflow: "hidden",
                      position: "absolute",
                      left: "0px",
                      top: "50%",
                      transform: "translate(0px,-50%)",
                    }}
                  />
                )}
                {service.label}
              </div>
            </Button>
          </>
        );
      })}
    </div>
  );
};

export default LoginExternal;
