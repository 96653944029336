import api, { RequestWithData } from "@src/api/api";

type RegisterData = {
  email: string;
  type: string;
  firstname?: string;
  lastname?: string;
  name?: string;
  password: string;
};

export type RegisterRequest = RequestWithData<RegisterData>;

export default async function register(request: RegisterRequest): Promise<void> {
  const { data } = await api.post("/api/v1/auth/register", request.data);
  return data;
}
