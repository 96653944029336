import { TFunction } from "i18next";
import {
  Briefcase,
  DocumentText,
  Gallery,
  Hashtag,
  Mobile,
  People,
  Personalcard,
  Setting2,
  StatusUp,
  Tag,
  User as UserIcon,
  UserSquare,
  Video,
  Wallet,
} from "iconsax-react";
import { SocialIcon } from "react-social-icons";

import ClubContainer from "@src/pages/profile/details/ClubContainer";
import CompanyAndClubProfileContainer from "@src/pages/profile/details/CompanyAndClubProfileContainer";
import EmployeeContainer from "@src/pages/profile/details/EmployeeContainer";
import GoogleMapsWidget from "@src/pages/profile/details/GoogleMapsWidget";
import PrivateContainer from "@src/pages/profile/details/PrivateContainer";
import PrivateProfileContainer from "@src/pages/profile/details/PrivateProfileContainer";
import { UserType } from "@src/types/UserType";

const SOCIAL_MEDIA_TYPES = [
  { name: "Twitter", value: "twitter", url: "https://twitter.com/" },
  { name: "Facebook", value: "facebook", url: "https://facebook.com/" },
  { name: "Instagram", value: "instagram", url: "https://instagram.com/" },
  { name: "Tiktok", value: "tiktok", url: "https://tiktok.com/" },
  { name: "Youtube", value: "youtube", url: "https://youtube.com/" },
  { name: "Linkedin", value: "linkedin", url: "https://linkedin.com/" },
  { name: "Xing", value: "xing", url: "https://xing.com/" },
];

export type SidebarConfigType = {
  type?: string;
  name?: string;
  title?: string;
  items?: {
    type: string;
    property?: string;
    placeholder?: string;
  }[];
};

type ProfileConfigType = Record<
  "company" | "person" | "club",
  {
    tabs: { name: string; key: string; url: string }[];
    mediatabs: { name: string; key: string; icon: JSX.Element }[];
    userlabels: { items: LabelItem[] };
    sidebarbuttons: { title: string; buttons: SidebarButton[] };
    sidebars: SidebarConfigType[];
  }
>;

type LabelItem = {
  label: string;
  type: string;
  property: string[];
  placeholder: string;
};

type SidebarButton = {
  icon: JSX.Element;
  tabname: string;
  label: string;
  authRequired?: boolean;
  page?: string;
  state?: Record<string, string>;
  props?: { state: Record<string, string> };
};

const COMMON_TABS = (t: TFunction) => [
  { name: t("components.shared.aboutUs"), key: "about", url: "über_uns" },
  { name: t("main.profileViewTabs.feed.title"), key: "news", url: "news" },
  { name: t("main.profileViewTabs.watch.title"), key: "watch", url: "watch" },
  { name: t("main.header.links.jobs"), key: "jobs", url: "jobs" },
  { name: t("main.profileViewTabs.shop.title"), key: "ads", url: "shop" },
  { name: t("main.profileViewTabs.advertisements.title"), key: "assignments", url: "inserate" },
];

const COMMON_MEDIA_TABS = [
  { name: "Feed", key: "news", icon: <DocumentText size={18} /> },
  { name: "Watch", key: "watch", icon: <Video size={18} /> },
];

const COMMON_USER_LABELS = (t: TFunction): LabelItem[] => [
  {
    label: t("main.contacts.tabs.likes"),
    type: "objectlabel",
    property: ["likes"],
    placeholder: "0",
  },
  {
    label: t("main.contacts.tabs.followers"),
    type: "objectlabel",
    property: ["follower_count"],
    placeholder: "0",
  },
];

const COMMON_BUTTONS = (t: TFunction): SidebarButton[] => [
  {
    icon: <Setting2 size={34} variant="Bold" color="white" />,
    tabname: "edit",
    label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
    authRequired: true,
    page: "/verwaltung/profile",
    state: { backlink: "/profile" },
  },
  {
    icon: <UserSquare size={34} variant="Bold" color="white" />,
    tabname: "contacts",
    label: t("main.profileTabs.company.contact"),
  },

  {
    icon: <Gallery size={34} variant="Bold" color="white" />,
    tabname: "media",
    label: t("main.feedTabs.feetCreate.media"),
  },
  {
    icon: <Hashtag size={34} variant="Bold" color="white" />,
    tabname: "hashtags",
    label: t("main.bookmarks.tabs.hashtags"),
  },
  {
    icon: <Tag size={34} variant="Bold" color="white" />,
    tabname: "assignments",
    label: t("main.profileViewTabs.advertisements.title"),
  },
  {
    icon: <Mobile size={34} variant="Bold" color="white" />,
    tabname: "social_media",
    label: t("main.profileTabs.person.socialMedia"),
  },
  {
    icon: <StatusUp size={34} variant="Bold" color="white" />,
    tabname: "stats",
    label: t("main.contacts.tabs.statistics"),
    authRequired: true,
  },
];
const COMMON_BUTTONS_CLUB_COMPANY = (t: TFunction): SidebarButton[] => [
  {
    icon: <Personalcard size={34} variant="Bold" color="white" />,
    tabname: "facts",
    label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.facts"),
  },
  {
    icon: <UserIcon size={34} variant="Bold" color="white" />,
    tabname: "employee",
    label: t("main.profileViewTabs.editProfile.form.employees"),
  },
];

const ProfileConfig = (t: TFunction): ProfileConfigType => ({
  person: {
    tabs: [...COMMON_TABS(t)],
    mediatabs: COMMON_MEDIA_TABS,
    userlabels: { items: COMMON_USER_LABELS(t) },
    sidebarbuttons: {
      title: t("main.profileViewTabs.aboutMe.content.furtherInfo"),
      buttons: [
        ...COMMON_BUTTONS(t).slice(0, 2),
        {
          icon: <Personalcard size={34} variant="Bold" color="white" />,
          tabname: "other_facts",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.characteristics"),
        },
        {
          icon: <StatusUp size={34} variant="Bold" color="white" />,
          tabname: "cvs",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.career"),
        },
        ...COMMON_BUTTONS(t).slice(2),
      ],
    },
    sidebars: [
      {
        name: "contacts",
        title: "Kontakte",
        items: [{ type: "contactbox" }, { type: "googlemaps" }],
      },
      {
        name: "other_facts",
        title: "Steckbrief",
        items: [
          {
            type: "other_facts",
          },
        ],
      },
    ],
  },
  club: {
    tabs: COMMON_TABS(t),
    mediatabs: COMMON_MEDIA_TABS,
    userlabels: { items: COMMON_USER_LABELS(t) },
    sidebarbuttons: {
      title: t("main.profileViewTabs.aboutMe.content.furtherInfo"),
      buttons: [
        ...COMMON_BUTTONS(t).slice(0, 2),
        ...COMMON_BUTTONS_CLUB_COMPANY(t),
        {
          icon: <People size={34} variant="Bold" color="white" />,
          tabname: "members",
          label: t("main.members.title"),
        },
        {
          icon: <Wallet size={34} variant="Bold" color="white" />,
          tabname: "jobs",
          label: t("main.header.links.jobs"),
        },
        ...COMMON_BUTTONS(t).slice(2),
      ],
    },
    sidebars: [
      {
        name: "facts",
        title: t("components.shared.dataAndFacts"),
        items: [{ type: "other_facts" }],
      },
      {
        name: "contacts",
        title: t("main.header.links.contacts"),
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
    ],
  },
  company: {
    tabs: COMMON_TABS(t),
    mediatabs: COMMON_MEDIA_TABS,
    userlabels: { items: COMMON_USER_LABELS(t) },
    sidebarbuttons: {
      title: t("main.profileViewTabs.aboutMe.content.furtherInfo"),
      buttons: [
        ...COMMON_BUTTONS(t).slice(0, 2),
        ...COMMON_BUTTONS_CLUB_COMPANY(t),
        {
          icon: <Wallet size={34} variant="Bold" color="white" />,
          tabname: "jobs",
          label: t("main.header.links.jobs"),
        },
        {
          icon: <Briefcase size={34} variant="Bold" color="white" />,
          tabname: "cvs",
          label: t("main.jobsTabs.career"),
          authRequired: true,
        },
        ...COMMON_BUTTONS(t).slice(2),
      ],
    },
    sidebars: [
      {
        name: "facts",
        title: t("components.shared.dataAndFacts"),
        items: [{ type: "other_facts" }],
      },
      {
        name: "contacts",
        title: t("main.header.links.contacts"),
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
    ],
  },
});

/**
 * Returns component according to type
 * @param {any} component
 * @param {UserType} user
 * @returns
 */
export function getComponent({ component, user, t }: { component: any; user?: UserType; t: any }) {
  switch (component.type) {
    case "functionlabel":
      if (!component.fn) return;
      if (!user) return;
      if (!getProperty(user, component.property, component.placeholder)) return;

      return (
        <div
          className={`flex flex-wrap gap-1 ${component.bold && "font-semibold"} ${component.small && "text-xs"}`}
        >
          {component.label && <div>{component.label}</div>}
          {component.fn(getProperty(user, component.property, component.placeholder))}
        </div>
      );

    case "objectlabel":
      if (!user) return;
      /*        if (!getProperty(user, component.property, component.placeholder)) return; // show placeholder if property is empty or undefined
       */
      return (
        <div className="flex flex-wrap gap-1">
          {component.label && (
            <div className={`${component.bold && "font-semibold"}`}>{component.label}</div>
          )}
          {getProperty(user, component.property, component.placeholder)}
        </div>
      );

    case "socialmedia":
      if (!user) return;
      if (!getProperty(user, component.property)) return;

      return (
        <div className="flex flex-wrap gap-2">
          {getProperty(user, component.property, component.placeholder).map(
            (socialmedia: any, index: number) => {
              return (
                <div key={`${socialmedia.url}-${index}`}>
                  <SocialIcon url={getSocialMedia(socialmedia)} className="-mx-2 scale-75" />
                </div>
              );
            },
          )}
        </div>
      );

    case "googlemaps":
      if (!user) return;
      return <GoogleMapsWidget />;

    case "contactbox":
      if (!user) return;
      return <div className="grid grid-cols-2 gap-4 md:grid-cols-3">{getContactBox(user)}</div>;

    case "other_facts":
      if (!user) return;
      return <div className="grid grid-cols-2 gap-4 md:grid-cols-3">{getProfileBox(user)}</div>;
    default:
      return <div>{t("components.shared.unknownComponent")}</div>;
  }
}

/**
 * Returns the value of the property of the JSON object
 * @param object JSON object
 * @param path properties
 * @param placeholder returns placeholder if value of property is undefined
 * @returns value of property. returns undefined if no value was found
 */
const getProperty = (object: any, path: Array<string>, placeholder?: string) => {
  let currentValue = object;
  for (const element of path) {
    if (!element) return object;
    if (!currentValue[element]) return placeholder;
    currentValue = JSON.parse(JSON.stringify(currentValue[element]));
  }
  return currentValue;
};

/**
 * Returns the url of the social media
 * @param linkData Linkdata of the social media page
 * @returns url of social media
 */
const getSocialMedia = (linkData: any) => {
  const index = SOCIAL_MEDIA_TYPES.findIndex(element => element.value === linkData.type);
  if (index < 0) return undefined;
  return SOCIAL_MEDIA_TYPES[index].url + linkData.username;
};

const getContactBox = (user: UserType) => {
  switch (user.type) {
    case "person":
      return <PrivateContainer user={user} minimized={false} />;
    case "club":
      return <ClubContainer user={user} minimized={false} />;
    case "company":
      return <EmployeeContainer user={user} />;
    default:
      return <></>;
  }
};

const getProfileBox = (user: UserType) => {
  switch (user.type) {
    case "person":
      return <PrivateProfileContainer user={user} />;
    case "club":
    case "company":
      return <CompanyAndClubProfileContainer user={user} />;
  }
};

export default ProfileConfig;
