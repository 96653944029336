import { useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

import MultipleFileUpload from "@src/components/elements/form/buttons/MultipleFileUpload";
import Input from "@src/components/elements/input/Input";
import StarsRating from "@src/components/elements/input/StarsRating";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";

const WriteReviewButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <>
      <ModalWrapper open={open} onCancel={() => setOpen(false)} hideActions={true}>
        <div className="flex flex-col items-start">
          <div className="mb-2 text-xl font-semibold">{t("main.shop.details.reviewWrite")}</div>
          <div className="flex flex-row">
            <img
              className="mr-2 size-16 rounded-lg"
              src="https://tradefoox-beta.b-cdn.net/media/images/avatars/xUGccPhKBT/6659891.jpeg"
              alt=""
            />
            <div className="text-sm font-semibold">
              This is product title This is product title This is product title
            </div>
          </div>
          <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700" />

          <div>{t("main.shop.details.overAllRating")}</div>
          <div>
            <StarsRating rating={4} />
          </div>
          <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700" />
        </div>

        <div className="flex flex-col gap-2">
          <Input type="text" name="title" placeholder={t("main.shop.details.writeReviewTitle")} />
          <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700" />

          <TextareaAutosize
            minRows={3}
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder={t("main.shop.details.writeReview")}
            maxLength={250}
            className="block w-full rounded-lg border-0 bg-gray-200 px-4 py-3"
          />
        </div>
        <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700" />
        <MultipleFileUpload limit={4} />
      </ModalWrapper>

      <button
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("main.shop.details.writeReview")}
      </button>
    </>
  );
};

export default WriteReviewButton;
