import { InfiniteData, InfiniteQueryObserverSuccessResult } from "@tanstack/react-query";

import { FeedResponse } from "@src/api/feed/feed";
import CompactPost from "@src/components/elements/CompactPost";

type Props = {
  placeholder?: string;
  feedResult: InfiniteQueryObserverSuccessResult<InfiniteData<FeedResponse>>;
};

const ProfileFeedList = ({ placeholder, feedResult }: Props) => {
  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      {feedResult.data.pages
        .flatMap(page => page.data)
        .filter(originalPost => originalPost !== null)
        .map(originalPost => {
          const post =
            originalPost.is_reposted && originalPost.reposted
              ? originalPost.reposted
              : originalPost;
          if (!post) return;

          let id = post.id;
          let type: "image" | "video" = "image";
          let url = placeholder;

          if (post.link_preview?.image) {
            url = `${post.link_preview?.image}`;
          } else if (post.media.length) {
            id = post.media[0].id;
            url = post.media[0].data_url;
            if (post.media[0].type === "video") {
              type = "video";
            }
          }

          return (
            <CompactPost
              key={`discover:${post.watch ? id : post.id}`}
              link={
                post.watch ? `/watch/für_dich?featured=${id}` : `/news/für_dich?featured=${post.id}`
              }
              type={type}
              url={url}
              viewCount={post.view_count}
              likeCount={post.like_count}
            />
          );
        })}
    </>
  );
};

export default ProfileFeedList;
