import { useTranslation } from "react-i18next";

import PopupWrapper from "@src/components/elements/PopupWrapper";
import Button from "@src/components/elements/input/Button";

type Props = {
  onClose?: () => void;
};

const AppPromoPopup = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <PopupWrapper onClose={onClose}>
      <div className="p-6 text-center">
        <h2 className="mb-4 text-xl font-bold">{t("main.panel.promoAppPopUp.title")}</h2>
        <p className="mb-6 text-gray-600">{t("main.panel.promoAppPopUp.description")}</p>
        <div className="flex justify-center gap-4">
          <Button variant={Button.Variants.Red}>{t("main.panel.promoAppPopUp.download")}</Button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default AppPromoPopup;
