import api, { RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type BlockedParams = {
  page?: number;
  q?: string;
};

type BlockedRequest = RequestWithParams<BlockedParams>;

type BlockedResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function blocked(request: BlockedRequest): Promise<BlockedResponse> {
  const { data } = await api.get(
    `/api/v1/me/blocked${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
