import api, { RequestWithData, RequestWithId } from "@src/api/api";

type RequestData = {
  text: string;
  parent_id?: string | null;
};

type CreateRequest = RequestWithId & RequestWithData<RequestData>;

export default async function comment(request: CreateRequest): Promise<void> {
  const { data } = await api.post(`/api/v1/posts/${request.id}/comment`, request.data);
  return data;
}
