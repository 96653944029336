import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import DemoProducts from "@src/mocks/MarketplaceProduct.json";

const HalfCircleTitle = () => {
  const demoProducts = DemoProducts.demoProducts;

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={16}
      slidesPerView={2}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      loop={true}
      breakpoints={{
        768: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        1024: {
          slidesPerView: 6,
        },
      }}
      onInit={swiper => {
        swiper.navigation.nextEl.classList.add("swiper-button-next");
        swiper.navigation.prevEl.classList.add("swiper-button-prev");
      }}
    >
      {demoProducts.map(product => (
        // eslint-disable-next-line react/jsx-key
        <SwiperSlide>
          <div
            key={product._id}
            className="relative flex w-full shrink-0 items-center justify-center overflow-hidden rounded-full bg-green-200 md:size-[190px]"
          >
            <img src={product.image} alt="" className="size-[150px] rounded-full object-contain" />
            <div className="absolute bottom-0 h-[55px] w-[200px] bg-white p-1 text-center text-xs font-semibold">
              {product.category}
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className="hidden md:block">
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
      </div>
    </Swiper>
  );
};

export default HalfCircleTitle;
