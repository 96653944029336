import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { FlagIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import remove from "@src/api/comments/remove";
import { useAppSelector } from "@src/state/hooks";
import { CommentType } from "@src/types/CommentType";

type Props = {
  comment: CommentType;
};

const CommentsDropdown = ({ comment }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const removeMutation = useMutation({
    mutationKey: ["comments", "remove", comment.id, user?.id ?? ""],
    mutationFn: remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Menu as="div" className="text-left group-hover/comment:visible lg:invisible">
      <div>
        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600">
          <span className="sr-only">{t("advertisement.addWidged.openOptions")}</span>
          <EllipsisVerticalIcon className="size-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <div className="py-1">
          {user && user.id === comment.author.id ? (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  className={`inline-flex w-full items-center justify-between px-4 py-2 text-left text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                >
                  {t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit")}
                  <PencilIcon className="size-4" />
                </button>
              )}
            </Menu.Item>
          ) : null}
          {user && user.id === comment.author.id ? (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  className={`inline-flex w-full items-center justify-between px-4 py-2 text-left text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                  onClick={() => removeMutation.mutate({ id: comment.id })}
                >
                  {t("components.shared.remove")}
                  <TrashIcon className="size-4" />
                </button>
              )}
            </Menu.Item>
          ) : null}
          <Menu.Item>
            {({ active }) => (
              <button
                type="submit"
                className={`inline-flex w-full items-center justify-between px-4 py-2 text-left text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
              >
                {t("buttons.report")}
                <FlagIcon className="size-4" />
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default CommentsDropdown;
