import { useState } from "react";
import { useTranslation } from "react-i18next";

import feed from "@src/api/feed/feed";
import Input from "@src/components/elements/input/Input";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ProfileFeedList from "@src/pages/profile/details/ProfileFeedList";

type Props = {
  userId?: string;
};

const ProfileDetailsPosts = ({ userId }: Props) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const queryParams = {
    q: searchQuery || null,
    user_id: userId ?? null,
    liked_by: null,
  };

  const { result: feedResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", "feed", queryParams],
    queryFn: ({ pageParam }) =>
      feed({
        params: {
          ...queryParams,
          page: pageParam,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      <Input
        placeholder={t("components.shared.searchFeed")}
        name="feed_keywords"
        clearable
        onChange={e => setSearchQuery(e)}
      />

      <div className="grid grid-flow-row gap-2">
        <div className="grid grid-cols-2 gap-3 lg:grid-cols-5">
          <ProfileFeedList placeholder="/placeholder.png" feedResult={feedResult} />
        </div>
      </div>
    </>
  );
};

export default ProfileDetailsPosts;
