import { useTranslation } from "react-i18next";

import Select from "@src/components/elements/input/Select";
import workTimeOptions from "@src/components/elements/jobs/shared/options/workTimeOptions";

type Props = {
  value: string;
  onChange: (key: string, value: string) => void;
};

const WorkTimeSelector = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <Select
      items={workTimeOptions}
      name="type"
      label={t("main.navLinks.jobsPublished.jobtype")}
      value={value || ""}
      onChange={e => onChange("type", e.target.value)}
    />
  );
};

export default WorkTimeSelector;
