import api, { RequestWithData } from "@src/api/api";

type RequestData = {
  text: string;
  adult: boolean;
  watch?: boolean;
  media: string[];
  link?: string;
  set_active_at?: number;
  link_preview_id?: string;
};

type CreateRequest = RequestWithData<RequestData>;

export default async function create(request: CreateRequest): Promise<void> {
  const { data } = await api.post("/api/v1/posts", request.data);
  return data;
}
