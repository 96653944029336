import { Bars3Icon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import {
  DocumentText,
  Notification,
  People,
  Profile2User,
  Shop,
  ShoppingCart,
  Sms,
  Tag,
  Video,
  Wallet,
} from "iconsax-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import HeaderButton from "@src/components/nav/HeaderButton";
import NavUserElement from "@src/components/nav/NavUserElement";
import { useAppSelector } from "@src/state/hooks";

type Props = {
  dark?: boolean;
  sideBarOnClick?: () => void;
};

type tabsType = {
  title: string;
  href: string;
  style?: string;
  icon: JSX.Element;
  disabled?: boolean;
  activeLinks?: string[];
  authRequired?: boolean;
};

const NavBar = ({ dark, sideBarOnClick }: Props) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(state => state.user);

  const tabsMobile: Array<tabsType> = [
    {
      title: t("main.header.links.messenger.title"),
      href: "/messenger",
      icon: <Sms name="Sms" size={24} />,
      style: "max-xl:hidden",
    },
    {
      title: t("main.header.links.contacts"),
      href: "/kontakte/für_dich",
      icon: <Profile2User name="Profile2User" size={24} />,
    },
    {
      title: t("main.header.links.marketplace"),
      href: "/marketplace/für_dich",
      icon: <Shop name="Shop" size={24} />,
      activeLinks: ["/marketplace", "/verwaltung/mein_shop"],
    },
    {
      title: t("main.header.links.marketplace"),
      href: "/benachrichtigungen",
      icon: <Notification name="Notification" size={24} />,
    },
    {
      title: t("main.header.links.advertisements"),
      href: "/inserate",
      icon: <Tag name="Tag" size={24} />,
      activeLinks: ["/inserate", "/verwaltung/inserate"],
    },
    {
      title: t("main.header.links.jobs"),
      href: "/jobs",
      icon: <Wallet name="Wallet" size={24} />,
      activeLinks: ["/jobs", "/verwaltung/jobs"],
    },
  ];

  const tabs: Array<tabsType> = [
    {
      title: t("main.header.links.messenger.title"),
      href: "/messenger",
      icon: <Sms name="Sms" size={24} />,
      authRequired: true,
    },
    {
      title: t("main.header.links.contacts"),
      href: "/kontakte/für_dich",
      icon: <Profile2User name="Profile2User" size={24} />,
    },
    {
      title: t("main.header.links.groups.title"),
      href: "/gruppen",
      icon: <People name="People" size={24} />,
    },
    {
      title: t("main.header.links.feed"),
      href: "/news",
      icon: <DocumentText name="DocumentText" size={24} />,
    },
    {
      title: t("main.header.links.watch"),
      href: "/watch",
      icon: <Video name="Video" size={24} />,
    },
    {
      title: t("main.header.links.marketplace"),
      href: "/marketplace/für_dich",
      icon: <Shop name="Shop" size={24} />,
      activeLinks: ["/marketplace", "/verwaltung/mein_shop"],
    },
    {
      title: t("main.header.links.advertisements"),
      href: "/inserate",
      icon: <Tag name="Tag" size={24} />,
      activeLinks: ["/inserate", "/verwaltung/inserate"],
    },
    {
      title: t("main.header.links.jobs"),
      href: "/jobs",
      icon: <Wallet name="Wallet" size={24} />,
      activeLinks: ["/jobs", "/verwaltung/jobs"],
    },
  ];

  return (
    <div
      className={`top-0 z-30 w-full border-b border-gray-300 bg-white ${dark && "bg-slate-900"}`}
    >
      <div className="-mb-0.5 flex flex-row items-center gap-2 align-middle lg:pt-0.5 xl:px-5">
        {sideBarOnClick && (
          <button onClick={() => sideBarOnClick()}>
            <Bars3Icon className="heroicon-stroke-w-1.2 size-7 text-white" />
          </button>
        )}
        <NavLink to="/news" className="relative h-12 w-56 shrink-0 cursor-pointer max-xl:hidden">
          <img
            src="/logo.gif"
            alt="TRADEFOOX"
            className={`${dark && "contrast-200 grayscale invert"}`}
          />
        </NavLink>

        {!user && (
          <NavLink
            className={`rounded-xl bg-darkblue px-3 py-1 text-sm text-white max-xl:hidden ${dark && "border border-white bg-transparent"}`}
            to="/registrierung"
          >
            {t("main.header.links.register")}
          </NavLink>
        )}
        <div className="h-12 xl:hidden"></div>

        <div className="flex w-full items-center justify-center gap-2 max-xl:hidden lg:gap-4 xl:gap-8">
          {tabs
            .filter(item => !item?.disabled)
            .map(tabData => {
              return (
                <HeaderButton
                  key={tabData.href}
                  title={tabData.title}
                  icon={tabData.icon}
                  href={tabData.href}
                  style={tabData.style}
                  dark={dark}
                  activeLinks={tabData.activeLinks}
                  authRequired={tabData.authRequired}
                />
              );
            })}
          <div className="max-xl:hidden">
            <NavUserElement dark={dark} />
          </div>
        </div>
        <div className="relative mr-[100px] shrink-0 cursor-pointer max-xl:hidden">
          <NavLink to={"/marketplace/warenkorb"}>
            <div className="flex flex-row">
              <div className="relative size-[35px] border-darkblue bg-transparent">
                <ShoppingCart name="ShoppingCart" size={24} />
                <div className="absolute -right-px top-0">
                  <div className="flex size-[16px] items-center justify-center rounded-full border bg-red-300 text-xs">
                    2
                  </div>
                </div>
              </div>
              <div className="ml-1 mt-2 text-xs text-darkblue">
                {t("main.shoppingVenture.shoppingCart")}
              </div>
              <ChevronLeftIcon className="mt-1.5 size-5 text-gray-300" />
            </div>
          </NavLink>
        </div>
        <div className="mr-0 flex w-full items-center justify-around gap-2 lg:gap-4 xl:hidden xl:w-fit xl:gap-8 2xl:mr-[250px]">
          {tabsMobile
            .filter(item => !item?.disabled)
            .map(tabData => {
              return (
                <HeaderButton
                  key={tabData.href}
                  title={tabData.title}
                  icon={tabData.icon}
                  href={tabData.href}
                  style={tabData.style}
                  dark={dark}
                  activeLinks={tabData.activeLinks}
                  authRequired={tabData.authRequired}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
